import * as React from "react"

function Logo({ fill, className, ...props }) {
  return (
    <svg
      width={180}
      height={90}
      viewBox="0 0 333 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        d="M298.65 6.71c0-3.56 2.8-6.36 6.36-6.36 3.56 0 6.36 2.8 6.36 6.36 0 3.56-2.93 6.36-6.36 6.36s-6.36-2.93-6.36-6.36zm1.27 19.85c0-2.8 2.16-5.09 5.09-5.09 2.93 0 5.09 2.29 5.09 5.09v56.61c0 2.93-2.29 5.22-5.09 5.22s-5.09-2.29-5.09-5.22V26.56zm11.45-19.85c0 3.51-2.85 6.36-6.36 6.36-3.51 0-6.36-2.85-6.36-6.36 0-3.51 2.85-6.36 6.36-6.36 3.51 0 6.36 2.85 6.36 6.36zM40.88 47.17c0 3.51-2.85 6.36-6.36 6.36-3.51 0-6.36-2.85-6.36-6.36 0-3.51 2.85-6.36 6.36-6.36 3.51 0 6.36 2.85 6.36 6.36zm0 17.77c0 3.51-2.85 6.36-6.36 6.36-3.51 0-6.36-2.85-6.36-6.36 0-3.51 2.85-6.36 6.36-6.36 3.51 0 6.36 2.85 6.36 6.36zM66.36 55.12c0-18.83 14.5-33.71 33.59-33.71 19.09 0 33.46 14.89 33.46 33.71s-14.38 33.71-33.46 33.71-33.59-14.88-33.59-33.71zm56.74 0c0-13.49-10.05-24.3-23.15-24.3-13.1 0-23.28 10.81-23.28 24.3s10.18 24.3 23.28 24.3c13.1 0 23.15-10.94 23.15-24.3zM133.41 27.19c0-2.67 2.16-4.71 4.71-4.71h6.74V11.03c0-2.93 2.29-5.09 5.09-5.09s5.09 2.16 5.09 5.09v11.45h16.79c2.54 0 4.71 2.04 4.71 4.71s-2.16 4.71-4.71 4.71h-16.79v28.75c0 11.83 6.74 18.19 16.79 18.19 2.54 0 4.71 2.16 4.71 4.71 0 2.67-2.16 4.83-4.71 4.83-15.78 0-26.97-10.69-26.97-27.73V31.9h-6.74c-2.54 0-4.71-2.16-4.71-4.71zM183.78 54.92c0-18.83 14.5-33.71 33.59-33.71 9.29 0 17.05 3.56 22.01 8.14 1.14 1.02 1.91 2.42 1.91 3.94 0 1.27-.38 2.42-1.65 3.82l-25.89 31.33c-1.02 1.14-2.16 1.65-3.56 1.65-2.8 0-4.83-1.91-4.83-4.58 0-1.14.25-2.29 1.14-3.18l23.09-27.64c-3.31-2.8-8.02-4.07-12.21-4.07-12.98 0-23.28 10.69-23.28 24.3 0 13.61 10.18 24.3 23.28 24.3 7.25 0 12.98-3.18 16.92-7.63 1.4-1.27 2.55-2.29 4.33-2.29 2.93 0 5.09 2.04 5.09 4.83 0 1.02-.64 2.42-1.53 3.43-5.34 6.36-14.12 11.07-24.81 11.07-18.96 0-33.59-14.88-33.59-33.71h-.01zM257.69 48.69c0-16.41 10.43-27.48 26.21-27.48 2.54 0 4.71 2.04 4.71 4.71s-2.16 4.71-4.71 4.71c-9.42 0-16.03 6.23-16.03 18.07v34.48c0 2.93-2.29 5.22-5.09 5.22s-5.09-2.29-5.09-5.22V48.69z"
        fill={fill || "#F1F1F2"}
      />
      <path
        d="M.63 55.94c0-18.83 14.5-33.71 33.59-33.71 10.56 0 19.47 4.58 24.81 10.94.89 1.02 1.53 2.42 1.53 3.56 0 2.8-2.16 4.71-5.09 4.71-1.91 0-2.93-1.02-4.33-2.16-3.94-4.45-9.67-7.63-16.92-7.63-12.98 0-23.28 10.69-23.28 24.3 0 13.61 10.3 24.3 23.28 24.3 7.13 0 12.98-3.18 16.92-7.63 1.27-1.14 2.42-2.29 4.33-2.29 2.93 0 5.09 2.04 5.09 4.84 0 1.02-.64 2.42-1.53 3.43-5.34 6.36-14.12 11.07-24.81 11.07C15.14 89.67.63 74.79.63 55.96v-.02zM331.01 76.53a6.633 6.633 0 00-4.72-1.95c-1.78 0-3.46.69-4.72 1.95a6.633 6.633 0 00-1.95 4.72c0 1.78.69 3.46 1.95 4.72a6.633 6.633 0 004.72 1.95c1.78 0 3.46-.69 4.72-1.95a6.633 6.633 0 001.95-4.72c0-1.78-.69-3.46-1.95-4.72zm-4.72 10.29c-3.07 0-5.57-2.5-5.57-5.57 0-3.07 2.5-5.57 5.57-5.57 3.07 0 5.57 2.5 5.57 5.57 0 3.07-2.5 5.57-5.57 5.57z"
        fill={fill || "#F1F1F2"}
      />
      <path
        d="M327.82 81.42c.87-.37 1.3-.98 1.3-1.83 0-.64-.28-1.15-.83-1.52-.56-.37-1.31-.56-2.25-.56-.63 0-1.35.07-2.15.2v7.27h1.05v-3.21h1.33c1.08 0 1.77 1.07 2.07 3.21h1.05c-.18-1.12-.38-1.92-.61-2.42-.23-.5-.55-.87-.96-1.13v-.01zm-1.6-.47h-1.28V78.4c.29-.03.66-.05 1.1-.05 1.38 0 2.07.43 2.07 1.29 0 .86-.63 1.3-1.88 1.3l-.01.01z"
        fill={fill || "#F1F1F2"}
      />
    </svg>
  )
}

export default Logo
