import axios from 'axios'
import { prepareTrack } from '../Coteri'

const getHeaders = (token) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_APPLE_MUSIC_DEV_TOKEN}`
  }
  if (token) {
    headers['Music-User-Token'] = token
  }
  return { headers }
}

const getTracks = async (type, offset, limit, headersConfig) => {
  try {
    if (type === 'library') {
      return axios.get(
        `https://api.music.apple.com/v1/me/library/songs?include=catalog&offset=${offset}&limit=${limit}`,
        headersConfig
      )
    }
    return axios.get(
      `https://api.music.apple.com/v1/me/recent/played/tracks?offset=${offset}&limit=${limit}`,
      headersConfig
    )
  } catch (error) {
    console.log('getRecentTracks', error)
    return null
  }
}

const getAppleMusicTrackData = async (isrc, store) => {
  try {
    const headersConfig = getHeaders()
    const response = await axios.get(
      `https://api.music.apple.com/v1/catalog/${store}/songs?filter[isrc]=${isrc}`,
      headersConfig
    )
    const data = response?.data?.data || []
    return data.length > 0 ? data[0] : null
  } catch (error) {
    console.log('getAppleMusicTrackData', isrc, error)
    return null
  }
}

// const fetchSongByISRC = async (isrc, store, headersConfig, accessToken) => {
//   try {
//     const response = await axios.get(
//       `https://api.music.apple.com/v1/catalog/${store}/songs?filter[isrc]=${isrc}`,
//       headersConfig
//     )
//     const data = response?.data?.data || []
//     if (data.length > 0) {
//       return await preparack('apple_music', data[0], accessToken)
//     }
//     return null
//   } catch (error) {
//     console.log('fetchSongByISRC', isrc, error)
//     return null
//   }
// }

const getAppleMusicHeavyRotation = async (
  accessToken,
  offset = 0,
  limit = 30,
  spotifyToken
) => {
  const headersConfig = getHeaders(accessToken)
  const result = axios.get(
    `https://api.music.apple.com/v1/me/history/heavy-rotation`,
    headersConfig
  )
  console.log('getAppleMusicHeavyRotation', result.data?.data)
  return []
}

const getAppleMusicTracks = async (
  type,
  accessToken,
  offset = 0,
  limit = 30,
  spotifyToken
) => {
  const headersConfig = getHeaders(accessToken)
  const promises = []
  const maxLimit = 30
  const numOfRequests = Math.floor(limit / maxLimit)
  const remOfRequests = numOfRequests > 0 ? limit % maxLimit : limit

  for (let i = 0; i < numOfRequests; i++) {
    const iOffset = i > 0 ? i * maxLimit + offset : offset
    promises.push(getTracks(type, iOffset, maxLimit, headersConfig))
  }
  if (remOfRequests > 0) {
    const lastOffset =
      numOfRequests > 0 ? numOfRequests * maxLimit + offset : offset
    const lastLimit = limit - numOfRequests * maxLimit
    promises.push(getTracks(type, lastOffset, lastLimit, headersConfig))
  }
  const results = await Promise.all(promises)
  const trackPromises = []
  results.forEach((result) => {
    const items = result.data?.data || []
    items.forEach((item) => {
      trackPromises.push(prepareTrack('apple_music', item, spotifyToken))
    })
  })
  const result = await Promise.all(trackPromises)
  return result.filter((x) => !!x)
}

const getAppleMusicRecommendations = async (accessToken, spotifyToken) => {
  const headersConfig = getHeaders(accessToken)

  const results = await axios.get(
    `https://api.music.apple.com/v1/me/recommendations`,
    headersConfig
  )
  const recommendations = results.data?.data || []
  // console.log('recommendations', recommendations)

  if (recommendations.length === 0) return []

  const recommendation = recommendations[0]
  const playlists = recommendation?.relationships?.contents?.data || []

  if (playlists.length === 0) return []

  const playlist = playlists[0]
  const recommendedPlaylist = await axios.get(
    `https://api.music.apple.com${playlist.href}`,
    headersConfig
  )
  const tracks =
    (recommendedPlaylist.data?.data || [{}])[0].relationships?.tracks?.data ||
    []
  const trackPromises = []
  tracks.slice(0, 20).forEach((item) => {
    trackPromises.push(prepareTrack('apple_music', item, spotifyToken))
  })
  const result = await Promise.all(trackPromises)
  return result.filter((x) => !!x)
}

// const fetchSongGenres = async (isrc, catalog = 'us') => {
//   const headersConfig = getHeaders()
//   const result = axios.get(
//     `https://api.music.apple.com/v1/catalog/${catalog}/songs?filter[isrc]=${isrc}`,
//     headersConfig
//   )
//   const genres = []
//   ;(result.data?.data || []).forEach((item) => {
//     genres.push(...(item.attributes?.genreNames || []))
//   })
//   return genres
// }

const searchAppleMusic = async (
  accessToken,
  storefront,
  text,
  spotifyToken,
  offset = 0,
  limit = 30
) => {
  const headersConfig = getHeaders(accessToken)
  const result = await axios.get(
    `https://api.music.apple.com/v1/catalog/${storefront}/search?term=${text}&types=songs&offset=${offset}&limit=${limit}`,
    headersConfig
  )
  const items = result.data?.results?.songs?.data || []
  const trackPromises = []
  items.slice(0, 20).forEach((item) => {
    trackPromises.push(prepareTrack('apple_music', item, spotifyToken))
  })
  const results = await Promise.all(trackPromises)
  return results.filter((x) => !!x)
}

const getAppleMusicRecentTracks = async (
  accessToken,
  offset = 0,
  limit = 30,
  spotifyToken
) => {
  return await getAppleMusicTracks(
    'recent',
    accessToken,
    offset,
    limit,
    spotifyToken
  )
}

const getAppleMusicLibrary = async (
  accessToken,
  offset = 0,
  limit = 30,
  spotifyToken
) => {
  return await getAppleMusicTracks(
    'library',
    accessToken,
    offset,
    limit,
    spotifyToken
  )
}

export {
  getHeaders,
  searchAppleMusic,
  getAppleMusicTrackData,
  getAppleMusicRecentTracks,
  getAppleMusicLibrary,
  getAppleMusicRecommendations,
  getAppleMusicHeavyRotation
}
