import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Base from '../base'
import { Helmet } from 'react-helmet'
import Button from '../../components/Button/Button'
import { useForm, useWatch } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import EmailDistributionList from '../../components/EmailDistribution/EmailDistributionList/EmailDistributionList'
import EmailDistributionConfirmInvitesModal from '../../components/EmailDistribution/EmailDistributionConfirmInvitesModal/EmailDistributionConfirmInvitesModal'
import { useToast } from '@chakra-ui/react'
import EmailDistributionStripeModal from '../../components/EmailDistribution/EmailDistributionStripeModal/EmailDistributionStripeModal'
import { auth, database, ref } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { useObject } from 'react-firebase-hooks/database'
import ClipLoader from 'react-spinners/ClipLoader'
import { setPaymentData } from '../../services/database'
import { sendInvitations } from '../../services/Invitations'
import { eventTimePassed } from '../../helpers'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required')
})

const EmailDistribution = () => {
  const { circleCode } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [pendingPayment, setPendingPayment] = useState(false)
  const [recipientModal, setRecipientModal] = useState(false)
  const [stripeModal, setStripeModal] = useState(false)
  const [recipients, setRecipients] = useState([])
  const [exampleEmailSent, setExampleEmailSent] = useState(false)
  const [user, loading] = useAuthState(auth)

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )

  const eventInfo = eventSnap?.val()
  const guestList = useMemo(() => {
    const guestsInfo = guestsSnap?.val() || {}
    return Object.entries(guestsInfo).map(([gKey, gData]) => ({
      id: gKey,
      ...gData
    }))
  }, [guestsSnap])

  const isTimePassed = eventTimePassed(eventInfo)
  const paymentRequired = !eventInfo?.isPremium

  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
    watch,
    setFocus
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      email: ''
    }
  })

  console.log('errors', errors)

  const emailToAdd = watch('email')

  const onSubmit = useCallback(
    (data) => {
      if (data.email && guestList.some((g) => g.email === data.email)) {
        setError('email', {
          message: 'This email is already in the guests list'
        })
        return
      }

      setRecipients([...recipients, data])

      clearErrors()
      reset()

      setTimeout(() => setFocus('name'), 0)
    },
    [recipients, guestList, reset, setFocus, setError, clearErrors]
  )

  const handleExampleEmail = () => {
    // TODO: Send Example Email
    setExampleEmailSent(true)
  }

  const onSendInvitations = () => {
    if (recipients.length === 0) {
      return
    }
    setLoading(true)
    setRecipientModal(true)
  }

  const onSendEmails = async () => {
    setRecipientModal(false)
    try {
      if (paymentRequired) {
        setStripeModal(true)
      } else {
        await sendInvitations(circleCode)
        setPaymentData({ circleCode, invitesSent: true })
        setLoading(false)
        navigate(`/event/${circleCode}/rsvps`, { replace: true })
      }
    } catch (e) {
      console.log('handleUpdateEvent', e)
      toast({
        status: 'error',
        title: e.message || 'Error while sending the invitations',
        position: 'top'
      })
      setLoading(false)
    }
  }

  const onSendToPayment = async () => {
    setPaymentData({ circleCode })
    setPendingPayment(true)
    window.open(
      `https://buy.stripe.com/${process.env.REACT_APP_STRIPE_LINK}?client_reference_id=${circleCode}`,
      '_blank',
      'noopener,noreferrer'
    )
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleSubmit(onSubmit)()
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleSubmit, onSubmit])

  useEffect(() => {
    if (!!pendingPayment && !!eventInfo && !!eventInfo.isPremium) {
      setLoading(false)
      navigate(`/event/${circleCode}/rsvps`, { replace: true })
    }
  }, [eventInfo, pendingPayment, navigate, circleCode])

  useEffect(() => {
    if (!user && !loading) {
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
      return
    }
    if (!eventLoading && !loading) {
      const isHost = eventInfo?.hostID === user?.uid
      if (!eventInfo || !isHost || isTimePassed) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
    }
  }, [
    eventLoading,
    loading,
    user,
    eventInfo,
    pendingPayment,
    isTimePassed,
    navigate,
    circleCode
  ])

  if (loading || eventLoading) {
    return (
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        blackLogo
        headerBackgroundColor={'transparent'}
        menuColor={'#000'}
        footerContinueText={'Send invites'}
        footerCancelText={'Back'}
        footerContinueOnClick={() => null}
        enableFooter
        footerContinueProps={{
          disabled: true
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Email distribution." />
        </Helmet>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )
  }

  return (
    <>
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        blackLogo
        headerBackgroundColor={'transparent'}
        menuColor={'#000'}
        footerContinueText={'Send invites'}
        footerCancelText={'Back'}
        footerContinueOnClick={onSendInvitations}
        footerCancelOnClick={() => navigate(-1)}
        enableFooter
        footerContinueProps={{
          disabled: recipients.length === 0,
          loading: isLoading
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Email distribution." />
        </Helmet>

        <div className="flex flex-1 flex-col">
          <span className="text-primary text-[20px] font-bold">
            Email distribution list
          </span>
          <p className="text-[15px]">
            Add emails to the list below to build your invite distribution list.
          </p>
          {exampleEmailSent ? (
            <p className="text-primary text-[14px] mt-2">
              ✓ Sent! Check your email ({user.email})
            </p>
          ) : (
            <p
              className="text-primary text-[14px] cursor-pointer underline mt-2"
              onClick={handleExampleEmail}
            >
              Email me an example of the invite
            </p>
          )}

          <div className="w-full rounded-md bg-off-white sm:mt-10 mt-5 flex flex-row p-5 items-center">
            <div className="flex flex-1 flex-col mr-5">
              <input
                {...register('name')}
                placeholder="Enter name"
                className={`bg-transparent outline-none ${errors.name ? 'text-error-opacity-50 placeholder:text-error-opacity-50' : ''}`}
              />
              <div className="w-full h-[1px] bg-[#80808033] sm:my-2 my-1"></div>
              <input
                {...register('email')}
                placeholder="Enter email"
                className={`bg-transparent outline-none ${errors.email ? 'text-error-opacity-50 placeholder:text-error-opacity-50' : ''}`}
              />
            </div>

            <div>
              <Button
                text={'+ Add'}
                className={'rounded-lg p-2'}
                size={'no-size'}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
          {errors.name ? (
            <div className="text-error mt-2">{errors.name.message}</div>
          ) : null}
          {errors.email ? (
            <div className="text-error mt-2">{errors.email.message}</div>
          ) : null}

          <div className="sm:mt-10 mt-5">
            <EmailDistributionList items={recipients} />
          </div>
        </div>
      </Base>
      {!!recipientModal ? (
        <EmailDistributionConfirmInvitesModal
          isOpen={recipientModal}
          circleCode={circleCode}
          recipients={recipients}
          onClose={() => setRecipientModal(false)}
          onConfirm={onSendEmails}
        />
      ) : null}
      {!!stripeModal ? (
        <EmailDistributionStripeModal
          isOpen={stripeModal}
          onClose={() => setStripeModal(false)}
          onConfirm={onSendToPayment}
        />
      ) : null}
    </>
  )
}

export default EmailDistribution
