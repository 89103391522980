import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addUserToMembers, createEventPlaylist } from '../../../services/Circle'
import { useToast } from '@chakra-ui/react'
import { getTokens } from '../../../services/helpers'
import { auth } from '../../../firebase'
import queryString from 'query-string'
import { useAuthState } from 'react-firebase-hooks/auth'
import ClipLoader from 'react-spinners/ClipLoader'
import * as amplitude from '@amplitude/analytics-browser'

const SelectProviderStep = ({
  circleCode,
  circleInfo,
  setProvider,
  setOption,
  setStep
}) => {
  const [authenticating, setAuthenticating] = useState(null)
  const toast = useToast()
  const navigate = useNavigate()
  const [user, loading] = useAuthState(auth)

  const params = useMemo(
    () =>
      typeof window != null ? queryString.parse(window.location.search) : {},
    []
  )

  const handleGuest = useCallback(async () => {
    console.log('handleGuest')
    try {
      const token = await getTokens('guest', null)
      if (!token.accessToken) return

      // logEvent(analytics, 'provider_connected', {
      //   source: 'STREAMING',
      //   provider: 'GUEST'
      // })

      if (!circleInfo.playlistID) {
        amplitude.track('Music Playlist added', {
          circleCode,
          status: 'success'
        })

        await createEventPlaylist({
          token,
          settingsToken: token,
          option: 'guest',
          provider: 'spotify',
          circleCode,
          name: circleInfo.name,
          type: circleInfo.type
        })
      } else {
        await addUserToMembers(
          auth.currentUser.uid,
          {
            uid: auth.currentUser.uid,
            email: auth.currentUser.email,
            option: 'guest',
            provider: 'spotify'
          },
          circleCode,
          [],
          [],
          []
        )
      }

      return true
    } catch (err) {
      console.log('fail', err)
      toast({
        status: 'error',
        title: err.message || 'Error while connecting with spotify',
        position: 'top'
      })
      if (!circleInfo.playlistID) {
        amplitude.track('Music Playlist added', {
          circleCode,
          status: 'failure'
        })
      }
      return false
    }
  }, [circleCode, circleInfo, toast])

  const handleSpotify = useCallback(async () => {
    console.log('handleSpotify')
    try {
      const settingsToken = await getTokens('guest', null)
      const token = await getTokens('spotify', `streaming-${circleCode}`)
      console.log('handleSpotify -> getProviderToken -> token', token)
      if (!token.accessToken) return

      // logEvent(analytics, 'provider_connected', {
      //   source: 'STREAMING',
      //   provider: 'SPOTIFY'
      // })

      if (!circleInfo.playlistID) {
        amplitude.track('Music Playlist added', {
          circleCode,
          status: 'success'
        })

        await createEventPlaylist({
          token,
          settingsToken,
          option: 'spotify',
          provider: 'spotify',
          circleCode,
          name: circleInfo.name,
          type: circleInfo.type
        })
      } else {
        await addUserToMembers(
          auth.currentUser.uid,
          {
            uid: auth.currentUser.uid,
            email: auth.currentUser.email,
            option: 'spotify',
            provider: 'spotify'
          },
          circleCode,
          [],
          [],
          []
        )
      }

      return true
    } catch (err) {
      console.log('fail', err)
      toast({
        status: 'error',
        title: err.message || 'Error while connecting with spotify',
        position: 'top'
      })
      if (!circleInfo.playlistID) {
        amplitude.track('Music Playlist added', {
          circleCode,
          status: 'failure'
        })
      }
      return false
    }
  }, [circleCode, circleInfo, toast])

  // const handleAppleMusic = useCallback(async () => {
  //   console.log('handleAppleMusic')
  //   try {
  //     const token = await getTokens('apple_music', `streaming-${circleCode}`)
  //     if (!token.appleMusicToken || !token.accessToken) return

  //     logEvent(analytics, 'provider_connected', {
  //       source: 'STREAMING',
  //       provider: 'APPLE_MUSIC'
  //     })

  //     if (!circleInfo.playlistID) {
  //       amplitude.track('Music Playlist added', {
  //         circleCode,
  //         status: 'success'
  //       })

  //       await createEventPlaylist({
  //         token,
  //         option: 'guest',
  //         provider: 'spotify',
  //         circleCode,
  //         name: circleInfo.name,
  //         type: circleInfo.type
  //       })
  //     } else {
  //       await addUserToMembers(
  //         auth.currentUser.uid,
  //         {
  //           uid: auth.currentUser.uid,
  //           email: auth.currentUser.email,
  //           option: 'guest',
  //           provider: 'spotify'
  //         },
  //         circleCode,
  //         [],
  //         [],
  //         []
  //       )
  //     }
  //     return true
  //   } catch (err) {
  //     console.log('fail', err)
  //     toast({
  //       status: 'error',
  //       title: err.message || 'Error while connecting with apple music',
  //       position: 'top'
  //     })
  //     if (!circleInfo.playlistID) {
  //       amplitude.track('Music Playlist added', {
  //         circleCode,
  //         status: 'failure'
  //       })
  //     }
  //     return false
  //   }
  // }, [circleCode, circleInfo, toast])

  const handleProvider = useCallback(async () => {
    if (!authenticating) return
    let success = false
    if (authenticating === 'guest') {
      success = await handleGuest()
    } else if (authenticating === 'spotify') {
      success = await handleSpotify()
    }
    // else if (authenticating === 'apple_music') {
    //   success = await handleAppleMusic()
    // }

    if (success) {
      if (params?.source === 'spotify') {
        navigate({ pathname: window.location.pathname })
      }
      setStep(1)
      setOption(authenticating)
      setProvider(authenticating === 'apple_music' ? 'apple_music' : 'spotify')
    }
  }, [authenticating])

  useEffect(() => {
    if (!!circleInfo && !!user && !loading && !authenticating) {
      console.log('useEffect Authenticating')
      if (params?.source === 'spotify') {
        setAuthenticating('spotify')
      } else {
        setAuthenticating('guest')
      }
    }
  }, [circleInfo, params, authenticating, user, loading])

  useEffect(() => {
    if (!!authenticating) {
      // Authenticate to music provider
      handleProvider().then(() => null)
    }
  }, [authenticating, handleProvider])

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <ClipLoader color="#5B4ABC" />
    </div>
  )
}

export default memo(SelectProviderStep)
