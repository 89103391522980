import * as React from "react"

function Plus({ fill, ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6v2z" fill={fill} />
    </svg>
  )
}

export default Plus
