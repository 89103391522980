import React, { useEffect, useState } from 'react'
import Modal from '../../Modal/Modal'
import DottedLine from '../../DottedLine/DottedLine'
import Toggle from '../../Toggle/Toggle'
import SquaresSquare from '../../Squares/SquaresSquare/SquaresSquare'
import Button from '../../Button/Button'
import { useWatch } from 'react-hook-form'

const NewEventCostModal = ({ opened, onClose, form }) => {
  const [squareCost, setSquareCost] = useState(undefined)
  const [venmo, setVenmo] = useState(undefined)
  const [cashapp, setCashapp] = useState(undefined)
  const [paypal, setPaypal] = useState(undefined)
  const [zelle, setZelle] = useState(undefined)
  const [allowCash, setAllowCash] = useState(false)

  const [
    squareCostValue,
    venmoValue,
    cashappValue,
    paypalValue,
    zelleValue,
    allowCashValue
  ] = useWatch({
    name: ['costPerSquare', 'venmo', 'cashapp', 'paypal', 'zelle', 'allowCash'],
    control: form.control
  })

  const onConfirm = () => {
    form.setValue('costPerSquare', squareCost)
    form.setValue('venmo', venmo)
    form.setValue('cashapp', cashapp)
    form.setValue('paypal', paypal)
    form.setValue('zelle', zelle)
    form.setValue('allowCash', allowCash)

    onClose()
  }

  useEffect(() => {
    setSquareCost(squareCostValue)
    setVenmo(venmoValue)
    setCashapp(cashappValue)
    setPaypal(paypalValue)
    setZelle(zelleValue)
    setAllowCash(allowCashValue)
  }, [
    allowCashValue,
    cashappValue,
    paypalValue,
    squareCostValue,
    venmoValue,
    zelleValue
  ])

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      className={'max-w-[450px] w-full mx-5'}
    >
      <div className="bg-white rounded-input relative pb-5 w-full flex flex-col">
        <div className="relative">
          <div className="absolute inset-0 w-full h-full left-2 sm:left-10 ">
            <DottedLine color={'#80808033'} />
          </div>

          <div className="sm:ml-10 ml-2 mt-5 z-50">
            <div className="flex items-center ml-3">
              <span className="mr-3 font-semibold">Cost per guest</span>
              <SquaresSquare
                placeholder={'$'}
                value={squareCost}
                onChangeText={(v) => setSquareCost(v)}
                placeholderBefore
              />
            </div>

            <div className="flex flex-row items-start mt-4">
              <div className="w-[3px] h-4 bg-primary" />

              <div className="flex flex-col ml-4">
                <span className="font-semibold leading-4">Payment methods</span>
                <span className="text-[12px]">
                  The way guests will pay you for event-related costs.
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-1 mt-3 ml-4">
              <div className="flex flex-row items-center mb-2">
                <span className="text-primary  cursor-pointer w-[85px]">
                  Venmo
                </span>
                <div
                  className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                >
                  <span className=" text-primary ml-1">@</span>
                  <input
                    className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                    value={venmo}
                    onChange={(e) => setVenmo(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center mb-2">
                <span className="text-primary  cursor-pointer w-[85px]">
                  Cash App
                </span>
                <div
                  className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                >
                  <span className=" text-primary ml-1">$</span>
                  <input
                    className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                    value={cashapp}
                    onChange={(e) => setCashapp(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center mb-2">
                <span className="text-primary  cursor-pointer w-[85px]">
                  Paypal
                </span>
                <div
                  className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                >
                  <span className=" text-primary ml-1">@</span>
                  <input
                    className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                    value={paypal}
                    onChange={(e) => setPaypal(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center mb-2">
                <span className="text-primary  cursor-pointer w-[85px]">
                  Zelle
                </span>
                <div
                  className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                >
                  <input
                    className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary placeholder:text-[14px] placeholder:text-primary`}
                    placeholder="Email or phone number"
                    value={zelle}
                    onChange={(e) => setZelle(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <div className="flex flex-row items-center">
                  <span className="text-primary  cursor-pointer mr-2">
                    Allow cash payment?
                  </span>
                  <Toggle
                    textOn="Yes"
                    textOff="No"
                    value={allowCash}
                    setValue={setAllowCash}
                  />
                </div>
                <span className="text-[12px]">
                  Guest will have the option to pay in cash you upon arrival.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center mt-4 justify-between px-5">
          <Button text={'Back'} type={'text'} onClick={onClose} />
          <Button text={'Continue'} onClick={onConfirm} />
        </div>
      </div>
    </Modal>
  )
}

export default NewEventCostModal
