import { initializeApp } from 'firebase/app'
import {
  getDatabase,
  orderByChild,
  orderByValue,
  query,
  ref,
  get,
  set,
  update,
  child
} from 'firebase/database'
import { getStorage } from 'firebase/storage'
import {
  GoogleAuthProvider,
  updateProfile as updateAuthProfile,
  signInAnonymously,
  sendSignInLinkToEmail,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signOut,
  initializeAuth,
  browserPopupRedirectResolver,
  browserLocalPersistence
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver
})
const database = getDatabase(app)
const storage = getStorage(app)
// const analytics = getAnalytics(app)

const getProfile = () => get(ref(database, `users/${auth.currentUser.uid}`))
const setProfile = (data) =>
  set(ref(database, `users/${auth.currentUser.uid}`), data)
const updateProfile = (data) =>
  update(ref(database, `users/${auth.currentUser.uid}`), data)

const getSettings = () => get(ref(database, 'settings'))
const updateSettings = (data) => update(ref(database, 'settings'), data)

export {
  database,
  storage,
  auth,
  ref,
  set,
  get,
  child,
  query,
  getSettings,
  updateSettings,
  getProfile,
  setProfile,
  updateProfile,
  orderByValue,
  orderByChild,
  GoogleAuthProvider,
  updateAuthProfile,
  isSignInWithEmailLink,
  signInAnonymously,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
}
