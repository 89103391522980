import React from 'react'
import { ReactComponent as CopySVG } from '../assets/icons/copy.svg'
import * as amplitude from '@amplitude/analytics-browser'

const CopyLink = ({ id, url, isDisabled }) => {
  const handleOnClink = async () => {
    amplitude.track('Link Copied', {
      circleCode: url
    })

    const location = typeof window != null ? window.location.origin : ''
    await navigator.clipboard.writeText(`${location}/${url}`)
    const button = document.getElementById(id)
    if (!button.classList.contains('animate')) {
      button.classList.add('animate')
      setTimeout(() => {
        button.classList.remove('animate')
      }, 3200)
    }
  }

  return (
    <button
      id={id}
      className={isDisabled ? 'button disabled' : 'button hover:bg-terciary hover:text-white'}
      type="button"
      title="Copy invite link"
      disabled={isDisabled}
      onClick={handleOnClink}
    >
      <div className={ "content pointer-events-none"}>
        <div className="copy">
          <CopySVG />
          <span className='font-medium'>Copy invite link</span>
        </div>
        <div className="copied">Copied</div>
      </div>
    </button>
  )
}

export default CopyLink
