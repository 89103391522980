import { database } from '../../firebase'
import { ref, update } from 'firebase/database'
import { post } from '../api'
import { v4 } from 'uuid'

export const sendIssue = async (uid, email, issue) => {
  try {
    const res = await post(`events/sendIssue`, { body: { uid, email, issue } })
    console.log('res', res.data)
  } catch (e) {
    console.log('sendIssue', e)
  }
}

export const sendEmail = async (type, extras) => {
  try {
    const emailId = v4().replace(/-/g, '')

    Object.keys(extras).forEach((key) => {
      if (extras[key] === undefined) {
        delete extras[key]
      }
    })

    await update(ref(database, `emails/${type}/${emailId}`), extras)
    console.log('Email', type, 'sent')
  } catch (e) {
    console.log('sendEmail', e)
  }
}
