import { post } from '../api'

export const sendInvitations = async (circleCode) => {
  try {
    const res = await post(`events/${circleCode}/sendInvites`)
    console.log('res', res.data)
  } catch (e) {
    console.log('sendInvitations', e)
  }
}
