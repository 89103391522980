import React, { useState } from 'react'
import {  
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/react'
import Button  from './Button/Button'
import { saveSelectedTracks } from '../services/Circle'
import OldPlaylist from './OldPlaylist'
import { getHostToken, getTokens } from '../services/helpers'
import { get, query, ref } from 'firebase/database'
import { database } from '../firebase'
import * as amplitude from '@amplitude/analytics-browser'

const SelectedModal = ({
  uid,
  hostID,
  isGuest,
  circleCode,
  selected,
  minLimit,
  maxLimit,
  connections,
  provider,
  option,
  popUp,
  setPopUp,
  toggleSelectSong,
  prevSelectedSongs,
  setTracksWError,
  onSelect
}) => {
  const [saving, setSaving] = useState(false)
  const toast = useToast()

  const handleSaveSelection = async () => {
    if (selected.length === 0) return
    setSaving(true)

    console.log('selected', selected)
    let token = {}

    try {
      // Fetch host tokens
      // if (isGuest) {
      //   const hostSnap = await get(query(ref(database, `users/${hostID}`)))
      //   const hostInfo = hostSnap?.val() || {}
      //   token = await getHostToken(hostInfo)
      // } else {
      // ALWAYS USE SETTINGS TOKEN
      token = await getTokens('guest', `streaming-${circleCode}`)
      // }
      if (!token.appleMusicToken && !token.accessToken) {
        toast({
          status: 'error',
          title: 'Error while refreshing tokens',
          position: 'top'
        })
        return
      }

      const tracksWErrror = await saveSelectedTracks(
        circleCode,
        selected,
        uid,
        provider,
        token,
        minLimit,
        maxLimit
      )

      amplitude.track('Song added', {
        circleCode,
        contributor: isGuest ? 'guest' : 'host'
      })

      if (tracksWErrror.length > 0) {
        tracksWErrror.forEach((track) => {
          track.withError = true
        })
        setTracksWError(tracksWErrror)
        setPopUp(null)
      } else {
        onSelect()
      }
    } catch (e) {
      console.log('handleSaveSelection', e)
    }
    setSaving(false)
  }

  return (
    <Modal
      isOpen={popUp === 'selected'}
      onClose={() => setPopUp(null)}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent mx="20px">
        <ModalHeader color="primary">Selected Songs</ModalHeader>
        <ModalCloseButton mt="5px" mr="0" />
        <ModalBody px="0" p={0} m={0}>
          <OldPlaylist
            type="selected"
            option="0"
            provider={provider}
            playlist={selected}
            circleCode={circleCode}
            maxLimit={maxLimit}
            selected={selected}
            prevSelectedSongs={prevSelectedSongs}
            toggleSelectSong={toggleSelectSong}
            connections={connections}
            mb='0'
            containerStyle={{
              paddingLeft: '20px',
              paddingRight: '20px',
              overflowY: 'scroll',
              maxHeight: '70vh'
            }}
          />
        </ModalBody>
        {selected.length > 0 && (
          <ModalFooter>
            <Flex flex="1" align="center" justify="space-between" mt="20px">
              <Button type="border" onClick={() => setPopUp(null)} text={'Add more'}/>
              <Button   onClick={handleSaveSelection} text={'Submit'}/>             
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default SelectedModal
