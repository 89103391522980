import React, { memo, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { useWatch } from 'react-hook-form'

const MultilineWithFixedPlaceholder = ({
  placeholder,
  className,
  fixedPlaceholder,
  register,
  hasError,
  form
}) => {
  const fixedPlaceholderRef = useRef(null)
  const placeholderRef = useRef(null)
  const inputRef = useRef(null)

  const value = useWatch({
    name: register.name,
    control: form?.control
  })

  useEffect(() => {
    if (value) {
      placeholderRef.current?.classList.add('hidden')
    } else {
      placeholderRef.current?.classList.remove('hidden')
    }
  }, [value])

  return (
    <div className={twMerge(`relative w-full h-28`, className)}>
      <textarea
        className={`${hasError ? 'border-error' : 'border-input-border'} border-1 rounded-input bg-transparent w-full p-2 outline-none h-full resize-none focus:border-primary focus:border-[2px]`}
        autoComplete="off"
        ref={(e) => {
          register.ref(e)
          inputRef.current = e
        }}
        {...register}
        onFocus={() => {
          fixedPlaceholderRef.current?.classList.add('text-primary')
          fixedPlaceholderRef.current?.classList.add('font-bold')
          placeholderRef.current?.classList.add('hidden')
        }}
        onBlur={() => {
          fixedPlaceholderRef.current?.classList.remove('text-primary')
          fixedPlaceholderRef.current?.classList.remove('font-bold')
          if (!value) {
            placeholderRef.current?.classList.remove('hidden')
          }
        }}
      />
      <span
        ref={fixedPlaceholderRef}
        className="absolute top-[-10px] text-placeholder pointer-events-none left-2.5 ease-linear duration-150 text-[12px] bg-white px-[4px] "
      >
        {fixedPlaceholder}
      </span>
      <span
        ref={placeholderRef}
        className="absolute top-2 text-placeholder pointer-events-none left-2.5 ease-linear duration-150 "
      >
        {placeholder}
      </span>
    </div>
  )
}
export default memo(MultilineWithFixedPlaceholder)
