import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import Button from '../../Button/Button'
import { Grid, useToast } from '@chakra-ui/react'
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage'
import { database, storage } from '../../../firebase'
import Modal from '../../Modal/Modal'
import { ref, update } from 'firebase/database'
import { XIcon, UploadIcon } from '../../../assets/icons'
import ClipLoader from 'react-spinners/ClipLoader'
import { ArrowDownIcon } from '../../../assets/icons'
import OptionImage from './OptionImage/OptionImage'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { categories } from '../../../utils/categories'

export const schema = yup
  .object({
    ocassion: yup.object()
  })
  .required()

const PhotoOrVideoModal = ({
  isOpen,
  handleOnClose,
  removeDesignOnCanva,
  overrideSelect,
  circleCode,
  action
}) => {
  const [uploading, setUploading] = useState(false)
  const fileInputRef = useRef(null)
  const toast = useToast()

  const options = categories.filter((c) => c.value !== 'other')

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [ocassion] = form.watch(['ocassion'])

  const selectedOcassion = useMemo(
    () => options.find((option) => option.value === ocassion),
    [options, ocassion]
  )

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = async (event, filesToUpload) => {
    const files = filesToUpload || Array.from(event.target.files)

    if (files.length === 0 || files.length > 1) return

    if (!!overrideSelect) {
      overrideSelect(files)
      return
    }

    setUploading(true)

    const uploadPromises = files.map(async (file) => {
      const storagePath = `events/${circleCode}/image`
      const fileRef = storageRef(storage, storagePath)
      const result = await uploadBytes(fileRef, file)
        .then(() => ({ success: true, path: storagePath }))
        .catch((error) => ({ success: false, path: storagePath, error }))
      if (!result.success) return null
      const url = await getDownloadURL(fileRef)
      return url
    })

    const results = await Promise.all(uploadPromises)
    const successUploads = results.filter((result) => !!result)
    const failedUploads = results.filter((result) => !result)

    if (successUploads.length > 0) {
      await update(ref(database, `circles/${circleCode}/info`), {
        url: successUploads[0]
      })
    }

    if (successUploads.length > 0) {
      toast({
        title: 'Upload successful',
        description: `${successUploads.length} files uploaded successfully.`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    }

    if (failedUploads.length > 0) {
      toast({
        title: 'Some uploads failed',
        description: `${failedUploads.length} files failed to upload.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
    handleOnClose()
    setUploading(false)
  }

  const handleSetImage = async (image) => {
    const response = await fetch(image)
    const blob = await response.blob()

    const parts = image.split('/')
    const fileName = parts[parts.length - 1]

    const file = new File([blob], fileName, { type: blob.type })

    if (!!overrideSelect) {
      overrideSelect([file])
      return
    }

    handleFileChange(null, [file])
  }

  useEffect(() => {
    form.setValue('ocassion', options[0].value)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      className={'max-w-[700px] w-full mx-5'}
    >
      <div className="p-2 bg-modal-bg rounded-input mx-3 md:mx-0 flex flex-col">
        <div className="flex justify-between mt-4 mx-5">
          <div className="gap-3 flex items-center">
            {uploading ? (
              <div className="flex items-center justify-center">
                <ClipLoader color="#5B4ABC" />
              </div>
            ) : (
              <>
                <div className="flex sm:flex-row flex-col gap-2">
                  <>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                    <Button
                      type="terciary"
                      size="small"
                      text={'Upload your own design'}
                      onClick={handleButtonClick}
                      className="text-sm flex items-center"
                      iconLeft={
                        <UploadIcon
                          className="mr-1"
                          fill={'#5B4ABC'}
                          width={20}
                          height={20}
                        />
                      }
                    />
                  </>
                  {!removeDesignOnCanva && (
                    <Button
                      type="terciary"
                      size="small"
                      text={'Design on Canva'}
                      onClick={action}
                      className="text-sm"
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div onClick={handleOnClose} className="cursor-pointer">
            <XIcon fill={'black'} width={25} height={25} />
          </div>
        </div>

        <div className="flex flex-col px-5 items-start my-5 overflow-hidden">
          <div className="relative max-w-[300px] w-full">
            <select
              className="flex-1 py-3 px-2 pr-8 outline-none cursor-pointer bg-transparent border border-black bg-off-white rounded-md w-full appearance-none"
              {...form.register('ocassion')}
              autoFocus
            >
              {options.map((c) => (
                <option key={c.value} value={c.value}>
                  {c.label}
                </option>
              ))}
            </select>
            <ArrowDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none" />
          </div>

          <Grid
            templateColumns={{
              base: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)'
            }}
            gap={3}
            className="overflow-scroll no-scrollbar mt-3 sm:max-h-[500px] max-h-[400px] min-h-[300px] flex items-center"
          >
            {selectedOcassion?.images?.map((image, key) => (
              <OptionImage
                key={key}
                image={image}
                incomingKey={key}
                handleSetImage={handleSetImage}
              />
            ))}
          </Grid>
        </div>
      </div>
    </Modal>
  )
}

export default memo(PhotoOrVideoModal)
