import React from 'react'
import Button from '../../Button/Button'
import SuperBowlImage from '../../../assets/images/home/super-bowl.png'
import { useNavigate } from 'react-router-dom'

const SuperBowl = ({ handleCreateEvent }) => {
  const navigate = useNavigate()

  const onLearnMore = () => {
    navigate('/superbowl/blog')
  }

  return (
    <div className="flex justify-center relative">
      <div className="w-full flex items-center bg-black md:flex-row flex-col max-w-[1200px]">
        <div className="flex items-center gap-3 md:mr-5 mr-0">
          <img
            src={SuperBowlImage}
            className="lg:w-[600px] md:w-[400px] w-full"
          />
        </div>

        <div className="flex flex-col flex-1 h-full p-3">
          <div className="flex flex-1 flex-col justify-start h-full mt-2">
            <span className="font-medium text-[17px] text-super-bowl-purple">
              Coming soon!
            </span>
            <span className="font-medium text-[18px] text-white">
              Get Ready for Game Day
            </span>
            <span className="font-medium text-[18px] text-white">
              Introducing Super Bowl Squares on Coteri
            </span>

            <span className="text-white mt-4">
              With Coteri's easy-to-use platform, guests can join in on the fun,
              track their squares in real-time, and take the competition to the
              next level-all while you focus on hosting the perfect party.
            </span>
          </div>

          <div className="flex items-center justify-between lg:mt-0 mt-5">
            <span
              className="underline cursor-pointer text-white"
              onClick={onLearnMore}
            >
              Learn more
            </span>
            <Button
              text={'Plan your Super Bowl'}
              className={'self-end mb-2 border-white text-black bg-white'}
              type={'border'}
              onClick={() => handleCreateEvent('/event/new?view=super-bowl')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuperBowl
