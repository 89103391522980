import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Base from './base'
import { Flex } from '@chakra-ui/react'

const CanvaCallback = () => {
  const { circleCode } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/event/${circleCode}`, {
      replace: true
    })
  }, [circleCode, navigate])

  return (
    <Base hideMenu={true}>
      <Flex flex="1" align="center" justify="center"></Flex>
    </Base>
  )
}

export default CanvaCallback
