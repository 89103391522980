import { post } from '../api'

export const startGuestVerification = async ({
  info,
  channel,
  deviceOTP,
  deviceName,
  circleCode,
  guestId,
  simulateProd
}) => {
  try {
    if (channel === 'email') {
      const res = await post(`events/startEmailVerification`, {
        body: {
          email: info,
          channel,
          deviceOTP,
          deviceName,
          circleCode,
          guestId,
          simulateProd
        }
      })
      return res
    }

    const res = await post(`events/startPhoneVerification`, {
      body: {
        phone: info,
        channel,
        deviceOTP,
        deviceName,
        circleCode,
        guestId,
        simulateProd
      }
    })
    return res
  } catch (e) {
    console.log('startGuestVerification', e)
    return { status: 'error' }
  }
}

export const verifyGuest = async ({
  circleCode,
  guestId,
  info,
  channel,
  code,
  trustDevice,
  deviceName
}) => {
  try {
    if (channel === 'email') {
      const res = await post(`events/verifyEmail`, {
        body: {
          circleCode,
          guestId,
          email: info,
          code,
          trustDevice,
          deviceName
        }
      })
      return res
    }

    const res = await post(`events/verifyPhone`, {
      body: { circleCode, guestId, phone: info, code, trustDevice, deviceName }
    })
    return res
  } catch (e) {
    console.log('verifyGuest', e)
    return { status: 'error' }
  }
}
