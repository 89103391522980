import React, { memo, useEffect } from 'react'
import Modal from '../../Modal/Modal'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../Input/Input'
import Button from '../../Button/Button'
import ClipLoader from 'react-spinners/ClipLoader'
import { update } from 'firebase/database'
import { useParams } from 'react-router-dom'
import { ref, database } from '../../../firebase'
import { useToast } from '@chakra-ui/react'

export const schema = yup
  .object({
    name: yup.string()
  })
  .required()

const EditEvent = ({ isOpen, handleOnClose, eventInfo }) => {
  const { circleCode } = useParams()
  const toast = useToast()

  const form = useForm({
    resolver: yupResolver(schema),
  })

  const handleFormSubmit = (form) => {
    try {
      update(ref(database, `circles/${circleCode}/info`), {
        name: form.name
      })

      toast({
        status: 'success',
        title: 'Name updated',
        position: 'top'
      })

      handleOnClose()
    } catch (err) {
      console.log('EditEvent: ', err)
    }
  }

  useEffect(() => {
    if (eventInfo) {
      form.setValue('name', eventInfo.name)
    }
  }, [eventInfo, form])

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <div className="p-10 bg-white rounded-input mx-3 md:mx-0">
        <h2>Change your event name</h2>

        <Input
          form={form}
          placeholder="Event name"
          className="mt-[15px] w-full"
          register={form.register('name')}
        />

        <div className="mt-[15px] flex flex-row items-center justify-between">
          {form.formState.isSubmitting ? (
            <div className="flex flex-1 items-center justify-center">
              <ClipLoader color="#5B4ABC" />
            </div>
          ) : (
            <React.Fragment>
              <Button type="text" text={'Cancel'} onClick={handleOnClose} />
              <Button
                text={'Confirm'}
                onClick={form.handleSubmit(handleFormSubmit)}
                disabled={!form.formState.isDirty}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default memo(EditEvent)
