import * as React from 'react'

function MusicFlow({ fill, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      viewBox="0 0 768 767.999994"
      height={64}
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path d="M34.426 357H51v54H34.426zm0 0" />
        </clipPath>
        <clipPath id="b">
          <path d="M195 233.602h17v300.75h-17zm0 0" />
        </clipPath>
        <clipPath id="c">
          <path d="M717 338h16.426v92H717zm0 0" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          fill="#422d8f"
          d="M42.555 410.547a8.128 8.128 0 01-8.13-8.129v-36.863a8.13 8.13 0 018.13-8.13 8.128 8.128 0 018.129 8.13v36.863a8.125 8.125 0 01-8.13 8.129zm0 0"
        />
      </g>
      <path
        fill="#422d8f"
        d="M82.723 461.797a8.129 8.129 0 01-8.13-8.133V314.31a8.128 8.128 0 018.13-8.13 8.13 8.13 0 018.129 8.13v139.355a8.131 8.131 0 01-8.13 8.133zm0 0M122.89 453.086a8.128 8.128 0 01-8.128-8.129V323.02a8.125 8.125 0 018.129-8.13 8.128 8.128 0 018.129 8.13v121.937a8.13 8.13 0 01-8.13 8.129zm0 0M163.059 470.29a8.129 8.129 0 01-8.13-8.134v-156.34a8.128 8.128 0 018.13-8.128 8.13 8.13 0 018.129 8.128v156.34a8.131 8.131 0 01-8.13 8.133zm0 0"
      />
      <g clipPath="url(#b)">
        <path
          fill="#422d8f"
          d="M203.227 534.086a8.131 8.131 0 01-8.133-8.129V242.016a8.131 8.131 0 0116.261 0v283.941a8.13 8.13 0 01-8.128 8.129zm0 0"
        />
      </g>
      <path
        fill="#422d8f"
        d="M243.39 509.7a8.13 8.13 0 01-8.128-8.13V266.406a8.128 8.128 0 018.129-8.129 8.129 8.129 0 018.132 8.13V501.57a8.131 8.131 0 01-8.132 8.13zm0 0M283.559 498.813a8.13 8.13 0 01-8.13-8.13v-213.39a8.128 8.128 0 018.13-8.129 8.129 8.129 0 018.132 8.129v213.39a8.131 8.131 0 01-8.132 8.13zm0 0M323.727 462.23a8.13 8.13 0 01-8.13-8.128V313.875a8.129 8.129 0 1116.258 0v140.227a8.128 8.128 0 01-8.128 8.128zm0 0M363.895 430.875a8.13 8.13 0 01-8.13-8.129V345.23a8.129 8.129 0 1116.258 0v77.516a8.128 8.128 0 01-8.128 8.129zm0 0M404.063 416.285a8.128 8.128 0 01-8.13-8.129v-48.34a8.13 8.13 0 018.13-8.128 8.128 8.128 0 018.128 8.128v48.34a8.125 8.125 0 01-8.128 8.13zm0 0M444.23 416.285a8.125 8.125 0 01-8.128-8.129v-48.34a8.128 8.128 0 018.128-8.128 8.13 8.13 0 018.13 8.128v48.34a8.128 8.128 0 01-8.13 8.13zm0 0M484.398 493.586a8.128 8.128 0 01-8.128-8.129V282.52a8.125 8.125 0 018.128-8.13 8.128 8.128 0 018.13 8.13v202.937a8.13 8.13 0 01-8.13 8.129zm0 0M524.566 520.805a8.131 8.131 0 01-8.132-8.13V255.302a8.129 8.129 0 018.132-8.13 8.128 8.128 0 018.13 8.13v257.375a8.13 8.13 0 01-8.13 8.129zm0 0M564.73 504.363a8.128 8.128 0 01-8.128-8.129V271.738a8.131 8.131 0 0116.262 0v224.496a8.129 8.129 0 01-8.134 8.13zm0 0M604.898 479.813a8.128 8.128 0 01-8.128-8.13V296.29a8.131 8.131 0 0116.262 0v175.395a8.129 8.129 0 01-8.134 8.128zm0 0M645.066 528.29a8.13 8.13 0 01-8.129-8.13V247.816a8.128 8.128 0 018.13-8.129 8.125 8.125 0 018.128 8.13V520.16a8.128 8.128 0 01-8.129 8.13zm0 0M685.234 455.957a8.128 8.128 0 01-8.129-8.129V320.145a8.13 8.13 0 018.13-8.13 8.128 8.128 0 018.128 8.13v127.683a8.125 8.125 0 01-8.129 8.129zm0 0"
      />
      <g clipPath="url(#c)">
        <path
          fill="#422d8f"
          d="M725.402 429.215a8.13 8.13 0 01-8.129-8.13v-74.194a8.131 8.131 0 018.13-8.133 8.131 8.131 0 018.128 8.133v74.195a8.13 8.13 0 01-8.129 8.129zm0 0"
        />
      </g>
    </svg>
  )
}

export default MusicFlow
