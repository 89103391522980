import React from 'react'

const SquaresSummaryModalItem = ({ item }) => {
  return (
    <div className='flex items-center justify-between'>
      <span className='text-[14px] flex flex-[0.3]'>{item.name || 'Owner'}</span>
      <span className='text-[14px] flex flex-[0.2]'>{item.initials}</span>
      <span className='text-[14px] flex flex-[0.3]'>{item.squares} squares</span>
    </div>
  )
}

export default SquaresSummaryModalItem
