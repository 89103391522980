import * as React from 'react'

function Clock({ fill, ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.403a10.597 10.597 0 100 21.194 10.597 10.597 0 000-21.194zM2.923 12a9.077 9.077 0 1118.154 0 9.077 9.077 0 01-18.154 0zM12.8 7.2a.8.8 0 00-1.6 0V12a.802.802 0 00.234.566l3.2 3.2a.801.801 0 001.133-1.132L12.8 11.669V7.2z"
        fill={fill || "#422d8f"}
      />
    </svg>
  )
}

export default Clock
