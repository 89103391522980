import React, { memo } from 'react'
import FeaturesCard from '../FeaturesCard/FeaturesCard'

const Features = () => {
  return (
    <div className="relative flex flex-col py-[50px] justify-between mx-0 lg:mx-0 w-full">
      <div className="flex items-center justify-center w-full">
        <div className="flex flex-col w-full">
          <span className="text-xl font-bold text-secondary mb-5 stripe-column:mb-0 ml-[10px]">
            Features
          </span>

          <div className="flex stripe-column-2rows:flex-row flex-col items-start justify-between relative">
            <div
              className={
                'flex stripe-column:flex-row flex-col items-start justify-between w-full'
              }
            >
              <FeaturesCard
                title={'Design cards'}
                text={
                  'Upload a design, select from one of ours, or design your own. Coteri integrates with Canva to provide you with thousands of free templates to design your personalized card.'
                }
                className={'mb-5 stripe-column:mb-0'}
              />
              <FeaturesCard
                title={'Create “What to Bring” lists'}
                text={
                  'Coordinate with your guests on food, drink, and more. Add items to the what to bring list for your guests to pick from.'
                }
                className={'mb-5 stripe-column:mb-0'}
              />
            </div>
            <div
              className={
                'flex stripe-column:flex-row flex-col items-start justify-between w-full'
              }
            >
              <FeaturesCard
                title={'Track responses'}
                text={
                  'Copy the invite link and send via email or text, or print cards with a QR code.'
                }
                className={'mb-5 stripe-column:mb-0'}
              />
              <FeaturesCard
                title={'Curate music playlists'}
                text={
                  'A guided process helps craft a playlist that your guests will love.'
                }
                className={'mb-5 stripe-column:mb-0'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Features)
