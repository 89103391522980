import * as React from 'react'

function ArrowDown({ opened, fill, ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transition: 'transform 0.3s ease' }}
      transform={`rotate(${opened ? 180 : 0})`}
      {...props}
    >
      <g clipPath="url(#clip0_188_179)">
        <path d="M7 10.084l5 4.791 5-4.791H7z" fill={fill || "#000"} />
      </g>
      <defs>
        <clipPath id="clip0_188_179">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0H24V23H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowDown
