import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import RegisterBox from '../components/RegisterBox'
import Base from './base'
import { Flex } from '@chakra-ui/react'
import querystring from 'query-string'

const Register = () => {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const params =
    typeof window != null ? querystring.parse(window.location.search) : {}

  useEffect(() => {
    if (user && !loading) {
      if (!!params.redirect_url) {
        navigate(params.redirect_url, { replace: true })
      } else {
        navigate('/', { replace: true })
      }
    }
  }, [user, loading])

  if (loading) return <></>

  return (
    <Base hideMenu={true}>
      <Flex justify="center">
        <Flex w="450px">
          <RegisterBox />
        </Flex>
      </Flex>
    </Base>
  )
}

export default Register
