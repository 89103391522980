import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import Input from '../../../components/Input/Input'
import { PlusIcon, SearchIcon } from '../../../assets/icons'
import ClipLoader from 'react-spinners/ClipLoader'
import MusicCard from '../../../components/EventDetails/MusicCard/MusicCard'
import Button from '../../../components/Button/Button'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getTokens, maxLimitSongs } from '../../../services/helpers'
import {
  getSpotifyRecommendations,
  searchSpotify
} from '../../../services/Spotify'
import { searchAppleMusic } from '../../../services/AppleMusic'
import { Container, Flex, useToast } from '@chakra-ui/react'
import SelectedModal from '../../../components/SelectedModal'
import TracksWErrorModal from '../../../components/TracksWErrorModal'
import SimilarSongsModal from '../../../components/SimilarSongsModal'
import {
  get,
  limitToFirst,
  orderByKey,
  query,
  ref,
  startAfter,
  startAt
} from 'firebase/database'
import { auth, database } from '../../../firebase'
import { useNavigate } from 'react-router-dom'
import {
  fetchLibrary,
  fetchRecentTracks,
  fetchTopTracks
} from '../../../services/Circle/user'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useObject } from 'react-firebase-hooks/database'
import { useRef } from 'react'
import AddSongsViewCreatedEvent from '../../../components/EventDetailsView/AddSongsViewCreatedEvent/AddSongsViewCreatedEvent'
import { getSettingsData, setSettingsData } from '../../../services/database'
import { uniqBy, prop } from 'ramda'
import { CheckIcon } from '@chakra-ui/icons'

const PAGE_SIZE = 15

const schema = yup
  .object({
    search: yup.string().notRequired(),
    filter: yup.string().notRequired()
  })
  .required()

const SelectSongsStep = ({
  circleCode,
  circleInfo,
  rsvpId,
  provider,
  option
}) => {
  const [searchResults, setSearchResults] = useState([])
  const [popUp, setPopUp] = useState(null)
  const [fullPlaylist, setFullPlaylist] = useState([])
  const [selected, setSelected] = useState([])
  const [tracksWError, setTracksWError] = useState([])
  const [trackWithError, setTrackWithError] = useState(null)
  const [isFetching, setFetching] = useState(false)
  const pageRef = useRef(0)
  const toast = useToast()
  const navigate = useNavigate()
  const [user, loading] = useAuthState(auth)
  const [firstTimeModal, setFirstTimeModal] = useState(false)

  const [memberSnap, memberLoading] = useObject(
    ref(
      database,
      `circles/${circleCode || '1'}/members/${user?.uid || '1'}/userInfo`
    )
  )
  const [prevSelectedSongsSnap, prevSelectedSongsLoading] = useObject(
    ref(
      database,
      `circles/${circleCode || '1'}/members/${user?.uid || rsvpId || '1'
      }/selected`
    )
  )
  const memberInfo = memberSnap?.val()
  const prevSelectedSongs = useMemo(
    () => prevSelectedSongsSnap?.val() || [],
    [prevSelectedSongsSnap]
  )
  const maxLimit = maxLimitSongs(memberInfo?.order)
  const minLimit = 0 // minLimitSongs(memberInfo?.order)
  // const limitReached =
  //   maxLimit !== -1 && (prevSelectedSongs || []).length >= maxLimit

  const filterOptions = useMemo(
    () =>
      [
        prevSelectedSongs.length > 0
          ? {
            value: 'recommendations',
            label: 'Coteri suggestions'
          }
          : null,
        {
          value: '4zAkxb3J6a5cM6KAP0nVGm',
          label: 'Popular Today'
        },
        {
          value: '7ejtgMFVvgow5Pk2Zq3KeG',
          label: 'Popular All Time'
        },
        {
          value: '6xMqitAbn0EF8pQs5UVUcZ',
          label: '2010s'
        },
        {
          value: '6oVSYR8QHmeN2tWSFqRm6o',
          label: '2000s'
        },
        {
          value: '16Zwb7N1OCH4JqzOAL9FLx',
          label: '1990s'
        },
        {
          value: '5pD9zzBjztHtTrABt7Abcb',
          label: '1980s'
        },
        {
          value: '3gHktisWbRE7EulXb0VCVh',
          label: '1970s'
        },
        {
          value: '0yN4owB2wQJ41oZFo9u8X3',
          label: '1960s'
        },
        option !== 'guest'
          ? {
            value: 'recently',
            label: 'Recently played songs'
          }
          : null,
        option !== 'guest'
          ? {
            value: 'library',
            label: 'Liked songs'
          }
          : null
      ].filter((x) => !!x),
    [prevSelectedSongs, option]
  )

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [search, filter] = form.watch(['search', 'filter'])

  const searchProvider = useCallback(
    async (reset) => {
      console.log('searchProvider', option, provider)
      if (!provider || !option) return []
      const searchText = search

      let results = []

      try {
        const token = await getTokens(option, `streaming-${circleCode}`)
        if (!token.appleMusicToken && !token.accessToken) return []

        if (provider === 'spotify') {
          results = await searchSpotify(
            token.accessToken,
            searchText,
            (pageRef.current - 1) * PAGE_SIZE,
            PAGE_SIZE
          )
        } else {
          results = await searchAppleMusic(
            token.appleMusicToken,
            'us',
            searchText,
            token.accessToken,
            (pageRef.current - 1) * PAGE_SIZE,
            PAGE_SIZE
          )
        }

        const accTracks = [
          ...(reset ? [] : searchResults),
          ...results.filter((x) => !!x)
        ]
        return uniqBy(prop('isrc'), accTracks)
      } catch (e) {
        setFetching(false)
        toast({
          title: e.message || 'Error while searching track',
          position: 'top',
          status: 'error'
        })
        return []
      }
    },
    [option, provider, search, circleCode, searchResults, toast]
  )

  const searchSimilars = (item) => {
    setTrackWithError(item)
  }

  const toggleSelectSong = (item) => {
    const _selected = selected.concat([])
    const selectedIdx = _selected.findIndex((x) => x.isrc === item.isrc)
    if (selectedIdx > -1) {
      _selected.splice(selectedIdx, 1)
    } else {
      _selected.push(item)
    }
    if (_selected.length === 0) {
      setPopUp(false)
    }
    setSelected(_selected)
  }

  const replaceSong = (item, replace) => {
    const _tracks = tracksWError.concat([])
    const trackIdx = _tracks.findIndex((x) => x.isrc === item.isrc)
    if (trackIdx > -1) {
      _tracks[trackIdx] = { ...replace, original: item.original || item }
      setTracksWError(_tracks)
    }
    setTrackWithError(null)
  }

  const handleSelect = () => {
    // logEvent(analytics, 'songs_selected', {
    //   source: 'SELECT_SONGS_EVENT'
    // })
    navigate(`/event/${circleCode}`)
  }

  const handlePreviousStep = () => {
    navigate(`/event/${circleCode}`)
  }

  const handleConfirm = () => {
    if (selected.length === 0) {
      handleSelect()
    } else {
      setPopUp('selected')
    }
  }

  const handleLoadMoreSongs = () => {
    const playlist = search ? searchResults : fullPlaylist

    if (playlist.length < pageRef.current * PAGE_SIZE) {
      toast({
        status: 'info',
        title: 'No more songs to load',
        position: 'top'
      })
      return
    }

    pageRef.current = pageRef.current + 1
    setFetching(true)
    if (search) {
      searchProvider(false).then((tracks) => {
        setSearchResults(tracks)
        setFetching(false)
      })
    } else {
      fetchPlaylist(filter.value, false).then((tracks) => {
        setFullPlaylist(tracks)
        setFetching(false)
      })
    }
  }

  const fetchPlaylist = useCallback(
    async (val, reset) => {
      if (!provider || !option) return []

      try {
        let tracks = []

        const token = await getTokens(option, `streaming-${circleCode}`)

        if (!token.appleMusicToken && !token.accessToken) {
          toast({
            status: 'error',
            title: 'Error while refreshing tokens',
            position: 'top'
          })
          return []
        }

        switch (val) {
          case 'recommendations':
            // Get Latest tracks
            const latestTracksSnap = await get(
              query(
                ref(database, `circles/${circleCode}/latestTracks`),
                orderByKey(),
                limitToFirst(5)
              )
            )
            const latestTracks = latestTracksSnap.val() || []

            if (latestTracks.length > 0) {
              tracks = await getSpotifyRecommendations(
                token.accessToken,
                latestTracks,
                (pageRef.current - 1) * PAGE_SIZE,
                PAGE_SIZE
              )
            }
            break
          case 'recently':
            tracks = await fetchRecentTracks(
              provider,
              {
                spotifyToken: token.accessToken,
                appleMusicToken: token.appleMusicToken
              },
              (pageRef.current - 1) * PAGE_SIZE,
              PAGE_SIZE
            )
            break
          case 'top':
            tracks = await fetchTopTracks(
              provider,
              {
                spotifyToken: token.accessToken,
                appleMusicToken: token.appleMusicToken
              },
              (pageRef.current - 1) * PAGE_SIZE,
              PAGE_SIZE
            )
            break
          case 'library':
            tracks = await fetchLibrary(
              provider,
              {
                spotifyToken: token.accessToken,
                appleMusicToken: token.appleMusicToken
              },
              (pageRef.current - 1) * PAGE_SIZE,
              PAGE_SIZE
            )
            break
          default:
            const playlistTracksSnap = await get(
              query(
                ref(database, `coteriPlaylists/${val}/tracks`),
                orderByKey(),
                startAt(((pageRef.current - 1) * PAGE_SIZE).toString()),
                limitToFirst(PAGE_SIZE)
              )
            )
            const playlistTracks = playlistTracksSnap.val() ?? []
            console.log('playlistTracks', playlistTracks)
            tracks = Array.isArray(playlistTracks)
              ? playlistTracks
              : Object.values(playlistTracks)
            break
        }

        const accTracks = [
          ...(reset ? [] : fullPlaylist),
          ...tracks.filter((x) => !!x)
        ]
        return uniqBy(prop('id'), accTracks)
      } catch (e) {
        setFetching(false)
        toast({
          status: 'error',
          title: e.message || 'Error while fetching tracks',
          position: 'top'
        })
        return []
      }
    },
    [provider, option, circleCode, fullPlaylist, toast]
  )

  useEffect(() => {
    if (search) {
      pageRef.current = 1
      setFetching(true)
      setFullPlaylist([])
      searchProvider(true).then((tracks) => {
        setSearchResults(tracks)
        setFetching(false)
      })
    } else {
      if (pageRef.current > 0) {
        pageRef.current = 1
        setFetching(true)
        setSearchResults([])
        fetchPlaylist(filter.value, true).then((tracks) => {
          setFullPlaylist(tracks)
          setFetching(false)
        })
      }
    }
  }, [search])

  useEffect(() => {
    if (!!circleInfo) {
      const settingsData = getSettingsData() ?? {}
      if (!settingsData.firstTimeAddSongs) {
        setFirstTimeModal(true)
        setSettingsData({ ...settingsData, firstTimeAddSongs: true })
      }
    }
  }, [circleInfo])

  useEffect(() => {
    if (!!filter?.value) {
      console.log('Filter changed', filter?.value)
      pageRef.current = 1
      setFetching(true)
      fetchPlaylist(filter.value, true).then((tracks) => {
        setFullPlaylist(tracks)
        setFetching(false)
      })
    }
  }, [filter, provider, option])

  useEffect(() => {
    if (!loading && !prevSelectedSongsLoading && !memberLoading) {
      if (!memberInfo) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      if (!filter?.value) {
        console.log('One Time Set filter')
        form.setValue('filter', filterOptions[0])
      }
    }
  }, [
    loading,
    circleCode,
    prevSelectedSongsLoading,
    memberLoading,
    memberInfo,
    selected,
    navigate,
    filter,
    form,
    filterOptions
  ])

  return (
    <>
      <div className="mt-[20px] flex flex-1 flex-col visible">
        <h2>
          Search for artists or songs you’d like to listen to at your event.
        </h2>

        <span className="text-[14px] font-medium my-3">
          Click
          <span
            className={`w-[18px] h-[18px] inline-flex border-terciary border-1 items-center justify-center rounded-full mx-1`}
            style={{ verticalAlign: -7 }}
          >
            <PlusIcon fill={'#120a31'} />
          </span>
          to add songs to your cart, then click “Continue” when you’re ready to
          add them to the playlist.
        </span>

        <div className="flex w-full items-end justify-end">
          <div
            className="rounded-md bg-music-card px-4 py-2 cursor-pointer"
            onClick={handleConfirm}
          >
            <span className="text-[14px] font-medium mr-2 pointer-events-none">
              {selected?.length} {selected?.length > 1 ? 'songs' : 'song'} added
            </span>

            <CheckIcon color={'#422D8F'} />
          </div>
        </div>

        <div className="flex items-center mt-[15px] sm:flex-row flex-col gap-4">
          <Input
            register={form.register('filter')}
            type={'select'}
            placeholder="Select filter"
            options={filterOptions}
            form={form}
            disabled={!filter?.value}
          />
          <Input
            register={form.register('search')}
            form={form}
            type="icon"
            placeholder="Search for an artist or song..."
            className="w-full"
            iconRight={<SearchIcon fill="#000" />}
            disabled={!filter?.value}
          />
        </div>

        <div id="songs" className="flex flex-1 mb-28">
          {loading ||
            memberLoading ||
            prevSelectedSongsLoading ||
            (isFetching && pageRef.current === 1) ? (
            <div className="flex flex-1 justify-center mt-10 h-[710px]">
              <ClipLoader color="#5B4ABC" />
            </div>
          ) : (
            <div className="flex flex-1 mt-[15px] w-full flex-col p-2">
              {[...(search ? searchResults : fullPlaylist)].map(
                (music, index) => (
                  <MusicCard
                    key={index}
                    music={music}
                    canPlay={true}
                    className="mb-3"
                    onClick={() => toggleSelectSong(music)}
                    checked={[...selected, ...prevSelectedSongs].find(
                      (selectedMusic) => music.id === selectedMusic.id
                    )}
                    provider={provider}
                  />
                )
              )}
              {(search ? searchResults : fullPlaylist).length <
                pageRef.current * PAGE_SIZE && !isFetching ? null : (
                <div className="w-full flex items-center justify-center">
                  <Button
                    text={'Load more songs'}
                    size={'medium'}
                    onClick={handleLoadMoreSongs}
                    className={'max-w-[250px]'}
                    loading={isFetching}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Flex bottom="0" left="0" right="0" position="fixed" zIndex="2">
        <Container
          bg="white"
          w="100%"
          maxW="800px"
          borderTopWidth="1px"
          borderColor="#EEE"
          py="16px"
          px={{ base: '16px', md: '36px' }}
          boxShadow={{ base: 'none', md: '2px 4px 12px rgb(0 0 0 / 8%)' }}
        >
          <div className="flex flex-row justify-between items-end">
            <Button
              type="text"
              disabled={memberLoading || prevSelectedSongsLoading}
              text={'Back'}
              onClick={handlePreviousStep}
            />
            <Button
              disabled={memberLoading || prevSelectedSongsLoading}
              text={'View selections'}
              onClick={handleConfirm}
              iconRight={
                <span
                  className="ml-2 inline-flex items-center justify-center rounded-full bg-off-white"
                  style={{ width: '28px', height: '28px' }}
                >
                  <span className="text-secondary">
                    {selected?.length > 9 ? '9+' : selected?.length}
                  </span>
                </span>
              }
              className={'flex-row items-center justify-center'}
            />
          </div>
        </Container>
      </Flex>
      <SelectedModal
        uid={user?.uid || rsvpId}
        isGuest={false}
        popUp={popUp}
        circleCode={circleCode}
        setPopUp={setPopUp}
        provider={provider}
        option={option}
        selected={selected}
        minLimit={minLimit}
        maxLimit={maxLimit}
        connections={[]}
        setTracksWError={setTracksWError}
        prevSelectedSongs={prevSelectedSongs}
        toggleSelectSong={toggleSelectSong}
        onSelect={handleSelect}
      />
      <TracksWErrorModal
        uid={user?.uid || rsvpId}
        selected={selected}
        tracksWError={tracksWError}
        setTracksWError={setTracksWError}
        setTrackWithError={setTrackWithError}
        provider={provider}
        option={option}
        circleCode={circleCode}
        minLimit={minLimit}
        maxLimit={maxLimit}
        connections={[]}
        searchSimilars={searchSimilars}
        onSelect={handleSelect}
      />
      <SimilarSongsModal
        track={trackWithError}
        circleCode={circleCode}
        circleInfo={circleInfo}
        provider={provider}
        option={option}
        maxLimit={maxLimit}
        selected={selected}
        prevSelectedSongs={prevSelectedSongs}
        connections={[]}
        replaceSong={replaceSong}
        setTrackWithError={setTrackWithError}
      />
      {!!firstTimeModal ? (
        <AddSongsViewCreatedEvent
          isOpen={firstTimeModal}
          onClose={() => setFirstTimeModal(false)}
        />
      ) : null}
    </>
  )
}

export default memo(SelectSongsStep)
