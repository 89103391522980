import React, { memo } from 'react'
import { twMerge } from 'tailwind-merge'

const FeaturesCard = ({ title, text, step, className }) => {
  return (
    <div className={twMerge("flex items-center w-full", className)}>
      <div>
        <div className="flex items-center">
          <div className="h-5 w-[2px] bg-secondary mr-2" />
          {step ? (
            <div className="text-[12px] font-bold text-stone-400">STEP {step}</div>
          ) : (
            <div className="text-xl font-bold text-black">{title}</div>
          )}
        </div>
        {step && <p className="font-bold px-2 text-black">
          {title}
        </p>}
        <div className="text-gray-500 p-2">{text}</div>
      </div>
    </div>
  )
}

export default memo(FeaturesCard)
