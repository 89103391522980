import React from 'react'
import Modal from '../../Modal/Modal'
import DottedLine from '../../DottedLine/DottedLine'
import Button from '../../Button/Button'

const AddSongsViewCreatedEvent = ({ isOpen, onClose, rsvp }) => {
  const title = rsvp ? 'Help your host create the perfect music playlist for the event' : 'Create the perfect music playlist for your event'
  const firstInstructionTitle = rsvp ? 'Select a few songs from the suggested list' : 'Set the vibe by selecting a few songs'
  const firstInstructionSubtitle = rsvp ? <>
    On the next screen, select a few songs. The list of suggestions is curated for this event based on the choices of others attending. Coteri using machine learning to help you build the perfect party playlist.
    <br /><br />
    You can also use the search bar to find a song or artist of your liking.
  </> : 'On the next screen, select a few songs to set the tone of the playlist.'
  const secondInstructionTitle = rsvp ? 'Click “View selections” to see the songs you picked' : 'Guests will be promoted with similar music during the RSVP process'
  const secondInstructionSubtitle = rsvp ? 'Review the selections, then click “Submit” when you’re ready to add the songs to the playlist. You can add more songs at any time through the event page.' : ' Music they select will be added to the music playlist for your event.'

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={'max-w-[450px] w-full mx-5'}
    >
      <div className="z-50">
        <div className="bg-white rounded-input relative w-full flex flex-col">
          <div className="px-[20.5px] flex items-start mt-6">
            <div className="w-[2px] h-4 bg-primary mt-[6.5px]" />

            <div className="flex flex-col ml-2">
              <h2 className="text-primary text-[20px]">
                {title}
              </h2>
              <span className="mt-2">Here's how it works...</span>
            </div>
          </div>

          <div className="absolute inset-0 w-full h-full left-5 flex">
            <DottedLine color={'#80808033'} />
          </div>

          <div className="w-full h-[1px] bg-[#80808033] my-3" />

          <div className="mb-5 px-[20.5px]">
            <div className="flex flex-row items-start mr-1 mt-2">
              <div className="w-[2px] h-4 bg-primary mt-2" />
              <div className="p-2 bg-off-white rounded-md mx-4 leading-4">
                <p className="text-[14px] font-bold text-primary pb-1">
                  {firstInstructionTitle}
                </p>
                <span className="text-[14px]">
                  {firstInstructionSubtitle}
                </span>
              </div>
            </div>

            <div className="flex flex-row items-start mr-1 mt-2">
              <div className="w-[2px] h-4 bg-primary mt-2.5" />
              <div className="p-2 bg-off-white rounded-md mx-4 leading-4">
                <p className="text-[14px] font-bold text-primary pb-1">
                  {secondInstructionTitle}
                </p>
                <span className="text-[14px]">
                  {secondInstructionSubtitle}
                </span>
              </div>
            </div>

            {!rsvp && <div className="flex flex-row items-start mr-1 mt-2">
              <div className="w-[2px] h-4 bg-primary mt-2.5" />
              <div className="p-2 bg-off-white rounded-md mx-4 leading-4">
                <p className="text-[14px] font-bold text-primary pb-1">
                  Access the playlist through the event page
                </p>
                <span className="text-[14px]">
                  Click the "Listen on Spotify" button to start playing music at
                  your event.
                </span>
              </div>
            </div>}

            {!rsvp && <div className="text-start mr-1 mt-2 pl-4 text-[13px]">
              Don't have Spotify? No problem! Spotify offers a free service.
            </div>}
          </div>

          <div className="flex items-center justify-end mr-8 mb-5 z-50">
            <Button
              type={'primary'}
              text={'Got it!'}
              size={'medium'}
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddSongsViewCreatedEvent
