import React, { memo } from 'react'
import Base from './base'
import { Helmet } from 'react-helmet'
import { InstagramEmbed } from 'react-social-media-embed';
import axios from 'axios';

const Anecdotes = () => {

  const getInstaFeed = async () => {
    const token = import.meta.env.VITE_INSTA_TOKEN;
    const fields = "media_url,media_type,permalink*";
    const url = `https://graph.instagram.com/ne/media?access_token=${token}&fields=${fields}`

    const { data } = await axios.get(url);
    console.log('anecdotes: ', data)
  }
  return (
    <Base hideBg={true} bottomBar={false} allowFullHeight={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create online invitations with Coteri</title>
        <meta
          name="description"
          content={
            'Create your online invitation with Coteri. Enter your event details, such as when and where. Decide if you want to collaborate on a music playlist and request list.'
          }
        />
      </Helmet>

      <div className='flex flex-1'>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InstagramEmbed url="https://www.instagram.com/" width={328} />
        </div>
      </div>
    </Base>
  )
}

export default memo(Anecdotes)
