import React from 'react'
import Thanksgiving1 from '../../../assets/images/eventDefault/thanksgiving/thanksgiving-1.png'
import Thanksgiving2 from '../../../assets/images/eventDefault/thanksgiving/thanksgiving-5.png'
import Button from '../../Button/Button'

const Thanksgiving = ({ handleCreateEvent }) => {
  return (
    <div className="flex justify-center relative">
      <div className="w-full flex items-center bg-white p-4 md:flex-row flex-col max-w-[1200px]">
        <div className="flex items-center gap-3 md:mr-10 mr-0 justify-center">
          <img src={Thanksgiving1} className="lg:w-[300px] md:w-[200px] w-[48%]" />
          <img src={Thanksgiving2} className="lg:w-[300px] md:w-[200px] w-[48%]" />
        </div>

        <div className="flex flex-col flex-1 h-full md:mt-0 mt-5">
          <div className="flex flex-1 flex-col justify-start h-full gap-3">
            <div className="flex flex-row">
              <div className="h-4 w-[2px] bg-secondary ml-[1px]" />
              <span className="leading-4 ml-2">
                New Thanksgiving designs available!
              </span>
            </div>
            <h2 className="text-halloween-orange text-[17px]">
              Gather Your Loved Ones for a Memorable Thanksgiving Celebration with Coteri
            </h2>
            <span>
              Celebrate Thanksgiving with Coteri's delightful new designs—make your holiday gatherings unforgettable with seamless invites, festive playlists, and everything you need to create a warm, inviting atmosphere!
            </span>
          </div>

          <Button
            text={'Plan your Thanksgiving'}
            className={'self-end border-black text-black lg:mt-0 mt-5'}
            type={'border'}
            onClick={() => handleCreateEvent('/event/new?view=thanksgiving')}
          />
        </div>
      </div>
    </div>
  )
}

export default Thanksgiving
