import React, { memo } from 'react'
import Modal from '../../Modal/Modal'
import { XIcon } from '../../../assets/icons'
import Button from '../../Button/Button'

const EnableInviteLinkModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-5 bg-white rounded-input mx-3 md:mx-0 sm:w-[500px]">
        <div className='flex flex-col'>
          <div className='flex items-center justify-between mb-5'>
            <h2 className='text-primary'>Please note</h2>
            <div className='flex items-center cursor-pointer' onClick={onClose}>
              <span>Cancel</span>
              <XIcon fill={"black"} width={23} height={23} />
            </div>
          </div>

          <span>Copy and send the invite link via email or text. Anyone with the invite link can respond to your event. You may turn it off at any time.</span>

          <div className='flex flex-row items-center justify-end'>
            <Button type={"border"} text={"Okay, I understand"} className={"mt-5"} size={"medium"} onClick={onConfirm} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(EnableInviteLinkModal)
