export const handleSelectAndSetAddress = (option, manualAddress, fieldCity, fieldState, fieldCountry, fieldZip, fieldWhere, form, setAddress1, setAddress2) => {
  const getAddressComponent = (types) =>
    option.value?.address_components.find((address) =>
      address.types.some((type) => types.includes(type))
    ) || {}

  const streetName = getAddressComponent(['route']) || {}
  const streetNumber = getAddressComponent(['street_number']) || {}
  const city = manualAddress
    ? fieldCity
    : (
        getAddressComponent(['locality', 'administrative_area_level_2']) ||
        {}
      ).long_name || fieldCity
  const state =
    (getAddressComponent(['administrative_area_level_1']) || {})
      .long_name || fieldState
  const zip =
    (getAddressComponent(['postal_code']) || {}).long_name || fieldZip
  const country =
    (getAddressComponent(['country']) || {}).long_name || fieldCountry

  const street =
    streetNumber.long_name && streetName.long_name
      ? `${streetNumber.long_name} ${streetName.long_name}`
      : streetName.long_name || streetNumber.long_name || fieldWhere

  const address2 = [city, state, country].filter(Boolean).join(', ')

  setAddress1(street)
  setAddress2(address2)

  form.setValue('where', street || option.label)
  form.setValue('city', city)
  form.setValue('state', state)
  form.setValue('country', country)
  form.setValue('zip', zip)
}