import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { Star } from '@mui/icons-material'
import { twMerge } from 'tailwind-merge'

const SquaresTable = ({
  selectedSquares,
  onSquareClick,
  user,
  rsvpId,
  disableClick,
  hideNumbers,
  removeTeams,
  teamTopName,
  teamTopClassName,
  teamLeftClassName,
  teamLeftName,
  topRowIndexes,
  leftRowIndexes
}) => {
  const toast = useToast()
  const [squareSize, setSquareSize] = useState(40)
  const containerRef = useRef(null)
  const currentUser = rsvpId || user?.uid

  const topRowKeys = topRowIndexes || Array.from({ length: 10 })
  const leftRowKeys = leftRowIndexes ?? Array.from({ length: 10 })

  const handleSquareClick = (row, col) => {
    if (disableClick) {
      return
    }

    const alreadySelectedSquare = selectedSquares.find(
      (square) => square.row === row && square.col === col
    )

    if (alreadySelectedSquare && alreadySelectedSquare?.uid !== currentUser) {
      toast({
        status: 'error',
        title: 'This square is already selected',
        position: 'top'
      })

      return
    }

    onSquareClick(row, col)
  }

  function getSquareSize(screenWidth) {
    const m = 0.05393
    const b = 13.8

    if (screenWidth > 1200) {
      return 76
    }

    return m * screenWidth + b
  }

  useEffect(() => {
    const updateSquareSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth

        const newSize = containerWidth / 10 - 5
        setSquareSize(newSize)
      }
    }

    updateSquareSize()

    window.addEventListener('resize', updateSquareSize)
    return () => {
      window.removeEventListener('resize', updateSquareSize)
    }
  }, [])

  return (
    <div className="w-full flex items-center justify-center px-1">
      {/* This div wraps the squares */}
      <div className="flex flex-col w-full">
        {/* This div add the top line */}
        {!removeTeams && (
          <div
            className={`flex flex-row w-full items-center pl-7 ${!hideNumbers && `mb-3`}`}
          >
            <div
              className={twMerge(
                `flex flex-1 h-2 bg-green-200 rounded-sm`,
                teamTopClassName
              )}
            />
            <div className="flex flex-1 items-center justify-center">
              <span className="bg-white sm:text-[16px] text-[14px]">
                {teamTopName}
              </span>
            </div>
            <div
              className={twMerge(
                `flex flex-1 h-2 bg-green-200 rounded-sm`,
                teamTopClassName
              )}
            />
          </div>
        )}

        <div className="flex overflow-visible w-full">
          {/* This div add the left line */}
          {!removeTeams && (
            <div
              className={`flex flex-col h-100 w-1 mt-4 ${!hideNumbers && 'mr-3'}`}
            >
              <div
                className={twMerge(
                  `flex flex-1 w-2 rounded-sm bg-green-200`,
                  teamLeftClassName
                )}
              />
              <div className="flex flex-1 items-center justify-center">
                <span className="flex flex-1 -rotate-90 bg-white sm:text-[16px] text-[14px]">
                  {teamLeftName}
                </span>
              </div>
              <div
                className={twMerge(
                  `flex flex-1 w-2 rounded-sm bg-green-200`,
                  teamLeftClassName
                )}
              />
            </div>
          )}

          <div
            ref={containerRef}
            className="flex items-center justify-center w-full"
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `auto repeat(10, ${squareSize}px)`,
                gap: '2px'
              }}
            >
              {/* Empty top-left cell */}
              <div></div>

              {/* Top row with numbers 0 to 9 */}
              {topRowKeys.map((v, index) => (
                <div
                  key={`top-${index}`}
                  className="flex justify-center items-center sm:text-[16px] text-[14px] mb-2"
                >
                  {hideNumbers ? '' : v}
                </div>
              ))}

              {/* Left column with numbers 0 to 9 and the grid of squares */}
              {leftRowKeys?.map((v, rowIndex) => (
                <React.Fragment key={`row-${rowIndex}`}>
                  {/* Left column numbers */}
                  <div className="flex justify-center items-center mr-2 sm:text-[16px] text-[14px]">
                    {hideNumbers ? '' : v}
                  </div>

                  {/* Row of squares */}
                  {Array.from({ length: 10 }).map((_, colIndex) => {
                    const square = selectedSquares.find(
                      (square) =>
                        square.row === rowIndex && square.col === colIndex
                    )
                    const isMySquare = square?.uid === currentUser

                    return (
                      <div
                        key={`square-${rowIndex}-${colIndex}`}
                        className={`rounded-sm border-[1px] border-black relative ${disableClick ? 'cursor-default' : 'cursor-pointer'} flex items-center justify-center ${square?.winner ? ' bg-blue' : square?.isContender ? 'border-[2px] border-light-blue-2 bg-light-blue-2' : isMySquare ? 'border-[3px] border-blue' : ''}`}
                        onClick={() => handleSquareClick(rowIndex, colIndex)}
                        style={{
                          width: squareSize,
                          height: squareSize
                        }}
                      >
                        {square?.winner && (
                          <div className="w-8 h-8 bg-light-blue-2 absolute rounded-full z-50 top-0 right-0 flex items-center justify-center transform translate-x-1/2 -translate-y-1/2">
                            <span className="text-[12px]">
                              {square?.winner}
                            </span>
                          </div>
                        )}
                        {square?.winner ? (
                          <Star style={{ color: 'white' }} fontSize="large" />
                        ) : (
                          <span className="text-[8px] sm-custom:text-[11px]">
                            {square ? square.initials : ''}
                          </span>
                        )}
                      </div>
                    )
                  })}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SquaresTable
