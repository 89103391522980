import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { ArrowDownIcon } from '../../../assets/icons'
import { twMerge } from 'tailwind-merge'
import { useWatch, Controller } from 'react-hook-form'

const Select = ({
  placeholder,
  className,
  options,
  register,
  form,
  hasError,
  ...props
}) => {
  const spanRef = useRef(null)
  const divRef = useRef(null)
  const [opened, setOpened] = useState(false)

  const formValue = useWatch({
    control: form.control,
    name: register.name
  })

  const handlePlaceholderAnimation = useCallback(() => {
    if (!spanRef.current) return

    if (formValue?.value) {
      spanRef.current.style.top = '-1px'
      spanRef.current.style.fontSize = '12px'
      spanRef.current.style.background = props.placeholderBg || 'white'
      spanRef.current.style.padding = '0px 4px'
    }
  }, [formValue?.value, props.placeholderBg])

  const onFocus = (e) => {
    setOpened(true)
  }

  const onUnFocus = (e) => {
    setOpened(false)
  }

  const handleOutsideClick = useCallback((event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setOpened(false)
    }
  }, [])

  const handleSelectOption = useCallback(
    (option) => {
      form?.setValue(register.name, option)
      setOpened(false)
    },
    [form, register.name]
  )

  useEffect(() => {
    if (formValue?.value) {
      handlePlaceholderAnimation()
    }
  }, [formValue, handlePlaceholderAnimation])

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('click', handleOutsideClick)

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div
      ref={divRef}
      className={twMerge(
        `relative px-2 w-full min-w-24 h-h-input flex bg-primary ${hasError ? 'border-error border-1' : opened ? 'border-primary' : 'border-input-border'} border-1 rounded-input bg-transparent items-center cursor-pointer ${opened && 'border-[2px]'}`,
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        setOpened(!opened);
      }}
    >
      <Controller
        name={register.name}
        control={form.control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <input
              name={name}
              className={`w-full mr-3 outline-none cursor-pointer bg-transparent pointer-events-none ${props?.centerText && 'text-center ml-[24px]'}`}
              readOnly
              value={value?.label}
              onChange={(e) => onChange(e)}
              onFocus={onFocus}
            />
          )
        }}
      />
      <span
        ref={spanRef}
        className={`absolute top-1/2 -translate-y-1/2 ${opened ? 'text-primary font-bold' : 'text-placeholder font-normal'} pointer-events-none left-2.5 ease-linear duration-150`}
      >
        {placeholder}
      </span>

      <ArrowDownIcon opened={opened} onClick={opened ? onUnFocus : onFocus} fill={props?.arrowDownFill} />

      {opened && (
        <div className={twMerge("border-1 absolute top-0 flex flex-col items-start rounded-input right-0 left-0 mt-56px max-h-56 overflow-y-auto bg-background z-10 scrollbar-custom", props?.optionsClassName)} >
          {options.map((option, index) => {
            return (
              <div
                key={index}
                className="hover:bg-primary hover:text-white w-full p-2 rounded-input cursor-pointer scrollbar-custom"
                onClick={() => handleSelectOption(option)}
              >
                <span>{option.label}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
export default memo(Select)
