import React, { useEffect, useState } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import Button from '../components/Button/Button'
import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Text,
  Container,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { auth, signOut } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import LoginBox from '../components/LoginBox'
import RegisterBox from '../components/RegisterBox'
import ResetBox from '../components/ResetBox'
import { LogoIcon } from '../assets/icons'
import { removeSettingsData } from '../services/database'

const Base = ({
  children,
  // showPopUp,
  // onSuccess,
  // onChangePopUp,
  // onCloseLogin,
  bottomBar,
  largeBottomBar,
  disableButtons,
  hideMenu,
  hideBg,
  blackLogo,
  whiteLogo,
  headerBackgroundColor,
  menuColor,
  allowFullHeight,
  removeVerticalPadding,
  enableFooter,
  footerCancelOnClick,
  footerCancelText,
  footerContinueOnClick,
  footerContinueText,
  footerCancelProps,
  footerContinueProps
}) => {
  const [showLoginModal, setShowLoginModal] = useState(null)
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  const handleClose = () => {
    // if (!!onChangePopUp) {
    //   onChangePopUp(null)
    // } else {
    setShowLoginModal(null)
    // }
  }

  const handleSignIn = () => {
    // if (!!onChangePopUp) {
    //   onChangePopUp('login')
    // } else {
    setShowLoginModal('login')
    // }
  }

  const handleSignUp = () => {
    // if (!!onChangePopUp) {
    //   onChangePopUp('login')
    // } else {
    setShowLoginModal('register')
    // }
  }

  const handleChangePopUp = (v) => {
    // if (!!onChangePopUp) {
    //   onChangePopUp(v)
    // } else {
    setShowLoginModal(v)
    // }
  }

  const handleOnLoginSuccess = (v) => {
    // if (!!onSuccess) {
    //   onSuccess(v)
    // } else {
    setShowLoginModal(null)
    // }
  }

  const handleSignOut = async () => {
    // removeSettingsData()
    await signOut(auth)
  }

  useEffect(() => {
    if (!loading && !!user) {
      // setUserId(analytics, user.uid)

      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('uid', user.uid)
      amplitude.identify(identifyEvent)
      amplitude.setUserId(user.uid)
    }
    // if (
    //   !isPublic &&
    //   !loading &&
    //   !!user &&
    //   !user.isAnonymous &&
    //   !user.emailVerified
    // ) {
    //   setVerifyUserEmail(true)
    // } else {
    //   setVerifyUserEmail(false)
    // }
  }, [user, loading])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <>
      {process.env.REACT_APP_ENV === 'DEV' && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          h="5px"
          bg="#422d8f"
          zIndex="999999999"
        />
      )}
      <VStack>
        <Container
          w="100%"
          // px={{ base: '16px', md: '36px' }}
          px={0}
          boxShadow={{ base: 'none', md: '2px 4px 12px rgb(0 0 0 / 8%)' }}
          maxW="800px"
          minH="100vh"
          position="absolute"
          backgroundColor={'white'}
        >
          <Flex minH="100vh" direction="column">
            <Flex flex={1} direction="column">
              <Flex
                ml="0"
                mb="20px"
                background={headerBackgroundColor || '#120a31'}
              >
                <Link to={disableButtons ? undefined : '/'} className="ml-8">
                  <LogoIcon
                    className="w-[120px] h-[90px]"
                    fill={whiteLogo ? '#fff' : blackLogo ? '#000' : '#DD94F7'}
                  />
                </Link>
              </Flex>
              <Flex
                flex={1}
                direction="column"
                px={removeVerticalPadding ? 0 : { base: '16px', md: '36px' }}
              >
                {children}
              </Flex>

              {!allowFullHeight && <Flex flex="1" />}
            </Flex>
            {bottomBar ? (
              <Flex
                pt={'20px'}
                mt={'10px'}
                alignItems={'center'}
                justifyItems={'center'}
                gap="6px"
                flexDirection={{ base: 'column', sm: 'row' }}
                pb={largeBottomBar ? '140px' : '20px'}
                px={'20px'}
                background={'#120a31'}
              >
                <Flex flex="1">
                  <Text fontSize="15px" color="white">
                    © 2024 Coteri Services LLC
                  </Text>
                </Flex>
                <div className="flex items-center justify-center">
                  <Button
                    type={'text'}
                    size={'no-size'}
                    onClick={() => navigate('/terms')}
                    text={'Terms & Conditions'}
                    className={
                      'text-white mr-5 text-[14px] hover:text-[#ffffff80]'
                    }
                  />
                  <Button
                    type={'text'}
                    size={'no-size'}
                    onClick={() => navigate('/privacy')}
                    text={'Privacy Policy'}
                    className={'text-white text-[14px] hover:text-[#ffffff80]'}
                  />
                </div>
              </Flex>
            ) : (
              <div className="h-4" />
            )}
            {!hideMenu && (
              <>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    position="absolute"
                    top="30px"
                    right={{ base: '16px', md: '36px' }}
                    icon={<HamburgerIcon color={menuColor || 'white'} />}
                    borderColor={menuColor || 'white'}
                    variant="outline"
                    disabled={!!disableButtons}
                  />
                  <MenuList zIndex="99">
                    {!!user && !user.isAnonymous && (
                      <Link to="/event-history">
                        <MenuItem>My events</MenuItem>
                      </Link>
                    )}
                    {/* <Link to="/blog">
                      <MenuItem>Coteri Blog</MenuItem>
                    </Link> */}
                    <Link to="/faq">
                      <MenuItem>FAQ</MenuItem>
                    </Link>
                    <Link to="/superbowl/blog">
                      <MenuItem>Super Bowl</MenuItem>
                    </Link>
                    {(!user || user.isAnonymous) && (
                      <>
                        <MenuItem onClick={handleSignIn}>Sign in</MenuItem>
                        <MenuItem onClick={handleSignUp}>Sign up</MenuItem>
                      </>
                    )}
                    {!!user && !user.isAnonymous && (
                      <Link to="/settings">
                        <MenuItem>My profile</MenuItem>
                      </Link>
                    )}
                    {!!user && !user.isAnonymous && (
                      <MenuItem onClick={handleSignOut}>Log out</MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </>
            )}
            <Modal isOpen={!!showLoginModal} onClose={handleClose} isCentered>
              <ModalOverlay />
              <ModalContent py="12px" mx="20px">
                <ModalCloseButton mt="4px" mr="0" />
                <ModalBody>
                  {showLoginModal === 'login' && (
                    <LoginBox
                      onSuccess={handleOnLoginSuccess}
                      onChangePopUp={handleChangePopUp}
                    />
                  )}
                  {showLoginModal === 'register' && (
                    <RegisterBox
                      onSuccess={handleOnLoginSuccess}
                      onChangePopUp={handleChangePopUp}
                    />
                  )}
                  {showLoginModal === 'reset' && (
                    <ResetBox
                      onSuccess={handleOnLoginSuccess}
                      onChangePopUp={handleChangePopUp}
                    />
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>
            {enableFooter && (
              <div className="aboslute bottom-0 left-0 right-0 bg-white h-20 z-50 p-5 flex items-center justify-between relative px-10">
                <Button
                  type={'text'}
                  text={footerCancelText || 'Cancel'}
                  onClick={footerCancelOnClick}
                  className={'text-black'}
                  {...footerCancelProps}
                />
                {!!footerContinueOnClick ? (
                  <Button
                    text={footerContinueText || 'Continue'}
                    onClick={footerContinueOnClick}
                    {...footerContinueProps}
                  />
                ) : null}
              </div>
            )}
          </Flex>
        </Container>
      </VStack>
    </>
  )
}

export default Base
