import {
  Modal,
  ModalContent,
  ModalOverlay,
  Toast,
  useToast
} from '@chakra-ui/react'
import Button from '../../Button/Button'
import React from 'react'
import { XIcon } from '../../../assets/icons'
import { ref, remove, update } from 'firebase/database'
import { auth, database } from '../../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const CancelEventModal = ({ eventInfo, opened, onClose, fetchCircles }) => {
  const [loading, setLoading] = React.useState(false)
  const toast = useToast()
  const [user] = useAuthState(auth)

  const handleDelete = async () => {
    try {
      setLoading(true)

      if (!eventInfo?.key) {
        toast({
          title: 'Event not found',
          position: 'top',
          status: 'error',
          isClosable: true
        })
        return
      }

      await Promise.all([
        remove(ref(database, `circles/${eventInfo.key}`)),
        remove(ref(database, `circlesRef/${user?.uid}/${eventInfo.key}`))
      ])

      await fetchCircles()

      onClose()

      toast({
        title: 'Event canceled',
        position: 'top',
        status: 'success',
        isClosable: true
      })
    } catch (err) {
      console.log('CancelEventModal: ', err)
      toast({
        title: 'Error canceling event',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={opened} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <div className="p-3">
          <div className="flex items-center cursor-pointer justify-end">
            <XIcon fill={'black'} width={20} height={20} onClick={onClose} />
          </div>

          <span className="mt-3">
            Are you sure you want to cancel this event?
          </span>

          <p className="font-semibold mt-3 text-[18px] text-center">
            {eventInfo?.name}
          </p>

          <div className="flex flex-row items-center justify-between mt-3">
            <Button
              type={'text'}
              size="small"
              text={'Back'}
              disabled={loading}
              onClick={onClose}
            />
            <Button
              size="small"
              text={'Yes'}
              onClick={handleDelete}
              disabled={loading}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default CancelEventModal
