import React, { memo, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

const Modal = ({ isOpen, onClose, children, className }) => {
  const modalRef = useRef(null)

  const handleClose = (event) => {
    if (!modalRef.current?.contains(event.target)) {
      onClose()
    }
  }

  const stopPropagation = (event) => {
    event.stopPropagation();
  }

  if (!isOpen) return null

  return (
    <div
      className="fixed inset-0 flex items-center justify-center cursor-default"
      style={{ zIndex: 9999 }}
      onClick={handleClose}
    >
      <div className="fixed inset-0 bg-black opacity-50 pointer-events-none" />
      <div ref={modalRef} className={twMerge(className)} style={{ zIndex: 99999 }} onClick={stopPropagation}>
        {children}
      </div>
    </div>
  )
}

export default Modal
