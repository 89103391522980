import { Spinner } from '@chakra-ui/react'
import React, { memo, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

const Button = ({
  text,
  onClick,
  type,
  fullWidth,
  size,
  className,
  disabled,
  iconLeft,
  iconRight,
  loading,
  spinnerProps
}) => {
  let baseClasses = ' rounded-button'

  const memoedDisable = useMemo(() => loading || disabled, [disabled, loading])

  switch (type) {
    case 'text':
      if (memoedDisable) {
        baseClasses += ' bg-transparent text-gray'
      } else {
        baseClasses += ' bg-transparent text-secondary hover:text-secondary-opacity-50 transition duration-300 ease-in-out'
      }
      break
    case 'terciary':
      baseClasses +=
        ' bg-transparent border-1 text-secondary'
      if (memoedDisable) {
        baseClasses += ' border-gray text-gray'
      } else {
        baseClasses += ' border-secondary hover:text-secondary-opacity-50 hover:border-secondary-opacity-50 transition duration-300 ease-in-out'
      }
      break
    case 'secondary':
      baseClasses += ' bg-secondary text-white hover:bg-secondary transition duration-300 ease-in-out'
      break
    case 'off-white':
      baseClasses +=
        ' bg-off-white border-1 text-primary'
      if (disabled) {
        baseClasses += ' border-gray text-gray'
      } else {
        baseClasses += ' border-primary hover:border-terciary hover:bg-terciary hover:text-white transition duration-300 ease-in-out'
      }
      break
    case 'border':
      baseClasses += ' border-1 border-primary text-primary hover:bg-terciary hover:border-terciary hover:text-white transition duration-300 ease-in-out'
      break
    default:
      if (memoedDisable) {
        baseClasses += ' bg-gray border-gray hover:bg-gray'
      } else {
        baseClasses += ' bg-primary hover:bg-terciary transition duration-300 ease-in-out'
      }
      baseClasses += ' text-white'
      break
  }

  switch (size) {
    case 'medium':
      baseClasses += ' px-h-button-medium py-v-button-medium'
      break
    case 'small':
      baseClasses += ' px-h-button-small py-v-button-small'
      break
    case 'extra-small':
      baseClasses += ' px-h-button-extra-small py-v-button-extra-small'
      break
    case 'no-size':
      baseClasses += ' px-0 py-0'
      break
    default:
      baseClasses += ' px-h-button py-v-button'
      break
  }

  if (fullWidth) {
    baseClasses += ' w-full'
  }

  return (
    <button className={twMerge(baseClasses, className)} onClick={onClick} disabled={loading || memoedDisable}>
      {loading ?
        <Spinner color="white" size={'sm'} {...spinnerProps} />
        :
        <>
          {iconLeft}
          {text}
          {iconRight}
        </>
      }
    </button>
  )
}

export default memo(Button)