import React, { useEffect, useRef, useState } from 'react'
import { useToast } from '@chakra-ui/react'

const SquaresTableSelect = ({
  sessionTableData,
  selectedSquares,
  onSquareClick,
  rsvpId,
  user,
  disableClick
}) => {
  const toast = useToast()
  const isContender = false
  const containerRef = useRef(null)
  const [squareSize, setSquareSize] = useState(0)
  const currentUser = rsvpId || user?.uid

  const handleSquareClick = (row, col) => {
    if (disableClick) {
      return
    }

    const alreadySelectedSquare = sessionTableData?.selectedSquares.find(
      (square) => square.row === row && square.col === col
    )

    if (alreadySelectedSquare && alreadySelectedSquare?.uid !== currentUser) {
      toast({
        status: 'error',
        title: 'This square is already selected',
        position: 'top'
      })
      return
    }

    onSquareClick(row, col)
  }

  useEffect(() => {
    const updateSquareSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth

        const newSize = containerWidth / 10 - 5
        setSquareSize(newSize)
      }
    }

    updateSquareSize()

    window.addEventListener('resize', updateSquareSize)
    return () => {
      window.removeEventListener('resize', updateSquareSize)
    }
  }, [])

  return (
    <div
      ref={containerRef}
      className="w-full flex items-center justify-center relative overflow-hidden"
    >
      <div
        className="grid gap-1"
        style={{
          gridTemplateColumns: 'repeat(10, 1fr)',
          gridTemplateRows: 'repeat(10, 1fr)'
        }}
      >
        {/* Generate the 10x10 grid */}
        {Array.from({ length: 10 }).map((_, rowIndex) =>
          Array.from({ length: 10 }).map((_, colIndex) => {
            const square = sessionTableData?.selectedSquares.find(
              (square) => square.row === rowIndex && square.col === colIndex
            )
            const isMySquare = square?.uid === currentUser

            return (
              <div
                key={`square-${rowIndex}-${colIndex}`}
                className={`rounded-sm border-[1px] border-black ${disableClick ? 'cursor-default' : 'cursor-pointer'} flex items-center justify-center 
                ${square ? (isMySquare ? 'border-[3px] border-primary' : isContender && 'border-[2px] border-light-purple bg-light-purple-2') : ''}`}
                onClick={() => handleSquareClick(rowIndex, colIndex)}
                style={{
                  width: squareSize,
                  height: squareSize
                }}
              >
                <span className="text-[9px] sm-custom:text-[13px]">
                  {square ? square.initials : ''}
                </span>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default SquaresTableSelect
