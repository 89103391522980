import React, { memo } from 'react'
import FeaturesCard from '../FeaturesCard/FeaturesCard'

const HowItWorks = () => {
  return (
    <div className="relative flex flex-col py-[50px] justify-between mx-0 lg:mx-0 w-full">
      <div className="flex items-center justify-center w-full">
        <div className="flex flex-col w-full">
          <span className="text-xl font-bold text-secondary mb-5 stripe-column:mb-0 ml-[9px]">
            How it works
          </span>

          <div className="flex stripe-column-2rows:flex-row flex-col items-start justify-between relative">
            <div
              className={
                'flex stripe-column:flex-row flex-col items-start justify-between w-full'
              }
            >
              <FeaturesCard
                title={'Connect your spotify account (optional)'}
                text={"Connect your spotify account to view your music data. Alternatively, choose “Guest checkout” to skip connecting your account. Coteri will create a public playlist on Spotify that is accessible with a free Spotify account."}
                step={1}
                className={'mb-5 stripe-column:mb-0'}
              />
              <FeaturesCard
                title={'Set the vibe'}
                text={
                  'Select a few songs to add to the music playlist for your event. Coteri leverages Machine Learning to generate suggestions based on the songs you pick.'
                }
                step={2}
                className={'mb-5 stripe-column:mb-0'}
              />
            </div>
            <div
              className={
                'flex stripe-column:flex-row flex-col items-start justify-between w-full'
              }
            >
              <FeaturesCard
                title={'Send the invites'}
                text={
                  'During the RSVP process, your guests will be prompted with songs to add to the playlist.'
                }
                step={3}
                className={'mb-5 stripe-column:mb-0'}
              />
              <FeaturesCard
                title={'Listen to the playlist'}
                text={
                  'A link to the playlist is accessible through the event page. Click “Listen to playlist” to start listening.'
                }
                step={4}
                className={'mb-5 stripe-column:mb-0'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(HowItWorks)
