import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  auth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword
} from '../firebase'
import { Flex, Text, Button, Input, useToast, Spinner } from '@chakra-ui/react'
import { updateUser } from '../services/User'
import { ReactComponent as GoogleSVG } from '../assets/icons/google.svg'
import querystring from 'query-string'
import { setCanvaUser } from '../services/helpers'
import * as amplitude from '@amplitude/analytics-browser'

const LoginBox = ({ onSuccess, onChangePopUp }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()
  const params =
    typeof window != null ? querystring.parse(window.location.search) : {}

  const handleSignInWithEmail = async () => {
    setLoading(true)
    try {
      const user = await signInWithEmailAndPassword(auth, email, password)

      amplitude.track('User log-in', {
        type: 'email',
        source: !!params.canva_user_token && !!params.state ? 'canva' : 'app'
      })

      if (!!params.canva_user_token && !!params.state) {
        await setCanvaUser(
          user.user.uid,
          params.canva_user_token,
          params.state,
          params.nonce,
          toast
        )
        return
      }
      if (!!onSuccess) {
        onSuccess()
        setLoading(false)
      } else if (!!params.redirect_url) {
        navigate(params.redirect_url)
      }
    } catch (e) {
      setLoading(false)
      toast({
        zIndex: 999999,
        title: e.message || 'Error while signing in',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    }
  }

  const handleSignInWithGoogle = async () => {
    try {
      const googleProvider = new GoogleAuthProvider()
      const res = await signInWithPopup(auth, googleProvider)

      amplitude.track('User log-in', {
        type: 'google',
        source: !!params.canva_user_token && !!params.state ? 'canva' : 'app'
      })

      await updateUser({
        name: res.user?.displayName,
        email: auth.currentUser.email
      })
      if (!!params.canva_user_token && !!params.state) {
        await setCanvaUser(
          res.user.uid,
          params.canva_user_token,
          params.state,
          params.nonce,
          toast
        )
        return
      }
      if (!!onSuccess) {
        onSuccess()
      } else if (!!params.redirect_url) {
        navigate(params.redirect_url)
      }
    } catch (err) {
      console.error(err)
      toast({
        zIndex: 999999,
        title: err.message || 'Error while signing with google',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    }
  }

  return (
    <Flex w="100%" direction="column" px="10px">
      <Text mb="28px" color="black" fontSize="20px" fontWeight="600">
        Sign In
      </Text>
      <Button
        h="50px"
        mb="20px"
        bg="white"
        color="black"
        borderRadius="10px"
        borderWidth="3px"
        borderColor="black"
        onClick={handleSignInWithGoogle}
        leftIcon={<GoogleSVG width={22} style={{ marginRight: 4 }} />}
      >
        Continue with Google
      </Button>
      <Text textAlign="center" color="gray.700" mb="20px">
        or
      </Text>
      <Input
        type="text"
        h="50px"
        mb="16px"
        borderRadius="10px"
        borderWidth="3px"
        borderColor="black"
        variant="outline"
        _hover={{ borderColor: 'gray.600' }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <Input
        type="password"
        h="50px"
        mb="16px"
        borderRadius="10px"
        borderWidth="3px"
        borderColor="black"
        variant="outline"
        _hover={{ borderColor: 'gray.600' }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <Button
        h="50px"
        mb="20px"
        bg="black"
        color="white"
        colorScheme="pink"
        borderRadius="10px"
        borderColor="black"
        onClick={handleSignInWithEmail}
        isDisabled={loading}
        isLoading={loading}
      >
        {loading ? <Spinner /> : 'Sign In'}
      </Button>
      <Flex justify="center" mb="10px">
        {onChangePopUp ? (
          <Text
            color="primary"
            cursor="pointer"
            onClick={() => onChangePopUp('reset')}
          >
            Forgot Password
          </Text>
        ) : (
          <Link
            to={`/reset?${new URLSearchParams(params).toString()}`}
            color="primary"
          >
            <Text color="primary">Forgot Password</Text>
          </Link>
        )}
      </Flex>
      <Flex justify="center" mb="10px">
        <Text color="gray.600">Don't have an account?</Text>
        {onChangePopUp ? (
          <Text
            color="primary"
            cursor="pointer"
            ml="4px"
            onClick={() => onChangePopUp('register')}
          >
            Sign Up
          </Text>
        ) : (
          <Link
            to={`/register?${new URLSearchParams(params).toString()}`}
            color="primary"
          >
            <Text color="primary" ml="4px">
              Sign Up
            </Text>
          </Link>
        )}
      </Flex>
    </Flex>
  )
}

export default LoginBox
