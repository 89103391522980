import {
  getSpotifyLikedSongs,
  getSpotifyRecentlyPlayed,
  getTopItems
} from '../Spotify'
import {
  getAppleMusicRecentTracks,
  getAppleMusicHeavyRotation,
  getAppleMusicLibrary
} from '../AppleMusic/index'
import { database, get } from '../../firebase'
import { ref } from 'firebase/database'

const fetchRecentTracks = async (
  provider,
  { spotifyToken, appleMusicToken },
  offset = 0,
  limit = 30
) => {
  if (provider === 'apple_music') {
    return await getAppleMusicRecentTracks(
      appleMusicToken,
      offset,
      limit,
      spotifyToken
    )
  } else {
    return await getSpotifyRecentlyPlayed(spotifyToken, offset, limit)
  }
}

const fetchTopTracks = async (
  provider,
  { spotifyToken, appleMusicToken },
  offset = 0,
  limit = 30
) => {
  if (provider === 'apple_music') {
    return await getAppleMusicHeavyRotation(
      appleMusicToken,
      offset,
      limit,
      spotifyToken
    )
  } else {
    return await getTopItems(spotifyToken, 'long_term', offset, limit)
  }
}

const fetchLibrary = async (
  provider,
  { spotifyToken, appleMusicToken },
  offset = 0,
  limit = 30
) => {
  if (provider === 'apple_music') {
    return await getAppleMusicLibrary(
      appleMusicToken,
      offset,
      limit,
      spotifyToken
    )
  } else {
    return await getSpotifyLikedSongs(spotifyToken, offset, limit)
  }
}

const fetchCircleTracks = async (code) => {
  const circlePlaylistSnap = await get(
    ref(database, `circles/${code}/playlist`)
  )
  return circlePlaylistSnap.val() || []
}

export { fetchRecentTracks, fetchTopTracks, fetchCircleTracks, fetchLibrary }
