import React, { Dispatch, SetStateAction, memo, useCallback } from 'react'
import {
  AppleIcon,
  AppleMusicIcon,
  FacebookIcon,
  GoogleIcon
} from '../../../assets/icons'
import Spotify from '../../../assets/icons/spotify'
import Toggle from '../../../components/Toggle/Toggle'

const SocialMediaCard = ({ value, setValue, type, additionalText }) => {
  const handleTypes = useCallback(() => {
    switch (type) {
      case 'apple':
        return {
          icon: <AppleIcon fill={'#000'} width={40} height={40} />,
          text: 'Apple'
        }
      case 'google':
        return {
          icon: <GoogleIcon width={40} height={40} />,
          text: 'Google'
        }
      case 'facebook':
        return {
          icon: <FacebookIcon fill={'#316ce9'} width={40} height={40} />,
          text: 'Facebook'
        }
      case 'spotify':
        return {
          icon: <Spotify fill={'#1dd15e'} width={40} height={40} />,
          text: 'Spotify'
        }
      case 'appleMusic':
        return {
          icon: (
            <AppleMusicIcon
              fill={'#fb425d'}
              width={35}
              height={35}
              className="ml-[2px]"
            />
          ),
          text: 'Apple music'
        }
      default:
        return { icon: <></>, text: '' }
    }
  }, [type])

  return (
    <div className="w-full flex items-center justify-between">
      <div className="flex items-center">
        {handleTypes().icon}
        <span className="ml-[10px] text-[18px]"> {handleTypes().text}</span>
      </div>

      <div className="flex items-center">
        {additionalText && (
          <span className="mr-[10px] text-[12px] text-placeholder">
            {additionalText}
          </span>
        )}
        <Toggle value={value} setValue={setValue} />
      </div>
    </div>
  )
}

export default memo(SocialMediaCard)
