/* eslint-disable no-unreachable */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Base from '../base'
import ClipLoader from 'react-spinners/ClipLoader'
import { Helmet } from 'react-helmet'
import { useToast } from '@chakra-ui/react'
import Button from '../../components/Button/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { createEvent } from '../../services/Circle'
import { useNavigate, useParams } from 'react-router-dom'
import { categories, DEFAULT_COVER } from '../../utils/categories'
import { ref, database, auth, storage } from '../../firebase'
import moment from 'moment'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'
import { handleSelectAndSetAddress } from '../../utils/address'
import NewEventTitle from '../../components/NewEvent/NewEventTitle/NewEventTitle'
import NewEventHostName from '../../components/NewEvent/NewEventHostName/NewEventHostName'
import NewEventImage from '../../components/NewEvent/NewEventImage/NewEventImage'
import NewEventDateAndTime from '../../components/NewEvent/NewEventDateAndTime/NewEventDateAndTime'
import NewEventLocation from '../../components/NewEvent/NewEventLocation/NewEventLocation'
import NewEventOcassion from '../../components/NewEvent/NewEventOcassion/NewEventOcassion'
import {
  getDownloadURL,
  uploadBytes,
  ref as storageRef
} from 'firebase/storage'
import NewEventCost from '../../components/NewEvent/NewEventCost/NewEventCost'
import { getNextHourAndRoundTo30Minutes } from '../../services/helpers'
import { useObject } from 'react-firebase-hooks/database'
import Note from '../../assets/icons/note'

export const schema = yup
  .object({
    eventTitle: yup.string().required('Event title is required'),
    ocassion: yup.string().nullable(),
    ocassionOther: yup.string().when('ocassion', (value, schema) => {
      const ocassion = value[0]

      if (ocassion === 'other') {
        return schema.required()
      }

      return schema
    }),
    date: yup.string().required('Date is required'),
    time: yup.string().required('Time is required'),
    timezone: yup.string().required('Timezone is required'),
    where: yup.string(),
    complementaryAddress: yup.string(),
    country: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup.string(),
    hostName: yup.string(),
    note: yup.string(),
    items: yup.array().of(yup.object()),
    url: yup.string(),
    eventImage: yup.array().optional(),
    costPerSquare: yup.string().optional(),
    venmo: yup.string().optional(),
    cashapp: yup.string().optional(),
    paypal: yup.string().optional(),
    zelle: yup.string().optional(),
    allowCash: yup.boolean().optional()
  })
  .required()

const NewEvent = ({ type }) => {
  const { circleCode, ocassion: incomingOcassion } = useParams()
  const toast = useToast()
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')

  const [noteFocused, setNoteFocused] = useState(false)
  const noteInputRef = useRef()

  const searchParams = new URLSearchParams(location.search)
  const view = searchParams.get('view')

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const eventInfo = useMemo(() => eventSnap?.val() ?? {}, [eventSnap])

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  const [
    fieldWhere,
    fieldCity,
    fieldCountry,
    fieldState,
    fieldZip,
    costPerSquare,
    fieldNote
  ] = form.watch([
    'where',
    'city',
    'country',
    'state',
    'zip',
    'costPerSquare',
    'note'
  ])

  const handleFocusNote = () => {
    setNoteFocused(true)
    setTimeout(() => {
      noteInputRef.current?.focus()
    }, 500)
  }

  const handleSubmit = async (formValues) => {
    try {
      let oldCode = null
      let newImageUrl = null

      if (type === 'edit') {
        oldCode = circleCode
      }

      if (formValues.eventImage && formValues.eventImage.length > 0) {
        const uploadPromises = formValues.eventImage.map(async (file) => {
          const storagePath = `events/${oldCode}/image`
          const fileRef = storageRef(storage, storagePath)
          const result = await uploadBytes(fileRef, file)
            .then(() => ({ success: true, path: storagePath }))
            .catch((error) => ({ success: false, path: storagePath, error }))
          if (!result.success) return null
          const url = await getDownloadURL(fileRef)
          return url
        })

        const results = await Promise.all(uploadPromises)
        const successUploads = results.filter((result) => !!result)
        const failedUploads = results.filter((result) => !result)

        if (successUploads.length > 0) {
          newImageUrl = successUploads[0]
        }

        if (successUploads.length > 0) {
          toast({
            title: 'Upload successful',
            description: `${successUploads.length} files uploaded successfully.`,
            status: 'success',
            duration: 5000,
            isClosable: true
          })
        }

        if (failedUploads.length > 0) {
          toast({
            title: 'Some uploads failed',
            description: `${failedUploads.length} files failed to upload.`,
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        }
      }

      const evData = {
        type: type === 'edit' ? 'edit' : 'new',
        oldCode,
        name: formValues.eventTitle,
        hostName: formValues.hostName,
        size: '10',
        date: moment(formValues.date).format('YYYY-MM-DD'),
        time: moment(formValues.time).format('HH:mm'),
        timezone: formValues.timezone || '',
        noteForGuests: formValues.note || '',
        ocassion: formValues.ocassion ?? null,
        ocassionOther: formValues.ocassionOther || '',
        venmo: formValues.venmo || '',
        cashapp: formValues.cashapp || '',
        paypal: formValues.paypal || '',
        zelle: formValues.zelle || '',
        allowCash: formValues.allowCash || false,
        costPerSquare: formValues.costPerSquare || null,
        completed: true,
        url: !!newImageUrl ? newImageUrl : formValues.url ?? '',
        address: {
          label: address1 || '',
          label2: address2 || '',
          country: formValues.country || '',
          state: formValues.state || '',
          city: formValues.city || '',
          zip: formValues.zip || '',
          value: formValues.where || '',
          complementaryAddress: formValues.complementaryAddress || ''
        }
      }

      if (type === 'edit') {
        evData.noteForGuests = formValues.note || ''
      }

      if (type === 'new') {
        evData.allowGuests = true
        amplitude.track('Event created', undefined, {
          user_id: user.uid
        })
        // logEvent(analytics, 'create_event', {
        //   source: 'NEW_EVENT'
        // })
      }

      const eventCircleCode = await createEvent(evData)

      navigate(`/event/${eventCircleCode}`)
    } catch (err) {
      console.log('Error submiting', err)
      toast({
        status: 'error',
        title: err.message || 'Error while hosting playlist',
        position: 'top'
      })
    }
  }

  const handleAddressSelect = useCallback(
    (option, manualAddress) => {
      handleSelectAndSetAddress(
        option,
        manualAddress,
        fieldCity,
        fieldState,
        fieldCountry,
        fieldZip,
        fieldWhere,
        form,
        setAddress1,
        setAddress2
      )
    },
    [fieldCity, fieldState, fieldZip, fieldCountry, fieldWhere, form]
  )

  const handleOldEventData = useCallback(() => {
    if (!eventInfo) return

    form.setValue('eventTitle', eventInfo.name)
    form.setValue('ocassion', eventInfo.ocassion)
    form.setValue('ocassionOther', eventInfo?.ocassionOther)
    form.setValue('hostName', eventInfo.hostName)
    form.setValue('date', !!eventInfo.date ? moment(eventInfo.date) : null)
    form.setValue(
      'time',
      !!eventInfo.time ? moment(`${eventInfo.date} ${eventInfo.time}`) : null
    )
    form.setValue('timezone', eventInfo.timezone ?? moment.tz.guess())
    form.setValue('note', eventInfo.noteForGuests)
    form.setValue('url', eventInfo.url ?? '')

    form.setValue('cashapp', eventInfo.cashapp)
    form.setValue('paypal', eventInfo.paypal)
    form.setValue('venmo', eventInfo.venmo)
    form.setValue('zelle', eventInfo.zelle)
    form.setValue('allowCash', eventInfo.allowCash)
    form.setValue('costPerSquare', eventInfo.costPerSquare)

    if (
      eventInfo.address?.value ||
      eventInfo.address?.zip ||
      eventInfo.address?.state ||
      eventInfo.address?.city ||
      eventInfo.address?.country ||
      eventInfo.address?.complementaryAddress
    ) {
      form.setValue('where', eventInfo.address?.value)
      form.setValue('zip', eventInfo.address?.zip)
      form.setValue('state', eventInfo.address?.state)
      form.setValue('city', eventInfo.address?.city)
      form.setValue('country', eventInfo.address?.country)
      form.setValue(
        'complementaryAddress',
        eventInfo.address?.complementaryAddress
      )
    }
  }, [eventInfo, form])

  useEffect(() => {
    handleAddressSelect({}, true)
  }, [handleAddressSelect])

  useEffect(() => {
    if (eventInfo && type === 'edit') {
      handleOldEventData()
    }
  }, [eventInfo, handleOldEventData, type])

  useEffect(() => {
    if ((!user || user.isAnonymous) && !loading) {
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
    }
    // Fist load
    if (!!user && !loading && !eventLoading) {
      if (type === 'edit' && !eventInfo) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      if (type === 'new') {
        if (user.displayName) {
          form.setValue('hostName', user.displayName)
        }
        form.setValue('date', moment(new Date()))
        form.setValue('time', moment(getNextHourAndRoundTo30Minutes()))
        form.setValue('timezone', moment.tz.guess())
      }
    }
  }, [type, user, loading, eventLoading, eventInfo, navigate, form])

  useEffect(() => {
    if (type === 'new') {
      const categoryValue = incomingOcassion ?? view
      const category = categories.find((c) => c.value === categoryValue)
      if (!!category) {
        form.setValue('ocassion', category.value)
        form.setValue('url', category.urls[0] ?? DEFAULT_COVER)
      } else {
        form.setValue('url', DEFAULT_COVER)
      }
    }
  }, [type, form, incomingOcassion, view])

  if (!user || user?.isAnonymous) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - See your events history</title>
          <meta
            name="description"
            content="See events you’ve hosted or attended. Click on the tiles to see the event details."
          />
        </Helmet>
      </>
    )
  }

  return (
    <Base hideBg={true} bottomBar={false} allowFullHeight={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create online invitations with Coteri</title>
        <meta
          name="description"
          content={
            'Create your online invitation with Coteri. Enter your event details, such as when and where. Decide if you want to collaborate on a music playlist and request list.'
          }
        />
      </Helmet>

      {!!eventLoading ? (
        <div className="w-full h-full flex flex-1 justify-center items-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      ) : (
        <div className="w-full flex gap-5 flex-col sm:flex-row">
          <div className="order-1 sm:w-[40%] w-[100%]">
            <NewEventImage form={form} view={view} />
          </div>

          <div className="order-2 flex-1">
            <NewEventTitle form={form} />

            <NewEventHostName form={form} />

            <NewEventDateAndTime form={form} />

            <NewEventLocation
              form={form}
              address1={address1}
              address2={address2}
              handleAddressSelect={handleAddressSelect}
            />

            <NewEventOcassion form={form} />

            <NewEventCost form={form} costPerSquare={costPerSquare} />

            <div
              className={`bg-off-white p-3 flex flex-row mt-[12px] rounded-md border-1 ${!fieldNote && !noteFocused ? '' : 'hidden'} relative`}
              onClick={handleFocusNote}
            >
              <div className="flex items-center mr-[8px]">
                <Note fill={'#5B4ABC'} />
              </div>
              <span className="text-primary font-semibold hover:underline cursor-pointer">
                Add event description, or a note for your guests
              </span>
            </div>
          </div>
        </div>
      )}

      <div className={`${fieldNote || noteFocused ? '' : 'hidden'}`}>
        <div className="w-full mt-4">
          <div className="flex items-center justify-between">
            <span>Additional event information</span>

            <button
              className="text-red-500 text-[14px] cursor-pointer"
              onClick={() => form.setValue('note', '')}
            >
              delete
            </button>
          </div>
          <div className="w-full h-[2px] bg-secondary mb-2" />
        </div>
        <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md border-1">
          <textarea
            className="bg-transparent w-full outline-none"
            autoComplete="off"
            {...form.register('note')}
            onFocus={() => setNoteFocused(true)}
            onBlur={() => setNoteFocused(false)}
            ref={(e) => {
              form.register('note')?.ref(e)
              noteInputRef.current = e
            }}
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        <Button
          text={type === 'edit' ? 'Update event' : 'Save event'}
          onClick={form.handleSubmit(handleSubmit)}
          className={'w-full mt-5 max-w-[350px]'}
          size={'medium'}
        />
      </div>

      {/* <div>
        <div className='w-full flex items-center justify-center mt-10'>
          <div className='flex flex-1 bg-off-white h-[2px] mr-2' />
          <span className='text-[13px] text-secondary'>Additional event features</span>
          <div className='flex flex-1 bg-off-white h-[2px] ml-2' />
        </div>

        <div className='flex items-center justify-center gap-5 mt-2'>
          <NewEventAdditionalInformation toggleTab={toggleTab} setToggleTab={setToggleTab} form={form} />
        </div>
      </div> */}
    </Base>
  )
}

export default memo(NewEvent)
