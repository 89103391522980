import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useToast
} from '@chakra-ui/react'
import Button from '../../Button/Button'
import { EmailInvitesIcon } from '../../../assets/icons'
import { ref, set } from 'firebase/database'
import { database } from '../../../firebase'

const EmailDistributionConfirmInvitesModal = ({
  isOpen,
  onClose,
  onConfirm,
  recipients,
  circleCode
}) => {
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const onSend = async () => {
    setLoading(true)
    try {
      const newGuests = {}
      recipients.forEach(({ name, email }) => {
        const id = email.replaceAll('.', '')
        newGuests[id] = {
          name,
          email
        }
      })
      await set(ref(database, `circles/${circleCode}/invites`), newGuests)
      onConfirm()
    } catch (e) {
      console.log(e)
      toast({
        zIndex: 999999,
        title: 'Error sending invites',
        position: 'top',
        status: 'error'
      })
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={'500px'} mx={4}>
        <ModalBody>
          <>
            <div className="flex flex-col items-center">
              <EmailInvitesIcon width={120} height={80} />
              <p className="text-[20px] mt-3">Ready to send?</p>
              <p className="text-center text-[14px] mt-4">
                Confirm all information on email distribution list looks
                correct. Click continue when you're ready.
              </p>
            </div>

            <div className="flex sm:flex-row flex-col items-center justify-between my-5 sm:gap-0 gap-5 z-50">
              <Button
                type={'border'}
                text={'Continue editing'}
                onClick={onClose}
                disabled={loading}
                size={'small'}
              />
              <Button
                text={'Yes, looks good!'}
                onClick={onSend}
                loading={loading}
                size={'small'}
              />
            </div>
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EmailDistributionConfirmInvitesModal
