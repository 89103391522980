import React, { memo, useEffect, useCallback, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../Input/Input'
import Button from '../../Button/Button'
import { handleSelectAndSetAddress } from '../../../utils/address'
import ClipLoader from 'react-spinners/ClipLoader'
import { update } from 'firebase/database'
import { useParams } from 'react-router-dom'
import { ref, database } from '../../../firebase'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import Modal from '../../Modal/Modal'
import { useToast } from '@chakra-ui/react'
import DottedLine from '../../DottedLine/DottedLine'


export const schema = yup
  .object({
    where: yup.string().required(),
    complementaryAddress: yup.string(),
    country: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required()
  })
  .required()

const LocationModal = ({ isOpen, handleOnClose, overrideConfirm, avoidResetForm, incommingAddress }) => {
  const { circleCode } = useParams()
  const toast = useToast()

  const [addressOptions, setAddressOptions] = useState([])

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE,
      debounce: 300
    })

  const [hasSelectedAddress, setHasSelectedAddress] = useState(false)
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [fieldWhere, fieldCity, fieldCountry, fieldState, fieldZip] =
    form.watch(['where', 'city', 'country', 'state', 'zip'])

  const handleAddressOptionsFilter = useCallback(async () => {
    try {
      if (!fieldWhere) {
        setAddressOptions([])
        return
      }
      getPlacePredictions({ input: fieldWhere })

      const detailedPlacesPromise = placePredictions.map(async (result) => {
        // eslint-disable-next-line no-undef
        return new Promise((resolve) => {
          placesService?.getDetails(
            {
              placeId: result.place_id
            },
            (placeDetails) => {
              resolve(placeDetails)
            }
          )
        })
      })

      // eslint-disable-next-line no-undef
      const resolvedPlacesPromise = await Promise.all(detailedPlacesPromise)

      const formattedResult = resolvedPlacesPromise.map((result) => ({
        label: result.formatted_address,
        value: result
      }))

      setAddressOptions(formattedResult)
    } catch (error) {
      console.error('Error fetching geocode data:', error)
    }
  }, [fieldWhere, getPlacePredictions, placePredictions, placesService])

  const handleAddressOnChange = useCallback(
    (event) => {
      form.setValue('where', event.target.value)
    },
    [form]
  )

  const handleFormSubmit = (formValues) => {
    try {
      const newAddressField = {
        address: {
          label: address1 || '',
          label2: address2 || '',
          country: formValues.country || '',
          state: formValues.state || '',
          city: formValues.city || '',
          zip: formValues.zip || '',
          value: formValues.where || '',
          complementaryAddress: formValues.complementaryAddress || ''
        }
      }

      update(ref(database, `circles/${circleCode}/info`), newAddressField)

      form.reset()

      toast({
        status: 'success',
        title: 'Location updated',
        position: 'top'
      })

      handleOnClose()
    } catch (err) {
      console.log('EditEvent: ', err)
      toast({
        status: 'error',
        title: 'Location not updated',
        position: 'top'
      })
    }
  }

  const handleAddressSelect = useCallback(
    (option, manualAddress) => {
      handleSelectAndSetAddress(option, manualAddress, fieldCity, fieldState, fieldCountry, fieldZip, fieldWhere, form, setAddress1, setAddress2)
    },
    [fieldCity, fieldState, fieldZip, fieldCountry, fieldWhere, form]
  )

  const onClose = () => {
    if (!avoidResetForm) {
      form.reset()
    }

    handleOnClose()
  }

  useEffect(() => {
    handleAddressSelect({}, true)
  }, [fieldCity, handleAddressSelect])

  useEffect(() => {
    if (incommingAddress && !Object.values(incommingAddress)?.every(value => value === undefined)) {
      form.setValue('where', incommingAddress?.address1)
      form.setValue('city', incommingAddress?.city)
      form.setValue('state', incommingAddress?.state)
      form.setValue('country', incommingAddress?.country)
      form.setValue('zip', incommingAddress?.zip)
      form.setValue('complementaryAddress', incommingAddress?.complementaryAddress)
      setHasSelectedAddress(true)
    }
  }, [form, incommingAddress])

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={"max-w-[450px] w-full mx-5"}>
      <div className="bg-white rounded-input relative pb-5 w-full flex flex-col">
        <div className="relative">
          <div className="absolute inset-0 w-full h-full left-2 sm:left-10">
            <DottedLine color={"#80808033"} />
          </div>

          <div className='sm:ml-10 ml-2 mt-5 z-50'>
            <div className='flex items-center'>
              <div className="w-[3px] h-4 bg-primary" />
              <span className='px-3 font-semibold'>Set your event location</span>
            </div>
          </div>

          <div className='sm:ml-10 ml-2 mt-5 z-50 pl-3 pr-10'>
            <Input
              type="autocomplete"
              placeholder="Search for an address"
              className="mt-[18px]"
              register={form.register('where')}
              hasError={form.formState.errors.where?.message}
              form={form}
              onCompleteFunction={handleAddressOptionsFilter}
              onCompleteOptions={addressOptions}
              onChange={handleAddressOnChange}
              onSelect={(ad) => {
                handleAddressSelect(ad, false)
                setHasSelectedAddress(true)
              }}
            />
            {hasSelectedAddress && <>
              <Input
                type="default"
                placeholder="Apartment, suite, etc."
                className="mt-[18px]"
                register={form.register('complementaryAddress')}
                hasError={form.formState.errors.complementaryAddress?.message}
                form={form}
              />
              <Input
                type="default"
                placeholder="Country"
                className="mt-[18px]"
                register={form.register('country')}
                hasError={form.formState.errors.country?.message}
                form={form}
              />
              <Input
                type="default"
                placeholder="City"
                className="mt-[18px]"
                register={form.register('city')}
                hasError={form.formState.errors.city?.message}
                form={form}
              />
              <Input
                type="default"
                placeholder="State"
                className="mt-[18px]"
                register={form.register('state')}
                hasError={form.formState.errors.state?.message}
                form={form}
              />
              <Input
                type="default"
                placeholder="Zip"
                className="mt-[18px]"
                register={form.register('zip')}
                hasError={form.formState.errors.zip?.message}
                form={form}
              />
            </>}
          </div>
        </div>

        <div className='flex flex-row items-center mt-5 justify-between px-5'>
          {form.formState.isSubmitting ? (
            <div className="flex flex-1 items-center justify-center">
              <ClipLoader color="#5B4ABC" />
            </div>
          ) : (
            <React.Fragment>
              <Button type="text" text={'Back'} onClick={onClose} />
              <Button
                text={'Continue'}
                onClick={form.handleSubmit(overrideConfirm || handleFormSubmit)}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default memo(LocationModal)
