import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { useObject } from 'react-firebase-hooks/database'
import { ref, database } from '../firebase'
import Guest from './Guest'

const GuestsModal = ({ rsvpId, circleCode, popUp, setPopUp }) => {
  const [list, setList] = useState([])

  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )

  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])

  useEffect(() => {
    if (!guestsLoading) {
      const _list = Object.entries(guestsInfo)
        .map(([gKey, gData]) => ({
          id: gKey,
          ...gData
        }))
        .filter((x) => !!x.verified)
        .sort((a, b) => (a.response === 'yes' ? -1 : !b.response ? 1 : 0))
      setList(_list)
    }
  }, [guestsLoading, guestsInfo])

  return (
    <Modal
      isOpen={!!popUp}
      onClose={() => setPopUp(null)}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent mx="20px">
        <ModalHeader color="primary">Guest list</ModalHeader>
        <ModalCloseButton mt="5px" mr="0" />
        <ModalBody px="20px" overflowY="scroll" maxH="70vh">
          {list.map((item) => (
            <Guest key={item.id} item={item} />
          ))}
        </ModalBody>
        <ModalFooter>
          <Flex flex="1" align="center" justify="space-between">
            <Button
              h="46px"
              px="20px"
              py="24px"
              mr="10px"
              mb="20px"
              borderRadius="25px"
              colorScheme="whiteAlpha"
              borderWidth="1px"
              borderColor="pink.400"
              _hover={{ backgroundColor: 'gray.50' }}
              onClick={() => setPopUp(null)}
            >
              <Text fontSize="16px" fontWeight="600" color="pink.400">
                Close
              </Text>
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GuestsModal
