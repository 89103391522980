import { getHeaders } from './index'
import axios from 'axios'

const createAppleMusicPlaylist = async (accessToken, name) => {
  const data = {
    attributes: {
      name: name,
      public: true
    }
  }

  try {
    const headersConfig = getHeaders(accessToken)
    const response = await axios.post(
      'https://api.music.apple.com/v1/me/library/playlists',
      data,
      headersConfig
    )
    const playlist = response.data?.data || [{}]
    console.log(response.data?.data)
    return playlist[0].id
  } catch (error) {
    console.log(error)
    return null
  }
}

const addToAppleMusicPlaylist = async (accessToken, playlistID, songsIDs) => {
  const data = {
    data: songsIDs.map((id) => ({ id, type: 'songs' }))
  }
  const headersConfig = getHeaders(accessToken)
  await axios.post(
    `https://api.music.apple.com/v1/me/library/playlists/${playlistID}/tracks`,
    data,
    headersConfig
  )
  console.log('Successfully added to apple playlist')
}

export { createAppleMusicPlaylist, addToAppleMusicPlaylist }
