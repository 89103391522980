import * as React from 'react'

function Note({ fill, ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 13.5h11v-1h-11v1zm0-3h11v-1h-11v1zm0-3h11v-1h-11v1zM21 20.077L17.923 17H4.616c-.46 0-.845-.154-1.154-.462-.309-.308-.463-.692-.462-1.153V4.615c0-.46.154-.844.463-1.153A1.56 1.56 0 014.615 3h14.77c.46 0 .844.154 1.152.462.308.308.462.692.463 1.153v15.462zM4.616 16H18.35L20 17.644V4.616a.591.591 0 00-.192-.424.583.583 0 00-.423-.192H4.615a.59.59 0 00-.423.192.583.583 0 00-.192.423v10.77a.59.59 0 00.192.423.582.582 0 00.423.192"
        fill={fill || "#000"}
      />
    </svg>
  )
}

export default Note
