import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { ArrowDownIcon } from '../../../assets/icons'
import { twMerge } from 'tailwind-merge'

const LargeSelect = ({ className, options, register, form, hasError }) => {
  const divRef = useRef(null)
  const [opened, setOpened] = useState(false)

  const formValue = form?.getValues(register.name)

  const onFocus = () => {
    setOpened(!opened)
  }

  const handleOutsideClick = useCallback((event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setOpened(false)
    }
  }, [])

  const handleSelectOption = useCallback(
    (option) => {
      form?.setValue(register.name, option)
      setOpened(false)
    },
    [form, register.name]
  )

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('click', handleOutsideClick)

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div
      ref={divRef}
      className={twMerge(
        `relative px-[14px] py-[16px] w-80 sm:w-96 min-w-24 h-h-large-input flex bg-primary ${hasError ? 'border-error border-1' : opened ? 'border-primary' : 'border-input-border'} border-1 rounded-input bg-transparent items-center justify-between cursor-pointer ${opened && 'border-[2px]'}`,
        className
      )}
      onClick={onFocus}
    >
      <div className="flex flex-col">
        <span
          className={`${opened ? 'text-primary' : 'text-black font-semibold'} pointer-events-none text-[20px]`}
        >
          {formValue?.title}
        </span>
        <span
          className={`${opened ? 'text-primary font-bold' : 'text-placeholder font-normal'} pointer-events-none text-[14px]`}
        >
          {formValue?.subtitle}
        </span>
      </div>

      <ArrowDownIcon opened={opened} />

      {opened && (
        <div className="border-1 absolute top-[30px] flex flex-col items-start rounded-input right-0 left-0 mt-56px max-h-56 overflow-y-auto bg-white z-10 scrollbar-custom">
          {options.map((option, index) => {
            return (
              <div
                key={index}
                className="hover:bg-primary hover:text-white w-full p-2 rounded-input cursor-pointer scrollbar-custom flex flex-col"
                onClick={() => handleSelectOption(option)}
              >
                <span className="text-[20px]">{option.title}</span>
                <span className="text-[14px]">{option.subtitle}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default memo(LargeSelect)
