import React, { memo } from 'react'
import RequestTabListItem from './RequestTabListItem'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'

const RequestTabList = ({ items, loading, rsvpId, guestsInfo, eventInfo, overrideDelete, owner }) => {
  if (loading) {
    return (
      <div className="flex flex-1 items-center justify-center mt-[15px]">
        <ClipLoader color="#5B4ABC" />
      </div>
    )
  }

  if (items?.length === 0) {
    return (
      <div className="flex flex-1 items-center justify-center mt-[15px]">
        <span>No items found</span>
      </div>
    )
  }

  return (
    <div className="border-1 rounded-input mt-[14px] flex items-center w-full">
      <table className="overflow-hidden w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-3 w-[20%] rounded-tl-input">
              <div className="w-full flex ml-3 md:ml-4 font-semibold">Item</div>
            </th>
            <th className="py-3 w-[30%]">
              <div className="w-full flex items-center font-semibold">Owner</div>
            </th>
            <th className="py-3 w-[10%] rounded-tr-input" />
          </tr>
        </thead>

        <tbody className="text-center">
          {items?.sort((a, b) => {
            if (!a.createdAt) return -1;
            if (!b.createdAt) return 1;

            return moment(a.createdAt).diff(moment(b.createdAt));
          }).map((item, index) => (
            <RequestTabListItem
              key={index}
              item={item}
              rsvpId={rsvpId}
              eventInfo={eventInfo}
              guestsInfo={guestsInfo}
              overrideDelete={overrideDelete}
              owner={owner}
            />
          )).reverse()}
        </tbody>
      </table>
    </div>
  )
}

export default memo(RequestTabList)
