import React, { useEffect, useMemo, useState } from 'react'
import Base from '../../base'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import Button from '../../../components/Button/Button'
import SquaresSquare from '../../../components/Squares/SquaresSquare/SquaresSquare'
import { calculatePayoutAmount } from '../../../utils/square'
import SquaresTable from '../../../components/Squares/SquaresTable/SquaresTable'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../firebase'
import SquaresSummaryModal from '../../../components/Squares/SquaresSummaryModal/SquaresSummaryModal'
import DottedLine from '../../../components/DottedLine/DottedLine'
import { useObject } from 'react-firebase-hooks/database'
import { ref } from 'firebase/database'
import { database } from '../../../firebase'
import { Star } from '@mui/icons-material'

const SquaresSummary = () => {
  const { circleCode } = useParams()
  const [user] = useAuthState(auth)
  const navigate = useNavigate()
  const [summaryModal, setSummaryModal] = useState(false)

  const [squareSnap, squareLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/squares`)
  )
  const squareInfo = squareSnap?.val()

  const myInitials = useMemo(() => `Your initials: ${squareInfo?.squares?.find((s) => s.uid === user?.uid)?.initials}`, [squareInfo?.squares, user?.uid])

  const hasSquaresPurchased = useMemo(() => squareInfo?.squares?.some((s) => s.uid && s.uid !== user?.uid), [squareInfo?.squares, user?.uid])
  const allSquaresPurchased = useMemo(() => squareInfo?.squares?.length === 100, [squareInfo?.squares?.length])

  const handleContinue = () => {
    navigate(`/event/${circleCode}/squares/choose`)
  }

  const handleCancel = () => {
    navigate(-1)
  }

  const handleGameSettings = () => {
    navigate(`/event/${circleCode}/squares`)
  }

  const handlePurchaseMoreSquares = () => {
    navigate(`/event/${circleCode}/squares/choose`)
  }

  const totalCost = useMemo(() => squareInfo?.costPerSquare * 100, [squareInfo?.costPerSquare])

  const values = useMemo(() => {
    const payoutAmountFist = calculatePayoutAmount(totalCost, squareInfo?.payouts?.first)
    const payoutAmountSecond = calculatePayoutAmount(totalCost, squareInfo?.payouts?.second)
    const payoutAmountThird = calculatePayoutAmount(totalCost, squareInfo?.payouts?.third)

    return {
      first: payoutAmountFist,
      second: payoutAmountSecond,
      third: payoutAmountThird,
      fourth: totalCost - payoutAmountFist - payoutAmountSecond - payoutAmountThird
    }
  }, [squareInfo, totalCost])

  return (
    <React.Fragment>
      <SquaresSummaryModal
        isOpen={summaryModal}
        onClose={() => setSummaryModal(false)}
        user={user}
        squareInfo={squareInfo}
      />

      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        footerContinueOnClick={handleContinue}
        footerCancelOnClick={handleCancel}
        footerCancelText={'Back'}
        enableFooter
        removeVerticalPadding
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - See your events history</title>
          <meta
            name="description"
            content="See events you’ve hosted or attended. Click on the tiles to see the event details."
          />
        </Helmet>

        {squareLoading ?
          <div className="mt-[15px] flex flex-1 items-center justify-center">
            <ClipLoader color="#5B4ABC" />
          </div> :
          <div className="flex relative flex-1 mt-[-20px] mb-[-15px] flex-col">
            {/* <div id='content' className='px-2 sm:px-10 z-50 w-full flex flex-1 flex-col'>
              <div id='content1' className='flex items-center md:flex-row flex-col flex-1'>
                <div>
                  <div className='mt-12 flex items-start justify-start w-full sm:flex-row flex-col sm:justify-between sm:items-center'>
                    <div className='flex flex-1 items-center'>
                      <span className='text-[20px] font-semibold'>Cost per square</span>
                      <SquaresSquare value={`$${squareInfo?.costPerSquare}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none ml-2'} placeholderBefore inputProps={{ disabled: true }} />
                    </div>
                  </div>

                  <div className='mt-3 flex flex-col w-full sm:mt-1'>
                    <span className='text-[20px] font-semibold'>Payout Amounts</span>

                    <div className='flex flex-1 items-center gap-2'>
                      <SquaresSquare title={'1st'} value={`$${values.first}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                      <SquaresSquare title={'2st'} value={`$${values.second}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                      <SquaresSquare title={'3st'} value={`$${values.third}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                      <SquaresSquare title={'4st'} value={`$${values.fourth}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                    </div>
                  </div>
                </div>

                <div className={`flex flex-col flex-1 md:items-end mr-4 items-center md:mt-12 mt-5 md:ml-10 ml-0`}>
                  {hasSquaresPurchased ?
                    <span className='text-[14px] md:px-0 px-10 sm:text-start text-center'><span className='font-semibold'>Game settings locked</span> because guests have selected squares.</span>
                    :
                    <Button text={'Edit game settings'} size={'medium'} className={"mt-2 sm:mt-0"} type={'off-white'} onClick={handleGameSettings} />
                  }
                  {!allSquaresPurchased && <Button text={'Pick more squares'} size={'medium'} className={"mt-6"} onClick={handlePurchaseMoreSquares} />}
                  <span className='text-[14px] underline text-primary cursor-pointer font-semibold mt-2' onClick={() => setSummaryModal(true)}>Summary of squares picks</span>
                </div>
              </div>
            </div>

            <DottedLine color={"#80808033"} className={"my-10 h-[1px]"} horizontal solid /> */}

            <div className='flex flex-col px-2 sm:px-10 mt-10'>
              <div id='content2' className='flex flex-col items-center flex-1'>
                <div className='flex flex-col items-center gap-4 flex-1'>
                  <div className='flex  flex-col items-center'>
                    <div className='flex items-start justify-evenly w-full'>
                      <div className='flex flex-col items-center flex-[0.3]'>
                        <div className='w-10 h-10 rounded-sm border-[3px] border-primary' />
                        <span className='text-[14px] font-semibold mt-2'>Owner</span>
                        <span className='text-[12px]'>You own this square</span>
                      </div>

                      <div className='flex flex-col items-center flex-[0.3]'>
                        <div className='w-10 h-10 rounded-sm border-2 border-light-purple bg-light-purple-2' />
                        <span className='text-[14px] font-semibold mt-2'>Contender</span>
                        <span className='text-[12px] text-center'>This square corresponds to the current score of the game.</span>
                      </div>

                      <div className='flex flex-col items-center flex-[0.3]'>
                        <div className='w-10 h-10 rounded-sm border-2 border-primary bg-primary flex items-center justify-center'>
                          <Star style={{ color: 'white' }} />
                        </div>
                        <span className='text-[14px] font-semibold mt-2'>Winner</span>
                        <span className='text-[12px] text-center'>Your square won for the corresponding quarter.</span>
                        <div className='flex gap-1 mt-1'>
                          <span className='py-1 rounded-full bg-light-purple text-[11px] text-white w-[26px] text-center'>1st</span>
                          <span className='py-1 rounded-full bg-light-purple text-[11px] text-white w-[26px] text-center'>2nd</span>
                          <span className='py-1 rounded-full bg-light-purple text-[11px] text-white w-[26px] text-center'>3rd</span>
                          <span className='py-1 rounded-full bg-light-purple text-[11px] text-white w-[26px] text-center'>4th</span>
                        </div>
                      </div>
                    </div>

                    <div className='flex items-center justify-between w-full mt-5 md:flex-row flex-col'>
                      <div>
                        <div className='border-1 border-primary rounded-md flex-row flex p-5 min-w-[350px] max-w-[350px] mt-4'>
                          <div className='flex flex-1 items-center justify-between pr-2'>
                            <img src='https://logosmarcas.net/wp-content/uploads/2020/07/Philadelphia-Eagles-Logo.png' width={100} height={100} />
                            <span className='text-[21px]'>0</span>
                          </div>
                          <div className='flex flex-1 items-center justify-between pl-2'>
                            <span className='text-[21px]'>0</span>
                            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYvn6ozSPiHxX5Dv5jyxw1zdRrzaagBhr9KQ&s' width={100} height={100} />
                          </div>
                        </div>

                        <SquaresSquare
                          placeholder={'Enter your initial to begin'}
                          wrapperInputClassName={`w-auto px-2 mt-4`}
                          inputProps={{ fontSize: 14, disabled: true }}
                          disabledProps={{ fontSize: 14 }}
                          inputClassName={'placeholder:text-[14px]'}
                          value={myInitials || ''}
                          removeOnChangeValidation
                        />
                      </div>

                      <div className={`flex flex-col flex-1 items-end justify-between h-full md:ml-10 ml-0`}>
                        {/* {hasSquaresPurchased ?
                          <span className='text-[14px] md:px-0 px-10 sm:text-start text-center'><span className='font-semibold'>Game settings locked</span> because guests have selected squares.</span>
                          :
                          <Button text={'Edit game settings'} size={'medium'} className={"mt-2 sm:mt-0"} type={'off-white'} onClick={handleGameSettings} />
                        } */}
                        {!allSquaresPurchased && <Button text={'Pick more squares'} size={'medium'} className={"mt-6"} onClick={handlePurchaseMoreSquares} />}
                        <span className='text-[14px] underline text-primary cursor-pointer font-semibold mt-2' onClick={() => setSummaryModal(true)}>Summary of squares picks</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DottedLine color={"#80808033"} className={"my-10 h-[1px]"} horizontal solid />

            <div>
              <div id='content3' className='px-5 flex flex-col items-center flex-1'>
                <SquaresTable
                  selectedSquares={squareInfo?.squares}
                  user={user}
                  hideNumbers
                  disableClick
                />
              </div>
            </div>

            <DottedLine color={"#80808033"} className={"my-10 h-[1px]"} horizontal solid />
          </div>}
      </Base>
    </React.Fragment>
  )
}

export default SquaresSummary
