import React, { memo, useMemo, useState } from 'react'
import { EditIcon } from '../../../assets/icons'
import Button from '../../Button/Button'
import RequestListItemModal from '../RequestListItemModal/RequestListItemModal'
import { useParams } from 'react-router-dom'
import RequestListDeleteModal from '../RequestListDeleteModal/RequestListDeleteModal'
import { useToast } from '@chakra-ui/react'
import { ref, update } from 'firebase/database'
import { auth, database } from '../../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { eventTimePassed } from '../../../helpers'

const RequestTabListItem = ({
  item,
  rsvpId,
  guestsInfo,
  eventInfo,
  overrideDelete,
  owner
}) => {
  const { circleCode } = useParams()
  const [user, loading] = useAuthState(auth)
  const [itemModal, setItemModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const toast = useToast()

  const isTimePassed = eventTimePassed(eventInfo)

  const isGoing = guestsInfo[rsvpId]?.response === 'yes'

  const userId = !!rsvpId ? rsvpId : user?.uid ?? ''

  const handleClaimItem = async (val) => {
    if (loading) return
    try {
      await update(ref(database, `circles/${circleCode}/items/${item.id}`), {
        claimed: !!val ? true : null,
        claimedBy: !!val ? userId : null
      })
      toast({
        status: 'success',
        title: val ? 'Item claimed' : 'Item unclaimed',
        position: 'top'
      })
    } catch (err) {
      console.log('Error')
      toast({
        status: 'error',
        title: 'Error attempting to claim item',
        position: 'top'
      })
    }
  }

  return (
    <>
      <tr className="border-t-1">
        <td className="py-3">
          <div className="flex-1 flex ml-3 md:ml-4 items-center">
            <span className="max-w-[80px] md:max-w-[230px] sm:max-w-[200px] text-start">
              {item.name}
            </span>
          </div>
        </td>
        <td className="py-3">
          <div className="flex-1 flex text-placeholder items-center">
            {!!item.claimed ? (
              item.claimedBy === userId ? (
                <span className="mr-2">You</span>
              ) : (
                <span>
                  {item?.claimedBy === eventInfo?.hostID
                    ? eventInfo?.hostName ?? item?.claimedBy
                    : guestsInfo[item?.claimedBy]?.name ?? item?.claimedBy}
                </span>
              )
            ) : null}
            {(!item.claimed || item.claimedBy === userId) && !isTimePassed ? (
              !isGoing && !owner ? (
                <span>No owner</span>
              ) : (
                <Button
                  size={'extra-small'}
                  text={!!item.claimed ? 'unclaim' : 'claim'}
                  type={!!item.claimed ? 'border' : 'default'}
                  onClick={() => handleClaimItem(!item.claimed)}
                />
              )
            ) : null}
          </div>
        </td>
        <td
          className={`flex gap-2 py-3 ${!!rsvpId ? 'justify-center' : 'justify-start'}`}
        >
          {owner && !isTimePassed ? (
            <div className="flex-1 flex items-center justify-evenly">
              <EditIcon
                fill="#00000099"
                className="cursor-pointer"
                onClick={() => setItemModal(true)}
              />
            </div>
          ) : null}
        </td>
      </tr>

      <RequestListItemModal
        isOpen={itemModal}
        onClose={() => setItemModal(false)}
        onDelete={() => setDeleteModal(true)}
        item={item}
        userId={userId}
        owner={owner}
      />

      <RequestListDeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        item={item}
        overrideDelete={overrideDelete}
      />
    </>
  )
}

export default memo(RequestTabListItem)
