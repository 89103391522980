export const phoneMasks = [
  { value: "+1", mask: "999 999 9999" },
  { value: "+93", mask: "99 999 9999" },
  { value: "+355", mask: "999 999 999" },
  { value: "+213", mask: "999 99 99 99" },
  { value: "+376", mask: "999 999" },
  { value: "+244", mask: "999 999 999" },
  { value: "+54", mask: "9 9999 9999" },
  { value: "+374", mask: "99 999 999" },
  { value: "+297", mask: "999 9999" },
  { value: "+61", mask: "9 9999 9999" },
  { value: "+43", mask: "999 999 9999" },
  { value: "+994", mask: "99 999 99 99" },
  { value: "+973", mask: "9999 9999" },
  { value: "+880", mask: "99999 999999" },
  { value: "+375", mask: "99 999 99 99" },
  { value: "+32", mask: "999 99 99 99" },
  { value: "+501", mask: "999 9999" },
  { value: "+229", mask: "99 99 99 99" },
  { value: "+975", mask: "99 999 999" },
  { value: "+591", mask: "9 999 9999" },
  { value: "+387", mask: "99 999 999" },
  { value: "+267", mask: "999 9999" },
  { value: "+55", mask: "99 9 9999 9999" },
  { value: "+359", mask: "999 999 999" },
  { value: "+226", mask: "99 99 99 99" },
  { value: "+257", mask: "99 99 99 99" },
  { value: "+855", mask: "99 999 999" },
  { value: "+237", mask: "99 99 99 99" },
  { value: "+238", mask: "999 99 99" },
  { value: "+1-345", mask: "999-9999" },
  { value: "+236", mask: "99 99 99 99" },
  { value: "+235", mask: "99 99 99 99" },
  { value: "+56", mask: "9 9999 9999" },
  { value: "+86", mask: "999 9999 9999" },
  { value: "+57", mask: "999 999 9999" },
  { value: "+269", mask: "999 9999" },
  { value: "+242", mask: "999 999 999" },
  { value: "+243", mask: "999 999 999" },
  { value: "+506", mask: "9999 9999" },
  { value: "+385", mask: "99 999 999" },
  { value: "+53", mask: "999 9999" },
  { value: "+357", mask: "99 999 999" },
  { value: "+420", mask: "999 999 999" },
  { value: "+45", mask: "99 99 99 99" },
  { value: "+253", mask: "99 99 99 99" },
  { value: "+1-767", mask: "999-9999" },
  { value: "+1-809", mask: "999-9999" },
  { value: "+670", mask: "9999 9999" },
  { value: "+593", mask: "9 999 999" },
  { value: "+20", mask: "9999 999 999" },
  { value: "+503", mask: "9999 9999" },
  { value: "+240", mask: "999 999 999" },
  { value: "+291", mask: "9 999 999" },
  { value: "+372", mask: "9999 9999" },
  { value: "+268", mask: "99 99 99 99" },
  { value: "+251", mask: "999 999 999" },
  { value: "+679", mask: "999 9999" },
  { value: "+358", mask: "999 999 9999" },
  { value: "+33", mask: "9 99 99 99 99" },
  { value: "+241", mask: "99 99 99 99" },
  { value: "+220", mask: "99 99 99 99" },
  { value: "+995", mask: "99 999 999" },
  { value: "+49", mask: "999 99999999" },
  { value: "+233", mask: "99 999 9999" },
  { value: "+30", mask: "999 999 9999" },
  { value: "+1-473", mask: "999-9999" },
  { value: "+502", mask: "9999 9999" },
  { value: "+224", mask: "99 99 99 99" },
  { value: "+245", mask: "999 9999" },
  { value: "+592", mask: "999 9999" },
  { value: "+509", mask: "99 99 9999" },
  { value: "+504", mask: "9999 9999" },
  { value: "+36", mask: "99 999 9999" },
  { value: "+354", mask: "999 9999" },
  { value: "+91", mask: "99999 99999" },
  { value: "+62", mask: "9999 999 999" },
  { value: "+98", mask: "999 999 9999" },
  { value: "+964", mask: "999 999 9999" },
  { value: "+353", mask: "999 999 999" },
  { value: "+972", mask: "999 999 9999" },
  { value: "+39", mask: "999 999 9999" },
  { value: "+225", mask: "99 99 99 99" },
  { value: "+1-876", mask: "999-9999" },
  { value: "+81", mask: "99 9999 9999" },
  { value: "+962", mask: "9999 9999" },
  { value: "+7", mask: "999 999 9999" },
  { value: "+254", mask: "999 999999" },
  { value: "+686", mask: "999 99999" },
  { value: "+965", mask: "9999 9999" },
  { value: "+996", mask: "999 999 999" },
  { value: "+856", mask: "99 999 999" },
  { value: "+371", mask: "99 999 999" },
  { value: "+961", mask: "99 999 999" },
  { value: "+266", mask: "9999 9999" },
  { value: "+231", mask: "999 999 999" },
  { value: "+218", mask: "99 9999999" },
  { value: "+423", mask: "999 9999" },
  { value: "+370", mask: "999 999 999" },
  { value: "+352", mask: "999 999 999" },
  { value: "+261", mask: "99 99 999 99" },
  { value: "+265", mask: "999 999 999" },
  { value: "+60", mask: "999 999 999" },
  { value: "+960", mask: "999 9999" },
  { value: "+223", mask: "99 99 99 99" },
  { value: "+356", mask: "9999 9999" },
  { value: "+692", mask: "999 9999" },
  { value: "+222", mask: "99 99 99 99" },
  { value: "+230", mask: "999 9999" },
  { value: "+52", mask: "999 999 9999" },
  { value: "+691", mask: "999 9999" },
  { value: "+373", mask: "999 999 99" },
  { value: "+377", mask: "99 999 9999" },
  { value: "+976", mask: "99 999 999" },
  { value: "+382", mask: "99 999 999" },
  { value: "+212", mask: "99 9999 999" },
  { value: "+258", mask: "99 999 9999" },
  { value: "+95", mask: "99 999 9999" },
  { value: "+264", mask: "999 999 9999" },
  { value: "+674", mask: "999 9999" },
  { value: "+977", mask: "99 999 999" },
  { value: "+31", mask: "99 999 9999" },
  { value: "+64", mask: "99 999 9999" },
  { value: "+505", mask: "9999 9999" },
  { value: "+227", mask: "99 99 99 99" },
  { value: "+234", mask: "999 999 9999" },
  { value: "+683", mask: "999 9999" },
  { value: "+47", mask: "999 99 999" },
  { value: "+968", mask: "9999 9999" },
  { value: "+92", mask: "999 999 9999" },
  { value: "+680", mask: "999 9999" },
  { value: "+970", mask: "9999 999 999" },
  { value: "+507", mask: "999 9999" },
  { value: "+675", mask: "9999 9999" },
  { value: "+595", mask: "999 999999" },
  { value: "+51", mask: "999 999 999" },
  { value: "+63", mask: "9999 999 999" },
  { value: "+48", mask: "999 999 999" },
  { value: "+351", mask: "99 999 9999" },
  { value: "+974", mask: "9999 9999" },
  { value: "+40", mask: "999 999 999" },
  { value: "+7", mask: "999 999 9999" },
  { value: "+250", mask: "999 999 999" },
  { value: "+1-869", mask: "999-9999" },
  { value: "+1-758", mask: "999-9999" },
  { value: "+1-784", mask: "999-9999" },
  { value: "+685", mask: "99 99999" },
  { value: "+378", mask: "999 999 999" },
  { value: "+239", mask: "99 99999" },
  { value: "+966", mask: "999 999 9999" },
  { value: "+221", mask: "99 999 9999" },
  { value: "+381", mask: "99 999 9999" },
  { value: "+248", mask: "999 9999" },
  { value: "+232", mask: "99 999999" },
  { value: "+65", mask: "9999 9999" },
  { value: "+421", mask: "999 999 999" },
  { value: "+386", mask: "99 999 999" },
  { value: "+677", mask: "99 99999" },
  { value: "+252", mask: "99 999 9999" },
  { value: "+27", mask: "99 999 9999" },
  { value: "+211", mask: "99 999 9999" },
  { value: "+34", mask: "999 999 999" },
  { value: "+94", mask: "99 999 9999" },
  { value: "+249", mask: "99 999 9999" },
  { value: "+597", mask: "999 9999" },
  { value: "+46", mask: "99 999 99 99" },
  { value: "+41", mask: "99 999 99 99" },
  { value: "+963", mask: "99 999 9999" },
  { value: "+886", mask: "9999 999 999" },
  { value: "+992", mask: "99 999 9999" },
  { value: "+255", mask: "99 999 9999" },
  { value: "+66", mask: "99 999 9999" },
  { value: "+228", mask: "99 999 999" },
  { value: "+676", mask: "99 999" },
  { value: "+1-868", mask: "999-9999" },
  { value: "+216", mask: "99 999 999" },
  { value: "+90", mask: "999 999 9999" },
  { value: "+993", mask: "9 9999 9999" },
  { value: "+688", mask: "999 999" },
  { value: "+256", mask: "999 999 999" },
  { value: "+380", mask: "99 999 9999" },
  { value: "+971", mask: "999 999 9999" },
  { value: "+44", mask: "9999 999999" },
  { value: "+598", mask: "99 999 999" },
  { value: "+998", mask: "99 999 9999" },
  { value: "+678", mask: "99 99999" },
  { value: "+379", mask: "99 9999 999" },
  { value: "+58", mask: "999 999 9999" },
  { value: "+84", mask: "99 999 9999" },
  { value: "+967", mask: "999 999 999" },
  { value: "+260", mask: "999 999 999" },
  { value: "+263", mask: "999 999 999" }
];
