import { useState, useEffect, useMemo } from 'react';

export function useMediaQuery(breakpoints) {
  const [matches, setMatches] = useState({});
  const memoizedBreakpoints = useMemo(() => breakpoints, [JSON.stringify(breakpoints)]);

  useEffect(() => {
    const mediaQueryLists = {};
    const listeners = {};

    const updateMatches = () => {
      const newMatches = {};
      Object.keys(mediaQueryLists).forEach(key => {
        newMatches[key] = mediaQueryLists[key].matches;
      });
      setMatches(newMatches);
    };

    Object.entries(memoizedBreakpoints).forEach(([key, width]) => {
      const mediaQuery = `(min-width: ${width}px)`;
      const mediaQueryList = window.matchMedia(mediaQuery);
      mediaQueryLists[key] = mediaQueryList;
      listeners[key] = () => updateMatches();

      mediaQueryList.addEventListener('change', listeners[key]);
    });

    updateMatches();

    return () => {
      Object.keys(mediaQueryLists).forEach(key => {
        mediaQueryLists[key].removeEventListener('change', listeners[key]);
      });
    };
  }, [memoizedBreakpoints]); // Note this dependency change

  return matches;
}