import React, { memo } from 'react'
import GuestItem from './GuestItem'

const GuestList = ({ guests, onDeleteGuest, sort, removeRsvp }) => {
  const sortedGuests = guests?.sort((a, b) => {
    const aMatches = a?.response === sort?.value;
    const bMatches = b?.response === sort?.value;

    if (aMatches && !bMatches) return -1;
    if (!aMatches && bMatches) return 1;

    return 0;
  });

  return (
    <div className="border-1 rounded-input mt-[14px] min-w-[370px] flex items-center w-full">
      <table className="overflow-hidden w-full">
        <thead>
          <tr>
            <th className="py-3 w-[5%] rounded-tl-input"></th>
            <th className="py-3 w-[30%]">
              <div className="w-full flex ml-1 md:ml-4 font-semibold">
                Guest
              </div>
            </th>
            {removeRsvp && <th
              className={`py-3 w-[20%] ${!onDeleteGuest && 'rounded-tr-input'}`}
            >
              <div className="w-full flex items-center md:justify-start justify-center">
                <span className='font-semibold'>RSVP</span>
              </div>
            </th>}
            {!!onDeleteGuest && (
              <th
                className={`py-3 w-[10%] rounded-tr-input bg-primary ${!!onDeleteGuest && 'rounded-tr-input'}`}
              >
                <div className="w-full flex items-center justify-center text-white">
                  Action
                </div>
              </th>
            )}
          </tr>
        </thead>

        <tbody className="text-center">
          {sortedGuests.map((guest, index) => (
            <GuestItem
              key={index}
              guest={guest}
              onDelete={onDeleteGuest}
              index={index + 1}
              removeRsvp={removeRsvp}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default memo(GuestList)
