import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import Modal from '../../Modal/Modal'
import Button from '../../Button/Button'
import { XIcon } from '../../../assets/icons'
import {
  startGuestVerification,
  verifyGuest
} from '../../../services/Verification'
import { useToast } from '@chakra-ui/react'
import { getSavedPhone, setSavedPhone } from '../../../services/database'
import ClipLoader from 'react-spinners/ClipLoader'
import * as amplitude from '@amplitude/analytics-browser'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../Input/Input'
import { osName } from 'react-device-detect'

export const schema = yup
  .object({
    otp: yup.string()
  })
  .required()

const PhoneOrEmailVerificationModal = ({
  opened,
  infoToVerify = {},
  circleCode,
  rsvpId,
  onClose,
  onVerified
}) => {
  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [otp] = form.watch(['otp'])
  const savedPhone = getSavedPhone() ?? {}
  const myDeviceOTP = !!infoToVerify
    ? (infoToVerify.channel === 'email' &&
        savedPhone.email === infoToVerify.info &&
        !!savedPhone.deviceOTP) ||
      (infoToVerify.channel === 'sms' &&
        savedPhone.phone === infoToVerify.info &&
        !!savedPhone.deviceOTP)
      ? savedPhone.deviceOTP
      : ''
    : ''
  const [isChecking, setChecking] = useState(myDeviceOTP !== '')
  const [canReSend, setCanReSend] = useState(false)
  const [isVerifying, setVerifying] = useState(false)
  const [deviceTrust, setDeviceTrust] = useState(false)
  const toast = useToast()
  const reSendInterval = useRef(0)

  const resendCode = async () => {
    startTimer()
    try {
      const { status, message } = await startGuestVerification({
        info: infoToVerify.info,
        channel: infoToVerify.channel
      })
      if (status === 'error') {
        toast({
          status: 'error',
          title: message ?? 'Could not send verification code',
          position: 'top'
        })
        return
      }

      toast({
        status: 'success',
        title: 'A verification code has been sent',
        position: 'top'
      })
    } catch (e) {
      console.log('resendCode', e)
      setVerifying(false)
    }
  }

  const handleVerify = async () => {
    setVerifying(true)

    try {
      const trustDevice = deviceTrust
      const { status, message, deviceOTP } = await verifyGuest({
        circleCode,
        info: infoToVerify.info,
        channel: infoToVerify.channel,
        guestId: rsvpId,
        code: otp,
        trustDevice,
        deviceName: osName ?? ''
      })
      if (status === 'error') {
        toast({
          status: 'error',
          title: message,
          position: 'top'
        })
        setVerifying(false)
        return
      }

      if (infoToVerify.channel === 'email') {
        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('email', infoToVerify.info)
        identifyEvent.set('rsvpId', rsvpId)
        amplitude.identify(identifyEvent)
        amplitude.setUserId(infoToVerify.info)

        amplitude.track(
          'Email verified',
          {
            circleCode
          },
          {
            user_id: rsvpId
          }
        )

        setSavedPhone(
          trustDevice
            ? { email: infoToVerify.info, deviceOTP }
            : { email: infoToVerify.info }
        )
      } else {
        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('phone', infoToVerify.info)
        identifyEvent.set('rsvpId', rsvpId)
        amplitude.identify(identifyEvent)
        amplitude.setUserId(infoToVerify.info)

        amplitude.track(
          'Phone Number verified',
          {
            circleCode
          },
          {
            user_id: rsvpId
          }
        )

        setSavedPhone(
          trustDevice
            ? { phone: infoToVerify.info, deviceOTP }
            : { phone: infoToVerify.info }
        )
      }

      if (!!onVerified) {
        onVerified()
      }
    } catch (e) {
      console.log('handleVerify', e)
      setVerifying(false)
    }
  }

  const startVerification = useCallback(async () => {
    console.log('startVerification', infoToVerify)
    if (!infoToVerify) {
      return
    }

    startTimer()
    if (!!myDeviceOTP) {
      setChecking(true)
    }
    const { status, message } = await startGuestVerification({
      info: infoToVerify.info,
      deviceOTP: myDeviceOTP,
      deviceName: osName,
      circleCode,
      guestId: rsvpId,
      channel: infoToVerify.channel,
      simulateProd: true
    })

    if (status === 'verified') {
      if (infoToVerify.channel === 'email') {
        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('email', infoToVerify.info)
        identifyEvent.set('rsvpId', rsvpId)
        amplitude.identify(identifyEvent)
        amplitude.setUserId(infoToVerify.info)

        amplitude.track(
          'Email verified',
          {
            circleCode
          },
          {
            user_id: rsvpId
          }
        )
      } else {
        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('phone', infoToVerify.info)
        identifyEvent.set('rsvpId', rsvpId)
        amplitude.identify(identifyEvent)
        amplitude.setUserId(infoToVerify.info)

        amplitude.track(
          'Phone Number verified',
          {
            circleCode
          },
          {
            user_id: rsvpId
          }
        )
      }

      if (!!onVerified) {
        onVerified()
      }
      return
    }
    if (status === 'error') {
      toast({
        status: 'error',
        title: message ?? 'Could not send verification code',
        position: 'top'
      })
      return
    }

    setChecking(false)
  }, [infoToVerify])

  const startTimer = () => {
    let seconds = 15
    setCanReSend(false)
    const id = setInterval(() => {
      if (seconds > 0) {
        seconds -= 1
      } else {
        setCanReSend(true)
        if (!!reSendInterval.current) {
          clearInterval(reSendInterval.current)
        }
      }
    }, 1000)
    reSendInterval.current = id
  }

  useEffect(() => {
    if (!!infoToVerify) {
      startVerification().then(() => null)
    }
  }, [infoToVerify, startVerification])

  useEffect(() => {
    return () => {
      if (!!infoToVerify) {
        if (!!reSendInterval.current) {
          clearInterval(reSendInterval.current)
        }
      }
    }
  }, [infoToVerify])

  return (
    <Modal isOpen={opened} onClose={onClose}>
      <div className="p-5 bg-white rounded-input mx-3 md:mx-0 flex items-center flex-col">
        {isChecking || !infoToVerify ? (
          <div className="flex flex-1 min-h-[200px] min-w-[100px] items-center justify-center">
            <ClipLoader color="#5B4ABC" />
          </div>
        ) : (
          <>
            {!!onVerified ? (
              <XIcon
                fill={'black'}
                width={20}
                height={20}
                className="self-end cursor-pointer"
                onClick={onClose}
              />
            ) : null}

            <h2 className="mb-3">
              {infoToVerify.channel === 'email'
                ? 'Verify your email.'
                : 'Verify your number.'}
            </h2>
            <span className="mb-3">
              Enter the 6-digit code sent to{' '}
              <span className="font-bold">
                {infoToVerify.channel === 'email'
                  ? infoToVerify.info
                  : infoToVerify.info}
              </span>
            </span>

            <Input
              register={form.register('otp')}
              form={form}
              type="masked"
              placeholder="Enter the 6-digit code*"
              className="flex-1 mb-5 mt-2"
              hasError={form.formState.errors.otp?.message}
              mask={'999999'}
            />

            <span className="mb-3">
              {infoToVerify.channel === 'email'
                ? "Didn't receive the email?"
                : "Didn't receive the text?"}{' '}
              <span className="font-bold underline cursor-pointer">
                <button
                  onClick={resendCode}
                  disabled={!canReSend}
                  className="text-primary disabled:text-gray"
                >
                  Resend it
                </button>
              </span>
            </span>

            <div className="flex items-center w-full mb-3">
              <input
                type="checkbox"
                className="cursor-pointer"
                checked={deviceTrust}
                onChange={(e) => setDeviceTrust(e.target.checked)}
              />
              <span
                className="ml-3"
                onClick={() => setDeviceTrust(!deviceTrust)}
              >
                Trust this device for future logins (optional)
              </span>
            </div>

            <Button
              text="Continue"
              onClick={handleVerify}
              disabled={isVerifying || !otp || otp.length < 6}
            />
          </>
        )}
      </div>
    </Modal>
  )
}

export default memo(PhoneOrEmailVerificationModal)
