import React, { memo, useState } from 'react'
import ActiveEventsCard from '../../../../components/MyEvents/ActiveEventsCard/ActiveEventsCard'
import CancelEventModal from '../../../../components/MyEvents/CancelEventModal/CancelEventModal'

const ActiveEvents = ({ isToggled, futureEvents, setDeleteEventModal }) => {
  return (
    <div className={`${isToggled ? 'mt[10px]' : 'hidden'} flex flex-1 `}>
      {futureEvents.length === 0 ? (
        <div className="flex flex-1 items-center justify-center">
          <span>No active events</span>
        </div>
      ) : (
        <div id="header" className="flex flex-1 flex-col mt-[10px] sm:flex-row">
          <div className="w-full">
            {futureEvents.map((event, index) => (
              <div className="mb-4 w-full" key={index}>
                <ActiveEventsCard
                  event={event}
                  setDeleteEventModal={setDeleteEventModal}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(ActiveEvents)
