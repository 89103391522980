import { Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'
import { CostIcon } from '../../../assets/icons'
import NewEventCostModal from './NewEventCostModal'

const NewEventCost = ({ form, costPerSquare }) => {
  const [opened, setOpened] = useState(false)

  const onClick = () => {
    setOpened(true)
  }

  return (
    <React.Fragment>
      <NewEventCostModal opened={opened} onClose={() => setOpened(false)} form={form} />

      <div>
        <Tooltip label="Insert your costs">
          <div
            className={`bg-off-white p-3 flex flex-row justify-between mt-[12px] rounded-md border-1`}
            onClick={onClick}
          >
            <div className='flex items-center'>
              <CostIcon className="w-[24px] h-[24px]" fill={'#5B4ABC'} />
              <span className={`ml-[8px] text-primary font-semibold ${!costPerSquare && 'hover:underline cursor-pointer'}`}>
                {costPerSquare ? `$${costPerSquare} per guest` : '+ Cost per guest'}
              </span>
            </div>

            {costPerSquare && <span className={` text-primary underline cursor-pointer`}>
              Edit payment options
            </span>}
          </div>
        </Tooltip>
      </div>
    </React.Fragment>
  )
}

export default NewEventCost
