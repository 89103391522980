import React from 'react'
import { getSpotifyTrackData } from '../Spotify'
import { getAppleMusicTrackData } from '../AppleMusic'

const prepareAppleMusicData = (track) => {
  const defStore = track.href.match(/v1\/catalog\/(.*)\/songs/i)
  const store = (defStore || []).length > 1 ? defStore[1] : 'us'
  const catalogs = track.relationships?.catalog?.data || []
  const artists = track.relationships?.artists?.data || []
  const isrc = track.attributes?.isrc
  if (!isrc && catalogs.length > 0) {
    const catalogTrack = catalogs[0]
    return {
      id: catalogTrack.id,
      type: catalogTrack.type,
      href: catalogTrack.href,
      isrc: catalogTrack.attributes?.isrc,
      name: catalogTrack.attributes?.name,
      genres: catalogTrack.attributes?.genreNames,
      albumName: catalogTrack.attributes?.albumName,
      popularity: 0,
      artworkURL: catalogTrack.attributes?.artwork?.url.replace(
        '{w}x{h}',
        '100x100'
      ),
      artistName: catalogTrack.attributes?.artistName,
      artists: artists.map((x) => x.id),
      releaseDate: catalogTrack.attributes?.releaseDate || null,
      provider: 'apple_music',
      store
    }
  }
  return {
    id: track.id,
    type: track.type,
    href: track.href,
    isrc,
    withError: !isrc,
    name: track.attributes?.name,
    genres: track.attributes?.genreNames,
    albumName: track.attributes?.albumName,
    popularity: 0,
    artworkURL: track.attributes?.artwork?.url.replace('{w}x{h}', '100x100'),
    artistName: track.attributes?.artistName,
    artists: artists.map((x) => x.id),
    releaseDate: track.attributes?.releaseDate || null,
    provider: 'apple_music',
    store
  }
}

const prepareSpotifyData = (track) => {
  const defStore = track.external_ids.isrc.substring(0, 2)
  const store = (track.available_markets || []).includes(defStore)
    ? defStore.toLowerCase()
    : 'us'
  return {
    id: track.id,
    type: 'track',
    href: track.href,
    isrc: track.external_ids.isrc,
    name: track.name,
    genres: [],
    albumName: track.album.name,
    popularity: track.popularity,
    artworkURL:
      (track.images || []).length > 1
        ? track.images[0].url
        : (track.album?.images || []).length > 1
          ? track.album.images[0].url
          : null,
    artistName: track.artists.map((x) => x.name).join(' & '),
    artists: track.artists.map((x) => x.id),
    releaseDate: track.album?.release_date || null,
    provider: 'spotify',
    store
  }
}

const prepareTrack = async (from, track, spotifyToken) => {
  if (from === 'spotify') {
    // Fetch extra data from Apple Music

    const spotifyTrack = prepareSpotifyData(track)
    // const appleMusicData = await getAppleMusicTrackData(
    //   spotifyTrack.isrc,
    //   spotifyTrack.store
    // )

    // if (!!appleMusicData) {
    //   const appleMusicTrack = prepareAppleMusicData(appleMusicData)
    //   // console.log('appleMusicTrack', appleMusicData, appleMusicTrack)
    //   return {
    //     ...spotifyTrack,
    //     spotifyID: spotifyTrack.id,
    //     appleMusicID: appleMusicTrack.id,
    //     appleMusicHREF: appleMusicTrack.href,
    //     genres: appleMusicTrack.genres,
    //     releaseDate: spotifyTrack.releaseDate || appleMusicTrack.releaseDate,
    //     appleMusic: appleMusicTrack
    //   }
    // }
    spotifyTrack.spotifyID = spotifyTrack.id
    // spotifyTrack.withError = true
    // console.log('appleMusicTrack', appleMusicData)
    return spotifyTrack
  }

  if (from === 'apple_music') {
    // Fetch extra data from Spotify

    const appleMusicTrack = prepareAppleMusicData(track)

    if (!appleMusicTrack.isrc) {
      return null
    }

    const spotifyData = await getSpotifyTrackData(
      appleMusicTrack.isrc,
      spotifyToken
    )
    if (appleMusicTrack.id !== '1591280474') {
      if (!!spotifyData && spotifyData !== '401') {
        const spotifyTrack = prepareSpotifyData(spotifyData)
        // console.log('spotifyTrack', spotifyData, spotifyTrack)
        return {
          ...appleMusicTrack,
          appleMusicID: appleMusicTrack.id,
          spotifyID: spotifyTrack.id,
          spotifyHREF: spotifyTrack.href,
          popularity: spotifyTrack.popularity
        }
      }
    }
    appleMusicTrack.withError = true
    // console.log('spotifyTrack', spotifyData)
    return appleMusicTrack
  }

  return null
}

export { prepareTrack }
