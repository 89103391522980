import React, { memo, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const NoStyle = ({ register, form, hasError, className, ...props }) => {
  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState('')

  const handleChange = (event) => {
    form?.setValue(register.name, event.target.value)
    setInputValue(event.target.value)

  }

  return (
    <div className={twMerge('relative w-full')}>
      <input
        type="text"
        ref={(e) => {
          register.ref(e)
          inputRef.current = e
        }}
        {...register}
        onChange={handleChange}
        className={twMerge("leading-none outline-none w-full", className)}
        {...props}
      />

      {hasError && (
        <div
          className="absolute flex justify-center items-center h-1 bg-[red] rounded-full"
        />
      )}
    </div>
  )
}

export default memo(NoStyle)
