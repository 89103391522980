import React, { useContext, useEffect, useState } from 'react'
import { getSettings } from '../firebase'
import {
  getSpotifyRecentlyPlayed,
  refreshOtherSpotifyToken
} from '../services/Spotify'

const TokensContext = React.createContext({
  general: null
})

const TokensProvider = ({ children }) => {
  const [general, setGeneral] = useState(null)

  const getSettingsTokens = async () => {
    const settingsSnap = await getSettings()
    const { spotifyToken, spotifyRefreshToken } = settingsSnap?.val() || {}
    try {
      console.log('getSettingsTokens')
      await getSpotifyRecentlyPlayed(spotifyToken, 0, 1)
      setGeneral({ spotifyToken, spotifyRefreshToken })
    } catch (e) {
      const spotifyData = await refreshOtherSpotifyToken(spotifyRefreshToken)
      console.log('TokensProvider success')
      if (spotifyData) {
        setGeneral({
          spotifyToken: spotifyData.accessToken,
          spotifyRefreshToken
        })
      }
    }
  }

  useEffect(() => {
    getSettingsTokens().then(() => null)
  }, [])

  const state = {
    general
  }

  return (
    <TokensContext.Provider value={state}>{children}</TokensContext.Provider>
  )
}

export const useTokens = () => useContext(TokensContext)

export default TokensProvider
