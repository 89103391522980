import React from 'react'
import { Tooltip } from '@chakra-ui/react'

const NewEventHostName = ({ form }) => {
  return (
    <div className='flex items-center w-full'>
      <span className="mr-1 cursor-default">
        Hosted by{' '}
      </span>
      <Tooltip label='Type your event host name'>
        <input
          placeholder='Joe Doe'
          className="bg-transparent outline-none text-primary"
          {...form.register('hostName')}
          autoComplete="off"
        />
      </Tooltip>
    </div>
  )
}

export default NewEventHostName
