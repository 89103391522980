import React from 'react'
import { twMerge } from 'tailwind-merge'

const DottedLine = ({ color, horizontal, solid, className }) => {
  const dottedStyle = solid ? {
    backgroundColor: color,
  } : {
    backgroundImage: 'radial-gradient(' + color + ' 1px, transparent 1px)',
    backgroundSize: horizontal ? '5px 2px' : '3px 10px'
  }

  return <div className={twMerge(horizontal ? "w-full h-[1.5px]" : "w-[1.5px] h-full", className)} style={dottedStyle} />
}

export default DottedLine