export const countryCodes = [
  { label: 'United States', value: '+1' },
  { label: 'Argentina', value: '+54' },
  { label: 'Aruba', value: '+297' },
  { label: 'Australia', value: '+61' },
  { label: 'Austria', value: '+43' },
  { label: 'Belgium', value: '+32' },
  { label: 'Brazil', value: '+55' },
  { label: 'Canada', value: '+1' },
  { label: 'Chile', value: '+56' },
  { label: 'Colombia', value: '+57' },
  { label: 'Costa Rica', value: '+506' },
  { label: 'Croatia', value: '+385' },
  { label: 'Cyprus', value: '+357' },
  { label: 'Denmark', value: '+45' },
  { label: 'Dominican Republic', value: '+1-809' },
  { label: 'Egypt', value: '+20' },
  { label: 'El Salvador', value: '+503' },
  { label: 'France', value: '+33' },
  { label: 'Germany', value: '+49' },
  { label: 'Greece', value: '+30' },
  { label: 'Honduras', value: '+504' },
  { label: 'Hungary', value: '+36' },
  { label: 'India', value: '+91' },
  { label: 'Ireland', value: '+353' },
  { label: 'Italy', value: '+39' },
  { label: 'Japan', value: '+81' },
  { label: 'Liechtenstein', value: '+423' },
  { label: 'Lithuania', value: '+370' },
  { label: 'Mexico', value: '+52' },
  { label: 'Netherlands', value: '+31' },
  { label: 'New Zealand', value: '+64' },
  { label: 'Nicaragua', value: '+505' },
  { label: 'Paraguay', value: '+595' },
  { label: 'Peru', value: '+51' },
  { label: 'Poland', value: '+48' },
  { label: 'Portugal', value: '+351' },
  { label: 'Puerto Rico', value: '+1' },
  { label: 'Romania', value: '+40' },
  { label: 'Saudi Arabia', value: '+966' },
  { label: 'Singapore', value: '+65' },
  { label: 'South Africa', value: '+27' },
  { label: 'Spain', value: '+34' },
  { label: 'Sweden', value: '+46' },
  { label: 'Switzerland', value: '+41' },
  { label: 'Taiwan', value: '+886' },
  { label: 'Thailand', value: '+66' },
  // { label: 'United Kingdom', value: '+44' },
  { label: 'Venezuela', value: '+58' }
]

export const mappedCountryCodes = countryCodes.map((c) => ({
  label: `${c?.label} (${c?.value})`,
  value: c?.value
}))
