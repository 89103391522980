import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useWatch } from 'react-hook-form'

const Icon = ({
  placeholder,
  className,
  iconRight,
  iconLeft,
  register,
  hasError,
  form
}) => {
  const spanRef = useRef(null)
  const wrapper = useRef(null)
  const [isFocused, setIsFocused] = useState(false)

  const inputValue = useWatch({
    name: register.name,
    control: form?.control
  })

  const handlePlaceholderAnimation = (shouldAnimate) => {
    if (!spanRef.current) return

    if (shouldAnimate) {
      wrapper.current.style.borderWidth = '2px'
      spanRef.current.style.top = '-3px'
      spanRef.current.style.fontSize = '12px'
      spanRef.current.style.background = '#fff'
      spanRef.current.style.padding = '0px 4px'
      return
    }

    if (!inputValue && !shouldAnimate) {
      spanRef.current.style.top = '50%'
      spanRef.current.style.fontSize = '16px'
      spanRef.current.style.padding = '0px'
    }

    wrapper.current.style.borderWidth = '1px'
  }

  const handleSpanLocation = useCallback(() => {
    if (!iconLeft) {
      spanRef.current.style.left = '12px'
    }
  }, [iconLeft])

  useEffect(() => {
    handleSpanLocation()
  }, [handleSpanLocation])

  return (
    <div
      ref={wrapper}
      className={twMerge(
        `relative px-2 min-w-24 h-h-input flex  ${hasError ? (isFocused ? 'border-primary' : 'border-error') : isFocused ? 'border-primary' : 'border-input-border'} border-1 rounded-input bg-transparent items-center`,
        className
      )}
    >
      {iconLeft}
      <input
        type="text"
        className="w-full ml-1 mr-3 outline-none border-input-border bg-transparent"
        {...register}
        onFocus={() => {
          setIsFocused(true)
          handlePlaceholderAnimation(true)
          spanRef.current?.classList.add('text-primary')
          spanRef.current?.classList.add('font-bold')
        }}
        onBlur={() => {
          setIsFocused(false)
          handlePlaceholderAnimation(false)
          spanRef.current?.classList.remove('text-primary')
          spanRef.current?.classList.remove('font-bold')
        }}
        autoComplete="off"
      />
      <span
        ref={spanRef}
        className="absolute top-1/2 -translate-y-1/2 text-placeholder pointer-events-none left-8 ease-linear duration-150"
      >
        {placeholder}
      </span>
      {iconRight}
    </div>
  )
}
export default memo(Icon)
