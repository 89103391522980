import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import EmailDistributionListItem from './EmailDistributionListItem'
import moment from 'moment'

const EmailDistributionList = ({ items, loading }) => {
  if (loading) {
    return (
      <div className="flex flex-1 items-center justify-center mt-[15px]">
        <ClipLoader color="#5B4ABC" />
      </div>
    )
  }

  if (items?.length === 0) {
    return (
      <div className="flex flex-1 items-center justify-center mt-[15px]">
        <span>No items found</span>
      </div>
    )
  }

  return (
    <div className="border-1 rounded-input flex items-center w-full">
      <table className="overflow-hidden w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-3 w-[20%] rounded-tl-input">
              <div className="w-full flex ml-3 md:ml-4 font-semibold sm:text-[16px] text-[14px]">Name</div>
            </th>
            <th className="py-3 w-[30%]">
              <div className="w-full flex items-center font-semibold sm:text-[16px] text-[14px]">Email recipient</div>
            </th>
          </tr>
        </thead>

        <tbody className="text-center">
          {items?.map((item, index) => (
            <EmailDistributionListItem
              key={index}
              item={item}
            />
          )).reverse()}
        </tbody>
      </table>
    </div>
  )
}

export default EmailDistributionList
