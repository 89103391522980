import { background } from '@chakra-ui/react'
import { BorderBottom } from '@mui/icons-material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  cssVariables: true,
  palette: {
    primary: {
      light: '#4f46e5',
      main: '#4f46e5',
      dark: '#e0e7ff'
    },
    secondary: {
      light: '#888685',
      main: '#888685',
      dark: '#424867'
    },
    mode: 'light'
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        fullWidth: true
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0
          }
        },
        inputRoot: {
          // padding: 0,
          borderRadius: '8px !important'
        }
      }
    }
    // MuiFormControl: {
    //   styleOverrides: {
    //     root: {
    //       // paddingLeft: '36px !important',
    //       width: '100% !important',
    //       cursor: 'pointer !important',
    //       '& .MuiOutlinedInput-notchedOutline': {
    //         border: 0
    //       }
    //       // '& .MuiOutlinedInput-input': {
    //       //   borderBottom: '1px solid #CCC',
    //       //   paddingLeft: 0,
    //       //   paddRight: 0,
    //       //   paddingTop: 0,
    //       //   paddingBottom: '4px'
    //       // }
    //     }
    //   }
    // }
  }
})

// MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd Mui-readOnly MuiInputBase-readOnly css-ysu2ya-MuiInputBase-root-MuiOutlinedInput-root

// MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd Mui-readOnly MuiInputBase-readOnly css-nxo287-MuiInputBase-input-MuiOutlinedInput-input

const MuiThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default MuiThemeProvider
