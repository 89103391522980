import * as React from 'react'

function CoteriSymbol({ className, fill, ...props }) {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 214 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        d="M107 .46C48.16.46.46 48.16.46 107S48.16 213.54 107 213.54 213.54 165.84 213.54 107 165.84.46 107 .46zm0 160.69c-12.48 0-22.59-10.11-22.59-22.59s10.11-22.59 22.59-22.59 22.59 10.11 22.59 22.59-10.11 22.59-22.59 22.59zm0-63.12c-12.48 0-22.59-10.11-22.59-22.59S94.52 52.85 107 52.85s22.59 10.11 22.59 22.59S119.48 98.03 107 98.03zM188.85 203.1a9.729 9.729 0 00-6.92-2.87c-2.61 0-5.07 1.02-6.92 2.87a9.729 9.729 0 00-2.87 6.92c0 2.61 1.02 5.07 2.87 6.92a9.729 9.729 0 006.92 2.87c2.61 0 5.07-1.02 6.92-2.87a9.729 9.729 0 002.87-6.92c0-2.61-1.02-5.07-2.87-6.92zm-6.92 15.09c-4.51 0-8.17-3.67-8.17-8.17s3.67-8.17 8.17-8.17 8.17 3.67 8.17 8.17-3.67 8.17-8.17 8.17z"
        fill={fill || "#DD94F7"}
      />
      <path
        d="M184.18 210.27c1.27-.55 1.91-1.44 1.91-2.69 0-.94-.41-1.68-1.22-2.23s-1.91-.82-3.3-.82c-.93 0-1.98.1-3.15.3v10.65h1.54v-4.71h1.96c1.58 0 2.59 1.57 3.03 4.71h1.54c-.26-1.64-.56-2.82-.89-3.55-.33-.73-.8-1.28-1.41-1.66h-.01zm-2.35-.68h-1.88v-3.73c.42-.05.96-.07 1.61-.07 2.02 0 3.03.63 3.03 1.89 0 1.26-.92 1.91-2.76 1.91z"
        fill={fill || "#DD94F7"}
      />
    </svg>
  )
}

export default CoteriSymbol
