import * as React from "react"

function XIcon({ fill, ...props }) {
  return (
    <svg
      width={256}
      height={256}
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M205.66 194.34a8.004 8.004 0 01-11.32 11.32L128 139.31l-66.34 66.35a8.006 8.006 0 11-11.32-11.32L116.69 128 50.34 61.66a8.004 8.004 0 1111.32-11.32L128 116.69l66.34-66.35a8.004 8.004 0 1111.32 11.32L139.31 128l66.35 66.34z"
        fill={fill}
      />
    </svg>
  )
}

export default XIcon
