import * as React from 'react'

function AppleMusic({ fill, ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_380_1197)">
        <path
          d="M23.996 6.125a9.17 9.17 0 00-.239-2.191c-.316-1.309-1.062-2.309-2.18-3.043A5.031 5.031 0 0019.7.164c-.516-.09-1.038-.14-1.562-.148-.04-.004-.082-.012-.125-.016H5.988c-.152.012-.304.02-.456.027-.747.043-1.488.12-2.192.402C2.004.956 1.04 1.878.477 3.206c-.195.45-.294.926-.363 1.41a9.87 9.87 0 00-.102 1.18c0 .031-.007.062-.007.094v12.223l.023.422c.051.817.156 1.625.5 2.375.65 1.418 1.739 2.351 3.235 2.8.418.13.856.188 1.293.227.555.055 1.11.062 1.664.062h11.031c.524 0 1.047-.035 1.57-.101.825-.106 1.598-.348 2.298-.808a5.056 5.056 0 001.879-2.208c.187-.422.293-.87.371-1.324.113-.676.137-1.36.137-2.043-.004-3.798 0-7.594-.004-11.391l-.006.001zm-6.422 3.988v5.711c0 .418-.059.828-.246 1.207-.29.59-.758.96-1.387 1.14-.349.1-.708.158-1.07.172a1.86 1.86 0 01-1.942-1.535 1.877 1.877 0 011.039-2.023c.32-.156.668-.25 1.015-.32.379-.082.758-.156 1.133-.243.277-.062.456-.23.511-.516a.786.786 0 00.02-.191V8.07a.747.747 0 00-.027-.184c-.04-.152-.149-.242-.305-.234-.16.007-.316.035-.472.066-.762.149-1.524.301-2.282.457l-3.699.746c-.016.004-.035.012-.05.012-.278.078-.376.204-.388.492-.004.043 0 .086 0 .13-.004 2.601 0 5.203-.004 7.804 0 .422-.047.836-.214 1.226-.278.64-.77 1.043-1.433 1.235-.351.102-.714.16-1.079.171-.957.035-1.754-.601-1.918-1.543-.14-.812.23-1.687 1.152-2.078.36-.148.73-.23 1.11-.308l.86-.176c.382-.081.581-.323.6-.714V6.14c0-.125.016-.25.043-.371.07-.285.274-.45.547-.516.254-.067.516-.113.773-.168.735-.149 1.465-.297 2.2-.442l2.269-.461c.672-.133 1.34-.27 2.011-.402.22-.043.442-.09.664-.106.308-.027.524.168.555.481.008.074.012.148.012.223v5.73l.002.005z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_380_1197">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AppleMusic
