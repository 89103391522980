const getData = (type) => {
  return typeof window !== 'undefined'
    ? window.localStorage.getItem(type)
    : null
}

const storeData = (type, value) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(type, value)
  }
}

const storeListData = (type, value) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(type, JSON.stringify(value))
  }
}

const removeData = (type) => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(type)
  }
}

const getTempProfile = () => {
  const data = getData('profile')
  return data && data !== 'undefined' && typeof data !== 'undefined'
    ? JSON.parse(data)
    : null
}

const updateTempProfile = (value) => {
  const oldVal = getTempProfile() ?? {}
  storeData('profile', JSON.stringify({ ...oldVal, ...value }))
}

const removeTempProfile = () => {
  removeData('profile')
}

const getEventData = () => {
  const data = getData('event_details')
  return data && data !== 'undefined' && typeof data !== 'undefined'
    ? JSON.parse(data)
    : null
}

const setEventData = (value) => {
  storeListData('event_details', value)
}

const removeEventData = () => {
  removeData('event_details')
}

const getGuests = () => {
  const data = getData('guests')
  return data && data !== 'undefined' && typeof data !== 'undefined'
    ? JSON.parse(data)
    : null
}

const setGuests = (value) => {
  storeListData('guests', value)
}

const removeGuests = () => {
  removeData('guests')
}

const getSavedPhone = () => {
  const data = getData('phoneData')
  return data && data !== 'undefined' && typeof data !== 'undefined'
    ? JSON.parse(data)
    : null
}

const setSavedPhone = (value) => {
  storeListData('phoneData', value)
}

const getSettingsData = () => {
  const data = getData('settingsData')
  return data && data !== 'undefined' && typeof data !== 'undefined'
    ? JSON.parse(data)
    : null
}

const setSettingsData = (value) => {
  storeListData('settingsData', value)
}

const removeSettingsData = () => {
  removeData('settingsData')
}

const getPaymentData = () => {
  const data = getData('payment_details')
  return data && data !== 'undefined' && typeof data !== 'undefined'
    ? JSON.parse(data)
    : null
}

const setPaymentData = (value) => {
  storeListData('payment_details', value)
}

const removePaymentData = () => {
  removeData('payment_details')
}

export {
  getTempProfile,
  updateTempProfile,
  removeTempProfile,
  getEventData,
  getGuests,
  setEventData,
  setGuests,
  removeEventData,
  removeGuests,
  getSavedPhone,
  setSavedPhone,
  getSettingsData,
  setSettingsData,
  removeSettingsData,
  getPaymentData,
  setPaymentData,
  removePaymentData
}
