import React from 'react'
import { Button, Flex, Text } from '@chakra-ui/react'
import { ReactComponent as YesSVG } from '../assets/icons/yes.svg'
import { ReactComponent as NoSVG } from '../assets/icons/no.svg'
import { ReactComponent as NoResponseSVG } from '../assets/icons/no-response.svg'

const Guest = ({ item, onRemove, isHost }) => {
  const { name, email, response } = item

  return (
    <Flex
      h="80px"
      px="16px"
      mb="10px"
      bg="white"
      borderWidth="1px"
      borderColor="gray.200"
      position="relative"
      borderRadius="16px"
    >
      <Flex
        px="12px"
        flex="1"
        direction="column"
        justify="center"
        overflow="hidden"
      >
        <Text
          fontSize="17px"
          fontWeight="600"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {name || ''}
        </Text>
        {isHost && (
          <Text
            fontSize="15px"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {email || ''}
          </Text>
        )}
      </Flex>
      <Flex flexShrink="0" align="center">
        {!!onRemove && (
          <Button
            mt="2px"
            mr="5px"
            h="36px"
            fontSize={{ base: '13px', md: '15px' }}
            fontWeight="500"
            colorScheme="pink"
            borderWidth="1px"
            borderColor="rgba(0, 0, 0, 0.01)"
            borderRadius="32px"
            color="white"
            onClick={() => onRemove(item)}
          >
            Remove
          </Button>
        )}
        {!response && !onRemove && (
          <Flex mr="10px" align="center" justify="center">
            <Flex mr="12px" align="center" justify="center">
              <Text color="#999" fontSize="16px" fontWeight="600">
                No response
              </Text>
            </Flex>
            <NoResponseSVG />
          </Flex>
        )}
        {!!response && response === 'yes' && !onRemove && (
          <Flex mr="10px" align="center" justify="center">            
           <span>Going 🎉</span>
          </Flex>
        )}
        {!!response && response === 'no' && !onRemove && (
          <Flex mr="10px" align="center" justify="center">
            <span>Unable to attend 🙁</span>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default Guest
