export const selectedSquares = [
  { row: 0, col: 4, uid: 'me', initials: 'YOU' },
  { row: 1, col: 2, uid: 'me', initials: 'YOU' },
  { row: 3, col: 6, uid: 'me', initials: 'YOU' },
  { row: 0, col: 1, uid: 'me', initials: 'YOU' },
  { row: 4, col: 1, uid: 'user1', initials: 'JRP' },
  { row: 2, col: 5, uid: 'user1', initials: 'JRP' },
  { row: 3, col: 3, uid: 'user1', initials: 'JRP' },
  { row: 5, col: 0, uid: 'user1', initials: 'JRP' },
  { row: 6, col: 2, uid: 'user2', initials: 'MB' },
  { row: 7, col: 4, uid: 'user2', initials: 'MB' },
  { row: 4, col: 7, uid: 'user2', initials: 'MB' },
  { row: 8, col: 3, uid: 'user3', initials: 'EM' },
  { row: 5, col: 6, uid: 'user3', initials: 'EM' },
  { row: 9, col: 1, uid: 'user3', initials: 'EM' },
  { row: 2, col: 8, uid: 'user4', initials: 'VJO' },
  { row: 1, col: 7, uid: 'user4', initials: 'VJO' },
  { row: 3, col: 9, uid: 'user4', initials: 'VJO' },
  { row: 6, col: 5, uid: 'user5', initials: 'MR' },
  { row: 7, col: 6, uid: 'user5', initials: 'MR' },
  { row: 0, col: 9, uid: 'user5', initials: 'MR' }
];

export const allSquares = [
  { row: 0, col: 4, uid: 'me', initials: 'YOU' },
  { row: 1, col: 2, uid: 'me', initials: 'YOU' },
  { row: 3, col: 6, uid: 'me', initials: 'YOU' },
  { row: 0, col: 1, uid: 'me', initials: 'YOU' },
  { row: 4, col: 1, uid: 'user1', initials: 'JRP' },
  { row: 2, col: 5, uid: 'user1', initials: 'JRP' },
  { row: 3, col: 3, uid: 'user1', initials: 'JRP' },
  { row: 5, col: 0, uid: 'user1', initials: 'JRP' },
  { row: 6, col: 2, uid: 'user2', initials: 'MB' },
  { row: 7, col: 4, uid: 'user2', initials: 'MB' },
  { row: 4, col: 7, uid: 'user2', initials: 'MB' },
  { row: 8, col: 3, uid: 'user3', initials: 'EM' },
  { row: 5, col: 6, uid: 'user3', initials: 'EM' },
  { row: 9, col: 1, uid: 'user3', initials: 'EM' },
  { row: 2, col: 8, uid: 'user4', initials: 'VJO' },
  { row: 1, col: 7, uid: 'user4', initials: 'VJO' },
  { row: 3, col: 9, uid: 'user4', initials: 'VJO' },
  { row: 6, col: 5, uid: 'user5', initials: 'MR' },
  { row: 7, col: 6, uid: 'user5', initials: 'MR' },
  { row: 0, col: 9, uid: 'user5', initials: 'MR' },
  { row: 0, col: 0, uid: 'user6', initials: 'HIJ' },
  { row: 2, col: 0, uid: 'user6', initials: 'HIJ' },
  { row: 4, col: 0, uid: 'user6', initials: 'HIJ' },
  { row: 1, col: 1, uid: 'user6', initials: 'HIJ' },
  { row: 5, col: 2, uid: 'user7', initials: 'KLM' },
  { row: 6, col: 1, uid: 'user7', initials: 'KLM' },
  { row: 8, col: 2, uid: 'user7', initials: 'KLM' },
  { row: 7, col: 8, uid: 'user7', initials: 'KLM' },
  { row: 9, col: 3, uid: 'user8', initials: 'NOP' },
  { row: 3, col: 4, uid: 'user8', initials: 'NOP' },
  { row: 5, col: 9, uid: 'user8', initials: 'NOP' },
  { row: 2, col: 6, uid: 'user8', initials: 'NOP' },
  { row: 1, col: 6, uid: 'user9', initials: 'QRS' },
  { row: 6, col: 7, uid: 'user9', initials: 'QRS' },
  { row: 8, col: 6, uid: 'user9', initials: 'QRS' },
  { row: 0, col: 6, uid: 'user9', initials: 'QRS' },
  { row: 3, col: 8, uid: 'user10', initials: 'TUV' },
  { row: 4, col: 5, uid: 'user10', initials: 'TUV' },
  { row: 5, col: 1, uid: 'user10', initials: 'TUV' },
  { row: 9, col: 0, uid: 'user10', initials: 'TUV' },
  { row: 2, col: 2, uid: 'user11', initials: 'WXY' },
  { row: 0, col: 8, uid: 'user11', initials: 'WXY' },
  { row: 8, col: 7, uid: 'user11', initials: 'WXY' },
  { row: 7, col: 1, uid: 'user11', initials: 'WXY' },
  { row: 4, col: 9, uid: 'user12', initials: 'ZAB' },
  { row: 1, col: 4, uid: 'user12', initials: 'ZAB' },
  { row: 6, col: 3, uid: 'user12', initials: 'ZAB' },
  { row: 9, col: 8, uid: 'user12', initials: 'ZAB' },
  { row: 2, col: 4, uid: 'user13', initials: 'CDE' },
  { row: 8, col: 0, uid: 'user13', initials: 'CDE' },
  { row: 5, col: 8, uid: 'user13', initials: 'CDE' },
  { row: 7, col: 2, uid: 'user13', initials: 'CDE' },
  { row: 0, col: 2, uid: 'user14', initials: 'FGH' },
  { row: 4, col: 8, uid: 'user14', initials: 'FGH' },
  { row: 9, col: 7, uid: 'user14', initials: 'FGH' },
  { row: 3, col: 0, uid: 'user14', initials: 'FGH' },
  { row: 1, col: 9, uid: 'user15', initials: 'IJO' },
  { row: 2, col: 1, uid: 'user15', initials: 'IJO' },
  { row: 5, col: 7, uid: 'user15', initials: 'IJO' },
  { row: 7, col: 7, uid: 'user15', initials: 'IJO' },
  { row: 6, col: 0, uid: 'user16', initials: 'MB' },
  { row: 3, col: 2, uid: 'user16', initials: 'MB' },
  { row: 8, col: 5, uid: 'user16', initials: 'MB' },
  { row: 3, col: 5, uid: 'user16', initials: 'MB' },
  { row: 1, col: 8, uid: 'user16', initials: 'MB' },
  { row: 9, col: 6, uid: 'user17', initials: 'OPQ' },
  { row: 4, col: 3, uid: 'user17', initials: 'OPQ' },
  { row: 6, col: 8, uid: 'user17', initials: 'OPQ' },
  { row: 2, col: 3, uid: 'user17', initials: 'OPQ' },
  { row: 0, col: 3, uid: 'user18', initials: 'RST' },
  { row: 5, col: 3, uid: 'user18', initials: 'RST' },
  { row: 7, col: 3, uid: 'user18', initials: 'RST' },
  { row: 8, col: 4, uid: 'user18', initials: 'RST' },
  { row: 9, col: 9, uid: 'user19', initials: 'UVW' },
  { row: 3, col: 7, uid: 'user19', initials: 'UVW' },
  { row: 1, col: 3, uid: 'user19', initials: 'UVW' },
  { row: 7, col: 0, uid: 'user19', initials: 'UVW' },
  { row: 0, col: 5, uid: 'user20', initials: 'JRP' },
  { row: 1, col: 0, uid: 'user20', initials: 'JRP' },
  { row: 6, col: 6, uid: 'user20', initials: 'JRP' },
  { row: 2, col: 7, uid: 'user20', initials: 'JRP' },
  { row: 4, col: 4, uid: 'user20', initials: 'JRP' },
   { row: 9, col: 4, uid: 'user21', initials: 'ABC'},
   { row: 8, col: 1, uid: 'user21', initials: 'ABC' },
   { row: 7, col: 9, uid: 'user21', initials: 'ABC' },
   { row: 6, col: 4, uid: 'user22', initials: 'MR' },
   { row: 5, col: 5, uid: 'user22', initials: 'MR' },
   { row: 4, col: 6, uid: 'user22', initials: 'MR' },
   { row: 3, col: 1, uid: 'user23', initials: 'GHI' },
   { row: 2, col: 9, uid: 'user23', initials: 'GHI' },
   { row: 1, col: 5, uid: 'user23', initials: 'GHI' },
   { row: 0, col: 7, uid: 'user24', initials: 'VJOL' },
   { row: 9, col: 5, uid: 'user24', initials: 'VJOL' },
   { row: 8, col: 8, uid: 'user24', initials: 'VJOL' },
   { row: 7, col: 5, uid: 'user25', initials: 'MNO' },
   { row: 6, col: 9, uid: 'user25', initials: 'MNO' },
   { row: 5, col: 4, uid: 'user25', initials: 'MNO' },
   { row: 4, col: 2, uid: 'user26', initials: 'PQR' },
   { row: 3, col: 9, uid: 'user26', initials: 'PQR' },
   { row: 2, col: 7, uid: 'user26', initials: 'PQR' },
   { row: 1, col: 8, uid: 'user27', initials: 'STU' },
   { row: 8, col: 9, uid: 'user27', initials: 'STU' },
   { row: 0, col: 6, uid: 'user27', initials: 'STU' },
   { row: 9, col: 2, uid: 'user27', initials: 'STU' }
];

export const selectedSquaresWithWinner = [
  { row: 0, col: 4, uid: 'me', initials: 'YOU' },
  { row: 1, col: 2, uid: 'me', initials: 'YOU' },
  { row: 3, col: 6, uid: 'me', initials: 'YOU' },
  { row: 0, col: 1, uid: 'me', initials: 'YOU' },
  { row: 4, col: 1, uid: 'user1', initials: 'JRP' },
  { row: 2, col: 5, uid: 'user1', initials: 'JRP' },
  { row: 3, col: 3, uid: 'user1', initials: 'JRP' },
  { row: 5, col: 0, uid: 'user1', initials: 'JRP' },
  { row: 6, col: 2, uid: 'user2', initials: 'MB' },
  { row: 7, col: 4, uid: 'user2', initials: 'MB' },
  { row: 4, col: 7, uid: 'user2', initials: 'MB' },
  { row: 8, col: 3, uid: 'user3', initials: 'EM' },
  { row: 5, col: 6, uid: 'user3', initials: 'EM' },
  { row: 9, col: 1, uid: 'user3', initials: 'EM' },
  { row: 2, col: 8, uid: 'user4', initials: 'VJO' },
  { row: 1, col: 7, uid: 'user4', initials: 'VJO' },
  { row: 3, col: 9, uid: 'user4', initials: 'VJO' },
  { row: 6, col: 5, uid: 'user5', initials: 'MR' },
  { row: 7, col: 6, uid: 'user5', initials: 'MR' },
  { row: 0, col: 9, uid: 'user5', initials: 'MR' },
  { row: 0, col: 0, uid: 'user6', initials: 'HIJ' },
  { row: 2, col: 0, uid: 'user6', initials: 'HIJ' },
  { row: 4, col: 0, uid: 'user6', initials: 'HIJ' },
  { row: 1, col: 1, uid: 'user6', initials: 'HIJ' },
  { row: 5, col: 2, uid: 'user7', initials: 'KLM' },
  { row: 6, col: 1, uid: 'user7', initials: 'KLM' },
  { row: 8, col: 2, uid: 'user7', initials: 'KLM' },
  { row: 7, col: 8, uid: 'user7', initials: 'KLM', winner: '1st' },
  { row: 9, col: 3, uid: 'user8', initials: 'NOP' },
  { row: 3, col: 4, uid: 'user8', initials: 'NOP' },
  { row: 5, col: 9, uid: 'user8', initials: 'NOP' },
  { row: 2, col: 6, uid: 'user8', initials: 'NOP' },
  { row: 1, col: 6, uid: 'user9', initials: 'QRS' },
  { row: 6, col: 7, uid: 'user9', initials: 'QRS' },
  { row: 8, col: 6, uid: 'user9', initials: 'QRS' },
  { row: 0, col: 6, uid: 'user9', initials: 'QRS' },
  { row: 3, col: 8, uid: 'user10', initials: 'TUV' },
  { row: 4, col: 5, uid: 'user10', initials: 'TUV' },
  { row: 5, col: 1, uid: 'user10', initials: 'TUV' },
  { row: 9, col: 0, uid: 'user10', initials: 'TUV' },
  { row: 2, col: 2, uid: 'user11', initials: 'WXY' },
  { row: 0, col: 8, uid: 'user11', initials: 'WXY' },
  { row: 8, col: 7, uid: 'user11', initials: 'WXY' },
  { row: 7, col: 1, uid: 'user11', initials: 'WXY' },
  { row: 4, col: 9, uid: 'user12', initials: 'ZAB' },
  { row: 1, col: 4, uid: 'user12', initials: 'ZAB' },
  { row: 6, col: 3, uid: 'user12', initials: 'ZAB' },
  { row: 9, col: 8, uid: 'user12', initials: 'ZAB' },
  { row: 2, col: 4, uid: 'user13', initials: 'CDE' },
  { row: 8, col: 0, uid: 'user13', initials: 'CDE' },
  { row: 5, col: 8, uid: 'user13', initials: 'CDE' },
  { row: 7, col: 2, uid: 'user13', initials: 'CDE' },
  { row: 0, col: 2, uid: 'user14', initials: 'FGH' },
  { row: 4, col: 8, uid: 'user14', initials: 'FGH' },
  { row: 9, col: 7, uid: 'user14', initials: 'FGH' },
  { row: 3, col: 0, uid: 'user14', initials: 'FGH' },
  { row: 1, col: 9, uid: 'user15', initials: 'IJO' },
  { row: 2, col: 1, uid: 'user15', initials: 'IJO' },
  { row: 5, col: 7, uid: 'user15', initials: 'IJO' },
  { row: 7, col: 7, uid: 'user15', initials: 'IJO' },
  { row: 6, col: 0, uid: 'user16', initials: 'MB' },
  { row: 3, col: 2, uid: 'user16', initials: 'MB' },
  { row: 8, col: 5, uid: 'user16', initials: 'MB' },
  { row: 3, col: 5, uid: 'user16', initials: 'MB' },
  { row: 1, col: 8, uid: 'user16', initials: 'MB' },
  { row: 9, col: 6, uid: 'user17', initials: 'OPQ' },
  { row: 4, col: 3, uid: 'user17', initials: 'OPQ' },
  { row: 6, col: 8, uid: 'user17', initials: 'OPQ' },
  { row: 2, col: 3, uid: 'user17', initials: 'OPQ' },
  { row: 0, col: 3, uid: 'user18', initials: 'RST' },
  { row: 5, col: 3, uid: 'user18', initials: 'RST' },
  { row: 7, col: 3, uid: 'user18', initials: 'RST' },
  { row: 8, col: 4, uid: 'user18', initials: 'RST' },
  { row: 9, col: 9, uid: 'user19', initials: 'UVW' },
  { row: 3, col: 7, uid: 'user19', initials: 'UVW' },
  { row: 1, col: 3, uid: 'user19', initials: 'UVW' },
  { row: 7, col: 0, uid: 'user19', initials: 'UVW' },
  { row: 0, col: 5, uid: 'user20', initials: 'JRP' },
  { row: 1, col: 0, uid: 'user20', initials: 'JRP' },
  { row: 6, col: 6, uid: 'user20', initials: 'JRP' },
  { row: 2, col: 7, uid: 'user20', initials: 'JRP' },
  { row: 4, col: 4, uid: 'user20', initials: 'JRP' },
   { row: 9, col: 4, uid: 'user21', initials: 'ABC'},
   { row: 8, col: 1, uid: 'user21', initials: 'ABC' },
   { row: 7, col: 9, uid: 'user21', initials: 'ABC' },
   { row: 6, col: 4, uid: 'user22', initials: 'MR' },
   { row: 5, col: 5, uid: 'user22', initials: 'MR' },
   { row: 4, col: 6, uid: 'user22', initials: 'MR' },
   { row: 3, col: 1, uid: 'user23', initials: 'GHI' },
   { row: 2, col: 9, uid: 'user23', initials: 'GHI' },
   { row: 1, col: 5, uid: 'user23', initials: 'GHI' },
   { row: 0, col: 7, uid: 'user24', initials: 'VJOL' },
   { row: 9, col: 5, uid: 'user24', initials: 'VJOL' },
   { row: 8, col: 8, uid: 'user24', initials: 'VJOL' },
   { row: 7, col: 5, uid: 'user25', initials: 'MNO' },
   { row: 6, col: 9, uid: 'user25', initials: 'MNO' },
   { row: 5, col: 4, uid: 'user25', initials: 'MNO' },
   { row: 4, col: 2, uid: 'user26', initials: 'PQR' },
   { row: 3, col: 9, uid: 'user26', initials: 'PQR' },
   { row: 2, col: 7, uid: 'user26', initials: 'PQR' },
   { row: 1, col: 8, uid: 'user27', initials: 'STU' },
   { row: 8, col: 9, uid: 'user27', initials: 'STU' },
   { row: 0, col: 6, uid: 'user27', initials: 'STU' },
   { row: 9, col: 2, uid: 'user27', initials: 'STU' }
];

export const selectedSquaresWithContender = [
  { row: 0, col: 4, uid: 'me', initials: 'YOU' },
  { row: 1, col: 2, uid: 'me', initials: 'YOU' },
  { row: 3, col: 6, uid: 'me', initials: 'YOU' },
  { row: 0, col: 1, uid: 'me', initials: 'YOU' },
  { row: 4, col: 1, uid: 'user1', initials: 'JRP' },
  { row: 2, col: 5, uid: 'user1', initials: 'JRP' },
  { row: 3, col: 3, uid: 'user1', initials: 'JRP' },
  { row: 5, col: 0, uid: 'user1', initials: 'JRP' },
  { row: 6, col: 2, uid: 'user2', initials: 'MB' },
  { row: 7, col: 4, uid: 'user2', initials: 'MB' },
  { row: 4, col: 7, uid: 'user2', initials: 'MB' },
  { row: 8, col: 3, uid: 'user3', initials: 'EM' },
  { row: 5, col: 6, uid: 'user3', initials: 'EM' },
  { row: 9, col: 1, uid: 'user3', initials: 'EM' },
  { row: 2, col: 8, uid: 'user4', initials: 'VJO' },
  { row: 1, col: 7, uid: 'user4', initials: 'VJO' },
  { row: 3, col: 9, uid: 'user4', initials: 'VJO' },
  { row: 6, col: 5, uid: 'user5', initials: 'MR' },
  { row: 7, col: 6, uid: 'user5', initials: 'MR' },
  { row: 0, col: 9, uid: 'user5', initials: 'MR' },
  { row: 0, col: 0, uid: 'user6', initials: 'HIJ' },
  { row: 2, col: 0, uid: 'user6', initials: 'HIJ' },
  { row: 4, col: 0, uid: 'user6', initials: 'HIJ' },
  { row: 1, col: 1, uid: 'user6', initials: 'HIJ', isContender: true },
  { row: 5, col: 2, uid: 'user7', initials: 'KLM' },
  { row: 6, col: 1, uid: 'user7', initials: 'KLM' },
  { row: 8, col: 2, uid: 'user7', initials: 'KLM' },
  { row: 7, col: 8, uid: 'user7', initials: 'KLM', winner: '1st'  },
  { row: 9, col: 3, uid: 'user8', initials: 'NOP' },
  { row: 3, col: 4, uid: 'user8', initials: 'NOP' },
  { row: 5, col: 9, uid: 'user8', initials: 'NOP' },
  { row: 2, col: 6, uid: 'user8', initials: 'NOP' },
  { row: 1, col: 6, uid: 'user9', initials: 'QRS' },
  { row: 6, col: 7, uid: 'user9', initials: 'QRS' },
  { row: 8, col: 6, uid: 'user9', initials: 'QRS' },
  { row: 0, col: 6, uid: 'user9', initials: 'QRS' },
  { row: 3, col: 8, uid: 'user10', initials: 'TUV' },
  { row: 4, col: 5, uid: 'user10', initials: 'TUV' },
  { row: 5, col: 1, uid: 'user10', initials: 'TUV' },
  { row: 9, col: 0, uid: 'user10', initials: 'TUV' },
  { row: 2, col: 2, uid: 'user11', initials: 'WXY' },
  { row: 0, col: 8, uid: 'user11', initials: 'WXY' },
  { row: 8, col: 7, uid: 'user11', initials: 'WXY' },
  { row: 7, col: 1, uid: 'user11', initials: 'WXY' },
  { row: 4, col: 9, uid: 'user12', initials: 'ZAB' },
  { row: 1, col: 4, uid: 'user12', initials: 'ZAB' },
  { row: 6, col: 3, uid: 'user12', initials: 'ZAB' },
  { row: 9, col: 8, uid: 'user12', initials: 'ZAB' },
  { row: 2, col: 4, uid: 'user13', initials: 'CDE' },
  { row: 8, col: 0, uid: 'user13', initials: 'CDE' },
  { row: 5, col: 8, uid: 'user13', initials: 'CDE' },
  { row: 7, col: 2, uid: 'user13', initials: 'CDE' },
  { row: 0, col: 2, uid: 'user14', initials: 'FGH' },
  { row: 4, col: 8, uid: 'user14', initials: 'FGH' },
  { row: 9, col: 7, uid: 'user14', initials: 'FGH' },
  { row: 3, col: 0, uid: 'user14', initials: 'FGH' },
  { row: 1, col: 9, uid: 'user15', initials: 'IJO' },
  { row: 2, col: 1, uid: 'user15', initials: 'IJO' },
  { row: 5, col: 7, uid: 'user15', initials: 'IJO' },
  { row: 7, col: 7, uid: 'user15', initials: 'IJO' },
  { row: 6, col: 0, uid: 'user16', initials: 'MB' },
  { row: 3, col: 2, uid: 'user16', initials: 'MB' },
  { row: 8, col: 5, uid: 'user16', initials: 'MB' },
  { row: 3, col: 5, uid: 'user16', initials: 'MB' },
  { row: 1, col: 8, uid: 'user16', initials: 'MB' },
  { row: 9, col: 6, uid: 'user17', initials: 'OPQ' },
  { row: 4, col: 3, uid: 'user17', initials: 'OPQ' },
  { row: 6, col: 8, uid: 'user17', initials: 'OPQ' },
  { row: 2, col: 3, uid: 'user17', initials: 'OPQ' },
  { row: 0, col: 3, uid: 'user18', initials: 'RST' },
  { row: 5, col: 3, uid: 'user18', initials: 'RST' },
  { row: 7, col: 3, uid: 'user18', initials: 'RST' },
  { row: 8, col: 4, uid: 'user18', initials: 'RST' },
  { row: 9, col: 9, uid: 'user19', initials: 'UVW' },
  { row: 3, col: 7, uid: 'user19', initials: 'UVW' },
  { row: 1, col: 3, uid: 'user19', initials: 'UVW' },
  { row: 7, col: 0, uid: 'user19', initials: 'UVW' },
  { row: 0, col: 5, uid: 'user20', initials: 'JRP' },
  { row: 1, col: 0, uid: 'user20', initials: 'JRP' },
  { row: 6, col: 6, uid: 'user20', initials: 'JRP' },
  { row: 2, col: 7, uid: 'user20', initials: 'JRP' },
  { row: 4, col: 4, uid: 'user20', initials: 'JRP' },
   { row: 9, col: 4, uid: 'user21', initials: 'ABC'},
   { row: 8, col: 1, uid: 'user21', initials: 'ABC' },
   { row: 7, col: 9, uid: 'user21', initials: 'ABC' },
   { row: 6, col: 4, uid: 'user22', initials: 'MR' },
   { row: 5, col: 5, uid: 'user22', initials: 'MR' },
   { row: 4, col: 6, uid: 'user22', initials: 'MR' },
   { row: 3, col: 1, uid: 'user23', initials: 'GHI' },
   { row: 2, col: 9, uid: 'user23', initials: 'GHI' },
   { row: 1, col: 5, uid: 'user23', initials: 'GHI' },
   { row: 0, col: 7, uid: 'user24', initials: 'VJOL' },
   { row: 9, col: 5, uid: 'user24', initials: 'VJOL' },
   { row: 8, col: 8, uid: 'user24', initials: 'VJOL' },
   { row: 7, col: 5, uid: 'user25', initials: 'MNO' },
   { row: 6, col: 9, uid: 'user25', initials: 'MNO' },
   { row: 5, col: 4, uid: 'user25', initials: 'MNO' },
   { row: 4, col: 2, uid: 'user26', initials: 'PQR' },
   { row: 3, col: 9, uid: 'user26', initials: 'PQR' },
   { row: 2, col: 7, uid: 'user26', initials: 'PQR' },
   { row: 1, col: 8, uid: 'user27', initials: 'STU' },
   { row: 8, col: 9, uid: 'user27', initials: 'STU' },
   { row: 0, col: 6, uid: 'user27', initials: 'STU' },
   { row: 9, col: 2, uid: 'user27', initials: 'STU' }
];

