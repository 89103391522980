import { Route, Routes, useLocation } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { NewEvent, EventDetails, MyEvents } from './pages'
import Login from './pages/login'
import Home from './pages/home'
import Register from './pages/register'
import Reset from './pages/reset'
import Anecdotes from './pages/anecdotes'
import Settings from './pages/settings'
import NewGuest from './pages/new-guest'
import Playlist from './pages/Playlist/Playlist'
import RSVPs from './pages/RSVPs'
import SpotifyCallback from './pages/spotify-callback'
import Thankyou from './pages/thankyou'
import Privacy from './pages/privacy'
import About from './pages/about'
import Blog from './pages/blog'
import Terms from './pages/terms'
import ReactGA from 'react-ga'
import { useEffect } from 'react'
import YesResponse from './pages/yes-response'
import Faq from './pages/faq'
import ErrorPage from './pages/404'
import RSVPEventDetails from './pages/RSVP/RSVPEventDetails'
import RSVPDetails from './pages/RSVP/RSVPDetails'
import RSVPPlaylist from './pages/RSVP/Playlist/RSVPPlaylist'
import CanvaCallback from './pages/canva-callback'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import NewEventMusicSelect from './components/NewEvent/NewEventMusicSelect/NewEventMusicSelect'
import Squares from './pages/Squares/Squares'
import SquaresChoose from './pages/Squares/SquaresChoose/SquaresChoose'
import SquaresSummary from './pages/Squares/SquaresSummary/SquaresSummary'
import SquaresGuestChoose from './pages/Squares/SquaresGuestChoose/SquaresGuestChoose'
import SquaresGuestSummary from './pages/Squares/SquaresGuestSummary/SquaresGuestSummary'
import EmailDistribution from './pages/EmailDistribution/EmailDistribution'
import SuperBowlBlog from './pages/SuperBowlBlog/SuperBowlBlog'

ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID)

function App() {
  const location = useLocation()
  const queryClient = new QueryClient()

  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE, {
      defaultTracking: {
        pageViews: true,
        sessions: true,
        formInteractions: true
      },
      autocapture: {
        attribution: true,
        pageViews: true,
        sessions: true,
        formInteractions: true,
        fileDownloads: true,
        elementInteractions: true
      }
    })
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: process.env.REACT_APP_AMPLITUDE_REPLAY_SAMPLE
    })
    amplitude.add(sessionReplayTracking)
  }, [])

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route exact path="/thankyou" element={<Thankyou />} />
        <Route
          exact
          path="/event/update/:circleCode/info"
          element={<CanvaCallback />}
        />
        <Route exact path="/callback" element={<SpotifyCallback />} />
        <Route exact path="/event-history" element={<MyEvents />} />
        {/* <Route exact path="/how-it-works" element={<HowItWorks />} /> */}
        <Route exact path="/about" element={<About />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route
          exact
          path="/event/new/event/playlist"
          element={<NewEventMusicSelect />}
        />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/event/new" element={<NewEvent type="new" />} />
        <Route
          exact
          path="/event/new/:ocassion"
          element={<NewEvent type="new" />}
        />
        <Route exact path="/event/:circleCode" element={<EventDetails />} />
        <Route
          exact
          path="/event/:circleCode/rsvp/:rsvpId"
          element={<RSVPDetails />}
        />
        <Route
          exact
          path="/event/:circleCode/rsvp/:rsvpId/details"
          element={<RSVPEventDetails />}
        />
        <Route
          exact
          path="/event/:circleCode/rsvp/:rsvpId/playlist"
          element={<RSVPPlaylist />}
        />
        <Route
          exact
          path="/event/:circleCode/edit"
          element={<NewEvent type="edit" />}
        />
        <Route
          exact
          path="/event/:circleCode/rsvp/:rsvpId/help"
          element={<YesResponse />}
        />
        <Route
          exact
          path="/event/:circleCode/rsvp/new"
          element={<NewGuest checkInvite={true} />}
        />
        <Route
          exact
          path="/event/:circleCode/guest"
          element={<NewGuest checkInvite={false} />}
        />
        <Route exact path="/event/:circleCode/rsvps" element={<RSVPs />} />
        <Route
          exact
          path="/event/:circleCode/playlist"
          element={<Playlist />}
        />
        <Route exact path="/anecdotes" element={<Anecdotes />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<ErrorPage />} />

        {/* Email distributions */}
        <Route
          path="/event/:circleCode/email-distribution"
          element={<EmailDistribution />}
        />

        {/* Super bowl blog */}
        <Route exact path="/superbowl/blog" element={<SuperBowlBlog />} />

        {/* Squares */}
        <Route
          exact
          path="/event/:circleCode/squares"
          element={<Squares />}
        />       
        <Route
          exact
          path="/event/:circleCode/squares/choose"
          element={<SquaresChoose />}
        />
        <Route
          exact
          path="/event/:circleCode/squares/summary"
          element={<SquaresSummary />}
        />

        <Route
          exact
          path="/event/:circleCode/rsvp/squares/choose/:rsvpId"
          element={<SquaresGuestChoose />}
        />
        <Route
          exact
          path="/event/:circleCode/rsvp/squares/summary/:rsvpId"
          element={<SquaresGuestSummary />}
        />
      </Routes>
    </QueryClientProvider>
  )
}

export default App
