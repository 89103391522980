import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import Input from '../../../components/Input/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '../../../components/Button/Button'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { addUserToMembers } from '../../../services/Circle'
import { useToast } from '@chakra-ui/react'
import { getTokens } from '../../../services/helpers'
import queryString from 'query-string'
import ClipLoader from 'react-spinners/ClipLoader'
import Base from '../../../pages/base'

const schema = yup
  .object({
    personType: yup.object().required()
  })
  .required()

const NewEventMusicSelect = ({
  rsvpId,
  circleCode,
  circleInfo,
  setProvider,
  setOption,
  setStep
}) => {
  const [authenticating, setAuthenticating] = useState(null)
  const toast = useToast()
  const navigate = useNavigate()

  const params = useMemo(
    () =>
      typeof window != null ? queryString.parse(window.location.search) : {},
    []
  )

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      personType: {
        value: 'guest',
        title: 'Guest',
        subtitle: 'Continue without connecting your music provider.'
      }
    }
  })

  const steps = useMemo(
    () => [
      'On the next screen, search for and add a few songs to be played at your event. These songs will establish the events overall tone.',
      'During the RSVP process, your guests will be prompted with similar music to the vibe you’ve set. The songs they pick will be added to a music playlist for the event.',
      'Click “Listen on Spotify” on your event page to access the playlist and start playing music.'
    ],
    []
  )

  const options = useMemo(() => {
    return [
      {
        value: 'guest',
        title: 'Guest',
        subtitle: 'Continue without connecting your music provider.'
      },
      {
        value: 'spotify',
        title: 'Spotify account',
        subtitle: 'Connect with your Spotify account.'
      }
      // {
      //   value: 'apple_music',
      //   title: 'Apple Music account',
      //   subtitle: 'Connect with your apple music account.'
      // }
    ]
  }, [])

  const handlePreviousStep = () => {
    navigate(-1)
  }

  const handleNextStep = async () => {
    const authType = form.getValues('personType')
    setAuthenticating(authType.value)
  }

  const handleGuest = useCallback(async () => {
    console.log('handleGuest')
    try {
      // logEvent(analytics, 'provider_connected', {
      //   source: 'STREAMING',
      //   provider: 'GUEST'
      // })

      await addUserToMembers(
        rsvpId,
        {
          uid: rsvpId,
          option: 'guest',
          provider: 'spotify'
        },
        circleCode,
        [],
        [],
        []
      )

      return true
    } catch (err) {
      console.log('fail', err)
      toast({
        status: 'error',
        title: err.message || 'Error while connecting with spotify',
        position: 'top'
      })
      return false
    }
  }, [circleCode, toast])

  const handleSpotify = useCallback(async () => {
    console.log('handleSpotify')
    try {
      const token = await getTokens(
        'spotify',
        `gstreaming-${circleCode}-${rsvpId}`
      )
      console.log('handleSpotify -> getProviderToken -> token', token)

      // logEvent(analytics, 'provider_connected', {
      //   source: 'STREAMING',
      //   provider: 'SPOTIFY'
      // })

      await addUserToMembers(
        rsvpId,
        {
          uid: rsvpId,
          option: 'spotify',
          provider: 'spotify'
        },
        circleCode,
        [],
        [],
        []
      )

      return true
    } catch (err) {
      console.log('fail', err)
      toast({
        status: 'error',
        title: err.message || 'Error while connecting with spotify',
        position: 'top'
      })
      return false
    }
  }, [circleCode, toast])

  const handleAppleMusic = useCallback(async () => {
    console.log('handleAppleMusic')
    try {
      const token = await getTokens(
        'apple_music',
        `gstreaming-${circleCode}-${rsvpId}`
      )
      if (!token.appleMusicToken || !token.accessToken) return

      // logEvent(analytics, 'provider_connected', {
      //   source: 'STREAMING',
      //   provider: 'APPLE_MUSIC'
      // })

      await addUserToMembers(
        rsvpId,
        {
          uid: rsvpId,
          option: 'guest',
          provider: 'spotify'
        },
        circleCode,
        [],
        [],
        []
      )

      return true
    } catch (err) {
      console.log('fail', err)
      toast({
        status: 'error',
        title: err.message || 'Error while connecting with apple music',
        position: 'top'
      })
      return false
    }
  }, [circleCode, toast])

  const handleProvider = useCallback(async () => {
    if (!authenticating) return
    let success = false
    if (authenticating === 'guest') {
      success = await handleGuest()
    } else if (authenticating === 'spotify') {
      success = await handleSpotify()
    } else if (authenticating === 'apple_music') {
      success = await handleAppleMusic()
    }

    if (success) {
      if (params?.source === 'spotify') {
        navigate({ pathname: window.location.pathname })
      }
      setStep(1)
      setOption(authenticating)
      setProvider(authenticating === 'apple_music' ? 'apple_music' : 'spotify')
    }
  }, [authenticating])

  useEffect(() => {
    if (!!circleInfo && !!params && !authenticating) {
      if (params.source === 'spotify') {
        console.log('useEffect Authenticating -> spotify')
        setAuthenticating('spotify')
      } else if (!params.view) {
        console.log('useEffect Authenticating -> guest')
        setAuthenticating('guest')
      }
    }
  }, [circleInfo, params, authenticating])

  useEffect(() => {
    if (!!authenticating) {
      // Authenticate to music provider
      handleProvider().then(() => null)
    }
  }, [authenticating, handleProvider])

  if (authenticating) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <ClipLoader color="#5B4ABC" />
      </div>
    )
  }

  return (
    <Base hideBg={true} bottomBar={false} allowFullHeight={true}>
      <div className={`mt-[20px] visible flex flex-1 flex-col`}>
        <h2>Create the perfect music playlist for your event.</h2>

        <div className="border-1 border-gray rounded-input px-[7px] py-[24px] my-[15px]">
          {steps.map((title, index) => (
            <div
              className={`flex items-center ${index !== steps.length - 1 ? 'mb-2' : ''}`}
              key={index}
            >
              <div className="px-3 py-1 rounded-full border-1 border-primary mr-[15px]">
                <span className="text-primary font-semibold">{index + 1}</span>
              </div>
              <span className="font-semibold">{title}</span>
            </div>
          ))}
        </div>

        <h2>Select your music app to get started.</h2>

        <Input
          type={'large-select'}
          register={form.register('personType')}
          form={form}
          options={options}
          className={'mt-3'}
          hasError={undefined}
        />
      </div>

      <div className="flex flex-row mt-[18px] justify-between items-end">
        <Button type="text" text={'Back'} onClick={handlePreviousStep} />
        <Button
          disabled={!!authenticating}
          text={'Continue'}
          onClick={handleNextStep}
        />
      </div>
    </Base>
  )
}

export default memo(NewEventMusicSelect)
