import React, { memo } from 'react'
import { SpotifyIcon, SpotifyWithTextIcon } from '../../../../assets/icons'
import Button from '../../../../components/Button/Button'
import MusicCard from '../../../../components/EventDetails/MusicCard/MusicCard'
import { useNavigate, useParams } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'
import BackgroundBlur from '../../../../components/EventDetails/BackgroundBlur/BackgroundBlur'
import { useObject } from 'react-firebase-hooks/database'
import { database, ref } from '../../../../firebase'
import { eventTimePassed } from '../../../../helpers'

const MusicTab = ({
  rsvpId,
  playlist,
  isToggled,
  circleInfo,
  alreadyHasResponse,
  rsvpResponse
}) => {
  const navigate = useNavigate()
  const { circleCode } = useParams()
  const provider = circleInfo?.provider ?? 'spotify'
  const isTimePassed = eventTimePassed(circleInfo)
  const hasMusic = !!circleInfo?.musicCollab ?? false

  const isRSVPAttending = rsvpId ? rsvpResponse === 'yes' : true;

  const [hostSnap, hostLoading] = useObject(
    !!circleInfo?.hostID ? ref(database, `users/${circleInfo.hostID}`) : null
  )
  const hostInfo = hostSnap?.val() ?? {}
  const canAddSongs =
    !!hostInfo.accessToken && !!hostInfo.refreshToken && !!hostInfo.spotifyID

  const handleAddMoreSongs = () => {
    navigate(
      !!rsvpId
        ? `/event/${circleCode}/rsvp/${rsvpId}/help?view=music&hasResponse=${alreadyHasResponse}`
        : `/event/${circleCode}/playlist`
    )
  }

  const handleViewPlaylist = () => {
    amplitude.track(
      'Playlist navigated to',
      {
        circleCode
      },
      {
        user_id: rsvpId
      }
    )
    if (provider === 'spotify') {
      window.open(
        `https://open.spotify.com/playlist/${circleInfo.playlistID}`,
        '_blank',
        'noopener,noreferrer'
      )
    } else {
      window.open(
        `https://music.apple.com/us/playlist/${circleInfo.name}/${circleInfo.playlistID}`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }

  return (
    <div className={`${isToggled ? 'mt-[10px] relative' : 'hidden'}`}>
      {/* {!hasMusic && (
        <BackgroundBlur
          onClick={handleAddMoreSongs}
          text={'Create music playlist'}
        />
      )} */}
      <div className={`${isToggled ? 'mt[10px]' : 'hidden'}`}>
        <div id="header" className="flex flex-col mt-3 items-center sm:flex-row">
          <div className='flex flex-col sm:mb-0 mb-3'>
            <span className="text-[14px]">
              Start by adding songs below to influence suggestions provided to your guests.
            </span>

            <span className="text-[14px] mt-3">
              When you’re ready, click  “Listen on Spotify” to navigate to the playlist on your
              Spotify app.
            </span>
          </div>

          <Button
            type="terciary"
            size="extra-small"
            text={
              provider === 'spotify'
                ? 'Listen on Spotify'
                : 'Listen on Apple Music'
            }
            iconLeft={<SpotifyIcon fill={'black'} className="mr-2" />}
            onClick={handleViewPlaylist}
            className="min-w-[220px] self-center flex items-center border-black text-black"
          />
        </div>

        <div className='w-full flex items-center justify-center mt-3'>
          {isTimePassed ? null : isRSVPAttending && (
            <button
              onClick={handleAddMoreSongs}
              className={`w-full h-10 mt-2 flex border-1 border-off-white bg-off-white text-primary text-[18px] rounded-md py-[2px] items-center justify-center hover:text-white hover:bg-primary transition duration-300 ease-in-out max-w-[370px]`}
            >
              + Add songs
            </button>
          )}
        </div>

        <div
          id="content"
          className="mt-[20px] mb-[10px] flex flex-row items-center"
        >
          <div className="flex flex-col items-center">
            <span className="text-placeholder text-[13px] mr-2">
              Available via
            </span>
          </div>
          <SpotifyWithTextIcon fill={'black'} width={80} height={30} />
        </div>

        {!playlist && (
          <div className="flex flex-1 items-center justify-center mt-[15px]">
            <span>No songs found</span>
          </div>
        )}
      </div>

      {playlist?.map((song, index) => (
        <MusicCard
          key={index}
          music={song}
          className="mb-3"
          checked
          provider={provider}
        />
      ))}
    </div>
  )
}

export default memo(MusicTab)
