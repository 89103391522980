const Colors = {
  light: '#E5E5E5',
  dark: '#2D2D31',
  primary: '#422d8f',
  black: {
    100: '#32312F',
    200: '#2F313E',
    300: '#2D2D31'
  },
  navy: '#2D2D31',
  white: '#FFFFFF',
  pink: {
    100: '#eeecf9',
    200: '#cdc5ed',
    300: '#ac9ee0',
    400: '#422d8f',
    500: '#6a50c8',
    600: '#5137af',
    700: '#3f2b88',
    800: '#2d1f61',
    900: '#1b123a'
  },
  gray: {
    100: '#E4E4E4',
    300: '#C2C2C2',
    500: '#A2A2A2',
    600: '#555555',
    confirmText: '#F4F4F6'
  },
  grayLight: '#F2F2F2',
  error: '#a81f71',
  alertInfo: '#FF5A5A',
  warning: '#FFE100',
  greenLight: '#D0F7BA',
  greenDark: '#75C843'
}

export default Colors
