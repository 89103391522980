import React from 'react'
import { v4 } from 'uuid'
import Button from '../../Button/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { set, update } from 'firebase/database'
import { database, ref } from '../../../firebase'
import { useToast } from '@chakra-ui/react'

export const schema = yup
  .object({
    email: yup.string().required()
  })
  .required()

const SuperBowlBlogBanner = ({ superBowlUsers }) => {
  const toast = useToast()

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  })

  const handleDivClick = () => {
    form.setFocus('email')
  }

  const submit = async ({ email }) => {
    try {
      if (superBowlUsers?.find((em) => em === email)) {
        toast({
          status: 'error',
          title: 'Already on list',
          position: 'top'
        })
        return
      }

      const newUsersToNotify = [...(superBowlUsers || []), email]

      await set(ref(database, `superBowlNotificationsGroup`), newUsersToNotify)

      form.reset()

      toast({
        status: 'success',
        title: 'Added to notification list',
        position: 'top'
      })
    } catch (err) {
      console.log('SuperBowlBlogBanner: ', err)
      toast({
        status: 'error',
        title: err?.message || 'Error adding to notification list',
        position: 'top'
      })
    }
  }

  return (
    <div
      className="bg-off-white rounded-md flex flex-col items-center p-3 cursor-pointer"
      onClick={handleDivClick}
    >
      <span className="text-primary text-[20px] font-bold mb-5">
        Coming soon!
      </span>
      <span className="text-[14px] text-center">
        Coordinate a digital Super Bowl squares game with Coteri. Enter your
        email below to get notified when the feature is available.
      </span>

      <div
        className={`max-w-[330px] w-full bg-white border-1 ${form.formState.errors.email ? 'border-error' : 'border-primary'} rounded-full flex items-center justify-between p-1 mt-4`}
      >
        <input
          {...form.register('email')}
          type="email"
          placeholder="Enter your email"
          className="outline-none text-[14px] pl-2"
        />
        <Button
          text={'Notify me'}
          size={'extra-small'}
          className={'w-[120px]'}
          onClick={form.handleSubmit(submit)}
        />
      </div>
    </div>
  )
}

export default SuperBowlBlogBanner
