import * as React from 'react'

function Edit({ fill, ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.548 20.938h16.9a.5.5 0 000-1h-16.9a.5.5 0 000 1zM9.71 17.18a2.587 2.587 0 001.12-.65l9.54-9.54a1.75 1.75 0 000-2.47l-.94-.93a1.788 1.788 0 00-2.47 0l-9.54 9.53c-.309.31-.53.697-.64 1.12L6.04 17a.737.737 0 00.19.72c.141.14.331.218.53.22l2.95-.76zm.41-1.36a1.468 1.468 0 01-.67.39l-.97.26-1-1 .26-.97c.07-.253.204-.484.39-.67l.38-.37 1.99 1.99-.38.37zm1.09-1.08l-1.99-1.99 6.73-6.73 1.99 1.99-6.73 6.73zm8.45-8.45L18.65 7.3l-1.99-1.99 1.01-1.02a.746.746 0 011.06 0l.93.94a.754.754 0 010 1.06z"
        fill={fill}
      />
    </svg>
  )
}

export default Edit
