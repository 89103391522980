import React, { memo, useState } from 'react'
import ActiveEvents from './ActiveEvents/ActiveEvents'
import PastEvents from './PastEvents/PastEvents'

const Tab = ({ futureEvents, pastEvents, setDeleteEventModal }) => {
  const [toggleTab, setToggleTab] = useState(0)

  return (
    <div className="flex flex-1 flex-col ">
      <ul className="flex w-full items-center mt-[20px]">
        <li
          onClick={() => setToggleTab(0)}
          className={`cursor-pointer relative w-[50%] pb-1 text-center ${toggleTab === 0 ? 'border-b-2 border-primary' : ''}`}
        >
          Active events
        </li>
        <li
          onClick={() => setToggleTab(1)}
          className={`cursor-pointer relative w-[50%] pb-1 text-center ${toggleTab === 1 ? 'border-b-2 border-primary' : ''}`}
        >
          Past events
        </li>
      </ul>

      <ActiveEvents
        isToggled={toggleTab === 0}
        futureEvents={futureEvents}
        setDeleteEventModal={setDeleteEventModal}
      />

      <PastEvents
        isToggled={toggleTab === 1}
        pastEvents={pastEvents}
        setDeleteEventModal={setDeleteEventModal}
      />
    </div>
  )
}

export default memo(Tab)
