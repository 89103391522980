import React, { useMemo, useState } from 'react'
import Modal from '../../Modal/Modal'
import { XIcon } from '../../../assets/icons'
import DottedLine from '../../DottedLine/DottedLine'
import Checkbox from '../../Checkbox/Checkbox'
import Button from '../../Button/Button'

const RSVPEventDetailsCostModal = ({ isOpen, onClose, eventInfo }) => {
  const [consent, setConsent] = useState(false)
  const onlyCashOption = !eventInfo?.venmo && !eventInfo?.cashapp && !eventInfo?.paypal && !eventInfo?.zelle

  const cashText = onlyCashOption ? 'Pay cash upon arrival' : 'or pay cash upon arrival.'

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={"max-w-[450px] w-full mx-5"}>
      <div>
        <div className="bg-white rounded-input relative w-full flex flex-col">
          <div className='flex items-center justify-end mt-5 px-5'>
            <div className='flex items-center cursor-pointer z-50' onClick={onClose}>
              <XIcon fill={"black"} width={23} height={23} />
            </div>
          </div>

          <div className="absolute inset-0 w-full h-full left-5 flex">
            <DottedLine color={"#80808033"} />
          </div>

          <div className='flex flex-col px-5 mt-3'>
            <div className='flex items-center'>
              <div className='w-[2px] h-5 bg-primary' />
              <span className='ml-5 text-[18px] text-primary font-semibold'>Send payment to your host</span>
            </div>
            <div className='flex items-center justify-between mx-5'>
              <span className='font-semibold'>You owe ${eventInfo?.costPerSquare}</span>
            </div>
          </div>

          <DottedLine color={"#80808033"} className={"my-5 h-[1px]"} horizontal solid />

          {eventInfo?.venmo && <div className='flex flex-row px-5'>
            <div className='w-[2px] h-4 bg-primary' />
            <span className='ml-5 text-[15px]'>Venmo: (@{eventInfo?.venmo})</span>
          </div>}

          {eventInfo?.cashapp && <div className='flex flex-row px-5 mt-2'>
            <div className='w-[2px] h-4 bg-primary' />
            <span className='ml-5 text-[15px]'>Cashapp: (${eventInfo?.cashapp})</span>
          </div>}

          {eventInfo?.paypal && <div className='flex flex-row px-5 mt-2'>
            <div className='w-[2px] h-4 bg-primary' />
            <span className='ml-5 text-[15px]'>Paypal: (@{eventInfo?.paypal})</span>
          </div>}

          {eventInfo?.zelle && <div className='flex flex-row px-5 mt-2'>
            <div className='w-[2px] h-4 bg-primary' />
            <span className='ml-5 text-[15px]'>Zelle: ({eventInfo?.zelle})</span>
          </div>}

          {eventInfo?.allowCash && <div className='flex flex-row px-5 mt-2'>
            <span className='ml-5 text-[15px]'>{cashText}</span>
          </div>}

          <div className='flex flex-col items-center justify-between px-10 mb-5 z-50 mt-3 sm:flex-row'>
            <Checkbox
              checked={consent}
              onClick={() => setConsent(!consent)}
              text="I confirm I paid or will pay."
              textClassName={"text-[12px]"}
              className={'sm:mt-0 mt-3 ml-[0.5px]'}
            />

            <Button
              text={"Confirm"}
              size={"medium"}
              disabled={!consent}
              className={"sm:mt-0 mt-3"}
              onClick={() => onClose()}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RSVPEventDetailsCostModal
