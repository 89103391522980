import React, { memo } from 'react'
import { useMediaQuery } from '../../../hook/useMediaQuery'

const Background = () => {
  const breakpoints = {
    sm: 600,
    md: 900,
  }

  const { md, sm } = useMediaQuery(breakpoints)

  function DottedLine({ color }) {
    const dottedStyle = {
      backgroundImage: 'radial-gradient(' + color + ' 1px, transparent 1px)',
      backgroundSize: '3px 10px'
    }

    return <div className="w-[1.5px] h-full" style={dottedStyle} />
  }

  return (
    <div className="absolute inset-0 w-full h-full transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 flex justify-between max-w-[1200px] z-0">
      <div className="w-[0.5px] h-full bg-[#80808033] lg:ml-0" />
      {md && <DottedLine color="#80808033" />}
      {md && <DottedLine color="#80808033" />}
      {sm && <DottedLine color="#80808033" />}
      <div className="w-[0.5px] h-full bg-[#80808033] lg:ml-0" />
    </div>
  )
}

export default memo(Background)
