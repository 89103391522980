import React, { useState } from 'react'
import { ClockIcon } from '../../../assets/icons'
import moment from 'moment-timezone'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import MuiThemeProvider from '../../../theme/mui'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { head } from 'ramda'

const NewEventDateAndTime = ({ form }) => {
  const [dateOpen, setDateOpen] = useState(false)
  const [timeOpen, setTimeOpen] = useState(false)

  return (
    <MuiThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="bg-off-white flex flex-col justify-center mt-[12px] rounded-md border-1 cursor-pointer">
          <div className="flex relative w-full p-3">
            <div className="flex mt-[6px] mr-4">
              <ClockIcon />
            </div>
            <div className="flex flex-col">
              <div className="flex gap-4 mb-2">
                <div className="flex-1">
                  <Controller
                    name="date"
                    control={form.control}
                    render={({ field: { onChange, value } }) => {
                      console.log('date value', value)
                      return (
                        <DatePicker
                          value={dayjs(value)}
                          format="dddd, MMM DD"
                          open={dateOpen}
                          onOpen={() => setDateOpen(true)}
                          onClose={() => setDateOpen(false)}
                          closeOnSelect
                          disableClearable
                          onChange={(v) => onChange(v)}
                          slotProps={{
                            actionBar: { hidden: true },
                            toolbar: { hidden: true },
                            openPickerButton: { hidden: true },
                            textField: {
                              onClick: () => setDateOpen(true),
                              variant: 'standard'
                            }
                          }}
                          error
                          helperText="Date is required"
                        />
                      )
                    }}
                  />
                </div>
                <div className="flex-1">
                  <Controller
                    name="time"
                    control={form.control}
                    render={({ field: { onChange, value } }) => {
                      console.log('time value', value)
                      return (
                        <TimePicker
                          value={dayjs(value)}
                          onChange={(v) => onChange(v)}
                          open={timeOpen}
                          onOpen={() => setTimeOpen(true)}
                          onClose={() => setTimeOpen(false)}
                          timeSteps={{ minutes: 30 }}
                          disableClearable
                          slotProps={{
                            openPickerButton: { hidden: true },
                            textField: {
                              onClick: () => setTimeOpen(true),
                              variant: 'standard'
                            }
                          }}
                        />
                      )
                    }}
                  />
                </div>
              </div>
              <Controller
                name="timezone"
                control={form.control}
                render={({ field: { onChange, value } }) => {
                  console.log('timezone value', value)
                  return (
                    <Autocomplete
                      id="timezone"
                      options={moment.tz.names()}
                      autoHighlight
                      value={value}
                      defaultValue={value ?? moment.tz.guess()}
                      forcePopupIcon={false}
                      placeholder="Select timezone"
                      disableClearable
                      onChange={(_, v) => onChange(v)}
                      getOptionLabel={(option) =>
                        !!option
                          ? `${option} ${moment.tz(option).format('Z')}`
                          : ''
                      }
                      renderOption={({ key, ...optionProps }, option) => (
                        <Box key={key} component="li" {...optionProps}>
                          {option} {moment.tz(option).format('Z')}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          defaultValue={value}
                          variant="standard"
                          sx={{
                            paddingRight: '0 !important',
                            backgroundColor: 'transparent !important'
                          }}
                        />
                      )}
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </MuiThemeProvider>
  )
}

export default NewEventDateAndTime
