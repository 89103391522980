import React from 'react'
import Base from '../base'
import { Helmet } from 'react-helmet'
import DottedLine from '../../components/DottedLine/DottedLine'
import SquaresTable from '../../components/Squares/SquaresTable/SquaresTable'
import SuperBowlBlogBanner from '../../components/SuperBowlBlog/SuperBowlBlogBanner/SuperBowlBlogBanner'
import {
  allSquares,
  selectedSquares,
  selectedSquaresWithContender,
  selectedSquaresWithWinner
} from './data'
import { useObject } from 'react-firebase-hooks/database'
import { ref } from 'firebase/database'
import { database } from '../../firebase'
import Button from '../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { CoteriSymbolIcon } from '../../assets/icons'
import { Star } from '@mui/icons-material'

const SuperBowlBlog = () => {
  const navigate = useNavigate()

  const [superBowlNotification, superBowlNotificationLoading] = useObject(
    ref(database, `superBowlNotificationsGroup`)
  )
  const superBowlUsers = superBowlNotification?.val()

  const onPlan = () => {
    navigate('/event/new?view=super-bowl')
  }

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      verifiedOnly={true}
      whiteLogo
      removeVerticalPadding
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>View super bowls blog - Coteri</title>
        <meta name="description" content="See super bowls blog" />
      </Helmet>

      <div className={`flex flex-1 h-full flex-col relative -mt-4 pb-10`}>
        <div className="absolute inset-0 h-full left-10 right-10 justify-between sm:flex hidden">
          <DottedLine color={'#80808033'} />
          <DottedLine color={'#80808033'} />
        </div>

        <div className="sm:mx-16 mx-3 mt-6 z-50">
          <SuperBowlBlogBanner superBowlUsers={superBowlUsers} />
        </div>

        <DottedLine color={'#80808033'} horizontal className={'my-10'} />

        <section className="sm:mx-16 mx-3">
          <h1 className="text-[20px] text-primary">
            How to Play Super Bowl Squares and Plan Your Party with Ease
          </h1>
          <p className="mt-5 text-[14px]">
            The Super Bowl is one of the most exciting events in sports because
            it’s more than just a football game—it’s a cultural phenomenon. Each
            year, it brings together millions of fans, casual viewers, and even
            those who don’t follow the sport to share in the excitement. The
            high stakes of crowning the NFL champion, combined with the halftime
            show, memorable commercials - both hilarious and heartfelt - and the
            lively party atmosphere, make it a day packed with energy and
            entertainment. Every play and every moment holds the potential for
            history-making drama, and that thrill captivates the attention of
            millions across the country.
          </p>
          <p className="mt-3 text-[14px]">
            The Super Bowl is more than just a football game. It’s a day of
            excitement, food, and friends. For party hosts, it’s the perfect
            opportunity to introduce games that keep the fun going, even for
            non-football fans. One of the most popular games to play during a
            Super Bowl party is{' '}
            <span className="text-primary font-bold">Super Bowl Squares</span>.
            It adds extra excitement and keeps everyone engaged for the entire
            game.{' '}
          </p>
          <p className="mt-3 text-[14px]">
            Let’s go over how it works and how Coteri can help you plan a Super
            Bowl party that will impress your guests.
          </p>
        </section>

        <section className="mt-7 sm:mx-16 mx-3">
          <h2>What Is Super Bowl Squares?</h2>
          <p className="mt-5 text-[14px]">
            Super Bowl Squares is a game of chance that doesn’t require any
            football expertise. The game involves a 10x10 grid of 100 squares,
            each corresponding to a digit from 0 to 9. The goal is to match the
            last digits of the scores at the end of each quarter. Continue
            reading to learn more about how it works, and how Coteri makes the
            game all the more exciting through our Super Bowl invitation
            features!
          </p>
        </section>

        <section className="mt-7 sm:mx-16 mx-3">
          <h2>Setting Up the Grid</h2>
          <div className="relative mb-5">
            <div className="absolute inset-0 w-full h-full  ">
              <DottedLine color={'#80808033'} />
            </div>
            <div className="flex pt-3">
              <div className="h-4 w-[2px] bg-secondary mr-2 mt-1" />
              <span className="text-[14px]">
                1. Create the Grid: Draw a 10x10 grid on paper or use an online
                version.
              </span>
            </div>
            <div className="flex mt-3">
              <div className="h-4 w-[2px] bg-secondary mr-2 mt-1" />
              <span className="text-[14px]">
                2. Label the Axes: Assign one axis to the AFC team and the other
                to the NFC team, leaving the top row and side column blank.
              </span>
            </div>
            <div className="flex mt-3">
              <div className="h-4 w-[2px] bg-secondary mr-2 mt-1" />
              <span className="text-[14px]">
                3. Assign Squares: Guests claim squares by writing their names
                in the boxes. Make sure all 100 squares are filled before the
                game begins.
              </span>
            </div>
          </div>

          <SquaresTable
            selectedSquares={selectedSquares}
            user={{ uid: 'me' }}
            hideNumbers
            disableClick
            teamLeftName={'Philadelphia'}
            teamTopName={'New England'}
            teamLeftClassName={'bg-black'}
            teamTopClassName={'bg-white border-1 border-black'}
          />
        </section>

        <section className="mt-7 sm:mx-16 mx-3">
          <div className="mb-5">
            <h2>Assigning the Numbers</h2>
            <p className="mt-5 text-[14px]">
              After the squares are claimed, assign numbers 0 through 9 to the
              rows and columns randomly. This can be done by drawing numbers
              from a hat or using a random number generator.
            </p>
          </div>

          <SquaresTable
            selectedSquares={allSquares}
            user={{ uid: 'me' }}
            disableClick
            teamLeftName={'Philadelphia'}
            teamTopName={'New England'}
            topRowIndexes={[1, 9, 0, 3, 6, 5, 4, 8, 7, 2]}
            leftRowIndexes={[9, 7, 3, 6, 8, 4, 5, 0, 1, 2]}
            teamLeftClassName={'bg-black'}
            teamTopClassName={'bg-white border-1 border-black'}
          />
        </section>

        <section className="mt-7 sm:mx-16 mx-3">
          <div className="mb-5">
            <h2>How to Win</h2>
            <p className="mt-5 text-[14px]">
              At the end of each quarter, the winner is determined by the last
              digit of each team’s score. For example, at the end of the 1st
              quarter if the NFC team has 13 points and the AFC team has 7
              points, then look for the square where the 3 (from 13) and 7
              intersect. The person whose name is in that square wins for the
              quarter.
            </p>
          </div>

          <SquaresTable
            selectedSquares={selectedSquaresWithWinner}
            user={{ uid: 'me' }}
            disableClick
            teamLeftName={'Philadelphia'}
            teamTopName={'New England'}
            topRowIndexes={[1, 9, 0, 3, 6, 5, 4, 8, 7, 2]}
            leftRowIndexes={[9, 7, 3, 6, 8, 4, 5, 3, 1, 2]}
            teamLeftClassName={'bg-black'}
            teamTopClassName={'bg-white border-1 border-black'}
          />
        </section>

        <DottedLine color={'#80808033'} horizontal className={'my-10'} />

        <div className="sm:mx-16 mx-3 z-50">
          <SuperBowlBlogBanner superBowlUsers={superBowlUsers} />
        </div>

        <section className="mt-7 sm:mx-16 mx-3">
          <h2 className="text-[18px] text-primary">
            Planning Your Super Bowl Party with Coteri{' '}
          </h2>
          <p className="mt-5 text-[14px]">
            Super Bowl Squares is a great game to get your guests involved, but
            party planning and organizing the game can still be a lot of work.
            That’s where Coteri comes in. With Coteri, sending out invites is a
            breeze. You can track RSVPs and let guests know who’s bringing what,
            so you’re not left with too many chips and not enough drinks. Coteri
            helps you stay organized so your party runs smoothly.
          </p>
          <p className="mt-3 text-[14px]">
            <span className="font-bold">
              Super Bowl Squares Game, Built-In:
            </span>{' '}
            Coteri features an integrated Super Bowl Squares game, so you don’t
            need to print out grids or manually track scores. It’s a hassle-free
            way to keep everyone engaged. You can even share your Squares game
            with friends and family who can’t attend in person!
          </p>
          <div className="relative mb-5">
            <div className="absolute inset-0 w-full h-full  ">
              <DottedLine color={'#80808033'} />
            </div>
            <div className="flex pt-3">
              <div className="h-4 w-[2px] bg-secondary mr-2 mt-1" />
              <span className="text-[14px]">
                1. Send Super Bowl Invitations - Send invites and track
                responses for your party.{' '}
              </span>
            </div>
            <div className="flex mt-3">
              <div className="h-4 w-[2px] bg-secondary mr-2 mt-1" />
              <span className="text-[14px]">
                2. Guests can claim their squares online during the RSVP
                process.
              </span>
            </div>
            <div className="flex mt-3">
              <div className="h-4 w-[2px] bg-secondary mr-2 mt-1" />
              <span className="text-[14px]">
                3. Once all squares are taken, Coteri automatically assigns
                random numbers.
              </span>
            </div>
            <div className="flex mt-3 pb-3">
              <div className="h-4 w-[2px] bg-secondary mr-2 mt-1" />
              <span className="text-[14px]">
                4. Coteri tracks the score in real-time, updates the party on
                potential winners, and notifies guests when they win.
              </span>
            </div>
          </div>

          <div className="flex items-start justify-evenly w-full mb-5 sm:flex-row flex-col">
            <div className="flex flex-col items-center sm:flex-[0.3] w-full sm:mb-0 mb-4">
              <div className="w-10 h-10 rounded-sm border-[3px] border-blue" />
              <span className="text-[14px] font-semibold mt-2">Owner</span>
              <span className="text-[12px] text-center">
                You own this square
              </span>
            </div>

            <div className="flex flex-col items-center sm:flex-[0.3] w-full sm:mb-0 mb-4">
              <div className="w-10 h-10 rounded-sm border-2 border-light-blue-2 bg-light-blue-2" />
              <span className="text-[14px] font-semibold mt-2">Contender</span>
              <span className="text-[12px] text-center">
                This square corresponds to the current score of the game.
              </span>
            </div>

            <div className="flex flex-col items-center sm:flex-[0.3] w-full sm:mb-0 mb-4">
              <div className="w-10 h-10 rounded-sm border-2 border-blue bg-blue flex items-center justify-center">
                <Star style={{ color: 'white' }} />
              </div>
              <span className="text-[14px] font-semibold mt-2">Winner</span>
              <span className="text-[12px] text-center">
                Your square won for the corresponding quarter.
              </span>
              <div className="flex gap-1 mt-1">
                <span className="py-1 rounded-full bg-blue text-[11px] text-white w-[26px] text-center">
                  1st
                </span>
                <span className="py-1 rounded-full bg-blue text-[11px] text-white w-[26px] text-center">
                  2nd
                </span>
                <span className="py-1 rounded-full bg-blue text-[11px] text-white w-[26px] text-center">
                  3rd
                </span>
                <span className="py-1 rounded-full bg-blue text-[11px] text-white w-[26px] text-center">
                  4th
                </span>
              </div>
            </div>
          </div>

          <SquaresTable
            selectedSquares={selectedSquaresWithContender}
            user={{ uid: 'me' }}
            disableClick
            teamLeftName={'Philadelphia'}
            teamTopName={'New England'}
            topRowIndexes={[1, 9, 0, 3, 6, 5, 4, 8, 7, 2]}
            leftRowIndexes={[9, 7, 3, 6, 8, 4, 5, 3, 1, 2]}
            teamLeftClassName={'bg-black'}
            teamTopClassName={'bg-white border-1 border-black'}
          />
        </section>

        <section className="mt-7 sm:mx-16 mx-3">
          <h2>A Winning Party</h2>
          <p className="mt-5 text-[14px]">
            Super Bowl Sunday is about more than just the game—it’s about
            enjoying time with friends and family. With Coteri, not only can you
            effortlessly organize your Super Bowl Squares game, but you can also
            plan your entire event with ease. Whether it’s your first time
            hosting or you’re a seasoned pro, Coteri ensures your party is one
            to remember.
          </p>
          <p className="mt-3 text-[14px]">
            Let the game (and the fun) begin—may the best square win!
          </p>
        </section>
      </div>

      <div className="w-full bg-terciary -mb-5 p-5 flex items-center justify-around sm:flex-row flex-col">
        <div className="flex flex-col sm:items-start items-center">
          <span className="text-white text-[18px] font-semibold sm:text-start text-center">
            Host your party with ease.
          </span>
          <span className="text-white text-[14px] sm:text-start text-center">
            Plan your Super Bowl party in seconds with Coteri.
          </span>
        </div>

        <div className="flex flex-1 items-center justify-between sm:w-auto w-full mt-5">
          <Button
            text={'Plan your Super Bowl'}
            className={
              'self-end mb-2 border-white text-black bg-white sm:ml-5 ml-0 hover:bg-primary'
            }
            type={'border'}
            onClick={onPlan}
          />

          <CoteriSymbolIcon fill={'#FFFFFF'} className="w-[30px] h-[30px]" />
        </div>
      </div>
    </Base>
  )
}

export default SuperBowlBlog
