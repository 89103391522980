import React, { memo, useCallback, useEffect, useRef } from 'react'
import { useWatch, Controller } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import InputMask from 'react-input-mask'

const MaskedInput = ({ register, placeholder, className, hasError, form, ...props }) => {
  const { mask } = props
  const spanRef = useRef(null)

  const value = useWatch({
    name: register.name,
    control: form?.control
  })

  const handlePlaceholderAnimation = useCallback(
    (isFocused) => {
      if (!spanRef.current) return

      if (isFocused) {
        spanRef.current.style.top = '0px'
        spanRef.current.style.fontSize = '12px'
        spanRef.current.style.background = 'white'
        spanRef.current.style.padding = '0px 4px'
        return
      }

      if (!value && !isFocused) {
        spanRef.current.style.top = '50%'
        spanRef.current.style.fontSize = '16px'
        spanRef.current.style.padding = '0px'
      }
    },
    [value]
  )

  useEffect(() => {
    if (value) {
      handlePlaceholderAnimation(true)
    } else {
      handlePlaceholderAnimation(false)
    }
  }, [handlePlaceholderAnimation, value])

  return (
    <div
      className={twMerge(`relative w-full h-h-input min-w-24`, className)}
    >
      <Controller
        name={register.name}
        control={form.control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <InputMask
              mask={mask}
              className={`${hasError ? 'border-error' : 'border-input-border'} border-1 rounded-input bg-transparent h-full w-full p-2 outline-none focus:border-primary focus:border-[2px]`}
              type="tel"
              onChange={onChange}
              value={value}
              onFocus={() => {
                handlePlaceholderAnimation(true)
                spanRef.current?.classList.add('text-primary')
                spanRef.current?.classList.add('font-bold')
              }}
              onBlur={() => {
                handlePlaceholderAnimation(false)
                spanRef.current?.classList.remove('text-primary')
                spanRef.current?.classList.remove('font-bold')
              }}
              autoComplete="off"
              maskChar={""}
            />
          )
        }}
      />

      <span
        ref={spanRef}
        className={
          'text-placeholder absolute top-1/2 -translate-y-1/2 pointer-events-none left-2.5 ease-linear duration-150'
        }
      >
        {placeholder}
      </span>
    </div>
  )
}

export default memo(MaskedInput)
