export const calculatePayoutAmount = (totalCost, percentage) => {
  const cost = totalCost;
  return Math.round((cost * percentage) / 100);
}

export const extractPercentage = (value, extractor = '%') => {
  if (!value || !value.startsWith(extractor)) return 0;
  return parseInt(value.replace(extractor, '')) || 0;
};

export const getUserInitials = (initials) => {
  return initials.replace('Your initials: ', '');
}