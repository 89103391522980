import React, { useEffect } from 'react'
import { Flex, Image, Spinner, Text, useToast } from '@chakra-ui/react'
import Track from './Track'
import SpotifyIMG from '../assets/images/spotify.png'
import { ReactComponent as AppleMusicSVG } from '../assets/icons/apple_music.svg'
import { refreshTokens } from '../services/helpers'
import { useTokens } from '../context/TokensContext'
import { usePlaying } from '../context/PlayingContext'
import FlatList from 'flatlist-react'
import MusicCard from './EventDetails/MusicCard/MusicCard'

const OldPlaylist = ({
  name = 'playlist',
  type,
  provider = 'spotify',
  option,
  isLoading,
  playlist,
  circleCode,
  maxLimit,
  limitReached,
  hideSelect,
  replacedTrack,
  selected = [],
  prevSelectedSongs = [],
  connections = {},
  containerStyle = {},
  toggleSelectSong,
  searchSimilars,
  replaceSong,
  showAvl = true,
  mb = '120px'
}) => {
  const { track: playing, setTrack: setPlaying } = usePlaying()
  const { general } = useTokens()
  const toast = useToast()
  let intervalId

  const onClickPlay = (item) =>
    setPlaying(playing?.id === item.id ? null : item)

  const handlePlay = async (item) => {
    console.log('item', item)
    try {
      const tracks =
        provider === 'spotify'
          ? [`spotify:track:${item.spotifyID || item.id}`]
          : [item]
      console.log('startPlayingMusic', tracks)
      await window.startPlayingMusic(provider, tracks, general?.spotifyToken)
    } catch (e) {
      const needsRefresh = await refreshTokens(
        provider,
        e?.response?.status,
        `circle-${circleCode}`
      )
      if (needsRefresh) return
      console.log('handlePlay', e)
      toast({
        title: e.message || 'Error while playing track',
        position: 'top',
        status: 'error'
      })
    }
  }
  const handleOpenLink = () => {
    if (provider === 'spotify') {
      window.open(`https://open.spotify.com`, '_blank', 'noopener,noreferrer')
    } else {
      window.open(`https://music.apple.com`, '_blank', 'noopener,noreferrer')
    }
  }

  const renderItem = (item) => {
    const prevSelected =
      prevSelectedSongs.findIndex((x) => x.isrc === item.isrc) > -1
    const isSelected = selected.findIndex((x) => x.isrc === item.isrc) > -1

    return (
        <MusicCard
          key={`${name}-${item.isrc}`}
          music={item}
          canPlay={true}
          className="mb-3"
          onClick={() => toggleSelectSong(item)}
          checked={[...selected, ...prevSelectedSongs].find(
            (selectedMusic) => item.id === selectedMusic.id
          )}
          provider={provider}
        />
    )
  }

  useEffect(() => {
    window.pauseMusic()
    if (!!playing) {
      handlePlay(playing).then(() => null)
      intervalId = setInterval(() => {
        window.pauseMusic(provider)
        setPlaying(null)
      }, 10000)
      return () => (intervalId ? clearInterval(intervalId) : {})
    }
  }, [playing])

  useEffect(() => {
    return () => {
      window.pauseMusic(provider)
    }
  }, [])

  if (!!isLoading) {
    return (
      <Flex justify="center" style={containerStyle} mb={mb}>
        <Spinner mt="80px" size="xl" color="primary" />
      </Flex>
    )
  }

  return (
    <Flex direction="column" style={containerStyle} mb={mb}>
      {!!showAvl && playlist.length > 0 && (
        <Flex align="center">
          <Flex direction="row" flexShrink="0">
            <Flex direction="column" cursor="pointer" onClick={handleOpenLink}>
              <Text
                ml="2px"
                mb="1px"
                fontSize="13px"
                fontWeight="300"
                color="gray.600"
              >
                Available via
              </Text>
              {provider === 'spotify' ? (
                <Image src={SpotifyIMG} w="80px" mt="5px" mb="20px" />
              ) : (
                <AppleMusicSVG style={{ marginBottom: 20 }} />
              )}
            </Flex>
          </Flex>
          {option === '6' && (
            <Text color="black" align="center" p="0" ml="24px" mb="16px">
              See the songs your guests have added below
            </Text>
          )}
        </Flex>
      )}
      <FlatList
        list={playlist}
        renderItem={renderItem}
        renderWhenEmpty={() => <></>}
      />
    </Flex>
  )
}

export default OldPlaylist
