import Colors from './colors'
import Styles from './styles'
import { extendTheme } from '@chakra-ui/react'

const theme = {
  colors: Colors,
  styles: Styles,
  config: {
    useSystemColorMode: false
  }
}

export default extendTheme(theme)
