import { SvgIconProps } from '@mui/material'
import * as React from 'react'

function Google({ ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_376_469)">
        <path
          d="M8.361.79a11.865 11.865 0 00.812 22.668c1.987.513 4.07.535 6.067.066A10.71 10.71 0 0020.097 21a10.77 10.77 0 003-4.924c.58-2.048.683-4.202.301-6.296h-11.16v4.63h6.464a5.573 5.573 0 01-2.374 3.66 6.78 6.78 0 01-2.612 1.03 7.739 7.739 0 01-2.831 0 6.967 6.967 0 01-2.635-1.149 7.368 7.368 0 01-2.718-3.641 7.183 7.183 0 010-4.618 7.36 7.36 0 011.72-2.796 6.97 6.97 0 017.023-1.833 6.428 6.428 0 012.557 1.5c.729-.725 1.456-1.452 2.183-2.181.375-.392.784-.765 1.153-1.166A11.478 11.478 0 0016.35.86 12 12 0 008.361.789z"
          fill="#fff"
        />
        <path
          d="M8.36.79a12 12 0 017.99.069 11.479 11.479 0 013.816 2.366c-.375.401-.77.776-1.153 1.166-.728.726-1.455 1.45-2.181 2.171a6.428 6.428 0 00-2.558-1.5A6.97 6.97 0 007.252 6.89a7.36 7.36 0 00-1.722 2.795L1.643 6.675A11.912 11.912 0 018.36.789z"
          fill="#E33629"
        />
        <path
          d="M.611 9.656a11.8 11.8 0 011.032-2.981L5.53 9.692a7.183 7.183 0 000 4.618c-1.295 1-2.591 2.005-3.887 3.015A11.874 11.874 0 01.61 9.656z"
          fill="#F8BD00"
        />
        <path
          d="M12.238 9.778h11.16a13.937 13.937 0 01-.302 6.296 10.77 10.77 0 01-3 4.924c-1.254-.979-2.514-1.95-3.768-2.929a5.573 5.573 0 002.373-3.663h-6.463c-.002-1.542 0-3.085 0-4.628z"
          fill="#587DBD"
        />
        <path
          d="M1.64 17.325c1.297-1 2.592-2.005 3.887-3.015a7.368 7.368 0 002.723 3.641c.791.56 1.69.948 2.64 1.14a7.74 7.74 0 002.831 0 6.779 6.779 0 002.612-1.031c1.255.979 2.515 1.95 3.769 2.929a10.711 10.711 0 01-4.856 2.525c-1.998.47-4.08.448-6.068-.065a11.811 11.811 0 01-4.312-2.173 11.949 11.949 0 01-3.225-3.951z"
          fill="#319F43"
        />
      </g>
      <defs>
        <clipPath id="clip0_376_469">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Google
