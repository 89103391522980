import * as React from 'react'

function Check({ fill, ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transition: 'transform 0.3s ease' }}
      {...props}
    >
      <path
        d="M20 7L10 17l-5-5"
        stroke={fill}
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"

      />
    </svg>
  )
}

export default Check
