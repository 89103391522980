import { ChevronRight } from '@mui/icons-material'
import React, { memo } from 'react'

const AttendingCard = ({ attending, onClick }) => {
  return (
    <div className="absolute bottom-[-28px] left-0 right-0 flex justify-center z-10">
      <div
        className={`h-[57px] w-full bg-white border-1 border-primary shadow-md rounded-md flex items-center justify-between px-[16px]`}
      >
        <span className="font-semibold">
          {attending ? "I'm Going 🎉" : 'Unable to attend 🙁'}
        </span>

        <div className="cursor-pointer" onClick={onClick}>
          <span className="text-primary font-semibold">Change</span>
          <ChevronRight htmlColor="#5B4ABC" />
        </div>
      </div>
    </div>
  )
}

export default memo(AttendingCard)
