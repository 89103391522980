import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { ArrowDownIcon } from '../../../assets/icons'
import { twMerge } from 'tailwind-merge'
import { useWatch, Controller } from 'react-hook-form'

const SearchableSelect = ({
  placeholder,
  className,
  options,
  register,
  form,
  hasError,
  ...rest
}) => {
  const spanRef = useRef(null)
  const divRef = useRef(null)

  const [opened, setOpened] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  const formValue = useWatch({
    control: form.control,
    name: register.name
  })

  const handlePlaceholderAnimation = useCallback(() => {
    if (!spanRef.current) return

    if (formValue || inputFocused) {
      spanRef.current.style.top = '-1px'
      spanRef.current.style.fontSize = '12px'
      spanRef.current.style.background = 'white'
      spanRef.current.style.padding = '0px 4px'
    }

    if (!formValue && !inputFocused) {
      spanRef.current.style.top = '50%'
      spanRef.current.style.fontSize = '16px'
      spanRef.current.style.padding = '0px'
    }
  }, [formValue, inputFocused])

  const onFocus = () => {
    setInputFocused(true)
    setOpened(true)
  }

  const onBlur = () => {
    setInputFocused(false)
  }

  const handleOutsideClick = useCallback((event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setOpened(false)
      setInputFocused(false)
    }
  }, [])

  const handleSelectOption = useCallback(
    (option) => {
      form?.setValue(register.name, option)
      setOpened(false)
      setInputFocused(false)
    },
    [form, register.name]
  )

  useEffect(() => {
    if (formValue || inputFocused || opened) {
      handlePlaceholderAnimation()
    }
  }, [formValue, handlePlaceholderAnimation, inputFocused, opened])

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('click', handleOutsideClick)

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div
      ref={divRef}
      className={twMerge(
        `relative px-2 w-full min-w-24 h-h-input flex bg-primary ${hasError ? 'border-error border-1' : opened ? 'border-primary' : 'border-input-border'} border-1 rounded-input bg-transparent items-center cursor-pointer ${opened && 'border-[2px]'}`,
        className
      )}
    >
      <Controller
        name={register.name}
        control={form.control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <input
              type="text"
              className="w-full ml-1 mr-3 outline-none border-input-border bg-transparent"
              {...register}
              value={formValue?.label}
              onChange={
                rest.onChange ||
                ((event) => {
                  form?.setValue(register.name, event.target.value)
                })
              }
              onFocus={onFocus}
              onBlur={onBlur}
              autoComplete="off"
            />
          )
        }}
      />
      <span
        ref={spanRef}
        className={`absolute top-1/2 -translate-y-1/2 ${opened ? 'text-primary font-bold' : 'text-placeholder font-normal'} pointer-events-none left-2.5 ease-linear duration-150`}
      >
        {placeholder}
      </span>

      <ArrowDownIcon opened={opened} />

      {opened && (
        <div className="border-1 absolute top-0 flex flex-col items-start rounded-input right-0 left-0 mt-56px max-h-56 overflow-y-auto bg-background z-10 scrollbar-custom">
          {options.length === 0 ?
            (<div
              id="no-data"
              className="flex w-full p-2 rounded-input items-center justify-center scrollbar-custom pointer-events-none"
            >
              <span className="text-placeholder pointer-events-none">
                Type to search
              </span>
            </div>
            ) : (
              options.map((option, index) => {
                return (
                  <div
                    key={index}
                    className="hover:bg-primary hover:text-white w-full p-2 rounded-input cursor-pointer scrollbar-custom"
                    onClick={() => handleSelectOption(option)}
                  >
                    <span>{option.label}</span>
                  </div>
                )
              }))}
        </div>
      )}
    </div>
  )
}
export default memo(SearchableSelect)
