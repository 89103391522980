import * as React from "react"

function Cost({ fill, ...props }) {
  return (
    <svg
      width={512}
      height={512}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M366.05 146a46.702 46.702 0 01-2.42-63.42 3.867 3.867 0 00-.22-5.26l-44.13-44.18a3.89 3.89 0 00-5.5 0l-70.34 70.34a23.598 23.598 0 00-5.71 9.24 23.657 23.657 0 01-14.95 15 23.702 23.702 0 00-9.25 5.71L33.14 313.78a3.89 3.89 0 000 5.5l44.13 44.13a3.872 3.872 0 005.26.22 46.69 46.69 0 0165.84 65.84 3.868 3.868 0 00.22 5.26l44.13 44.13a3.89 3.89 0 005.5 0l180.4-180.39a23.702 23.702 0 005.71-9.25 23.657 23.657 0 0114.95-15 23.598 23.598 0 009.24-5.71l70.34-70.34a3.892 3.892 0 000-5.5l-44.13-44.13a3.871 3.871 0 00-5.26-.22 46.699 46.699 0 01-63.42-2.32z"
        stroke={fill || "#000"}
        strokeWidth={32}
        strokeMiterlimit={10}
      />
      <path
        d="M287.083 225.104H302.5c0-21.868-21.236-31.843-38.542-34.14V175h-15.416v15.964C231.236 193.261 210 203.236 210 225.104c0 20.859 20.55 31.705 38.542 34.148v38.31c-11.162-1.934-23.125-7.893-23.125-18.499H210c0 19.956 18.693 31.75 38.542 34.194v15.91h15.416V313.21c17.306-2.297 38.542-12.279 38.542-34.147 0-21.869-21.236-31.844-38.542-34.141v-38.318c10.252 1.842 23.125 7.254 23.125 18.5zm-61.666 0c0-11.246 12.873-16.658 23.125-18.5v36.992c-10.568-1.95-23.125-7.723-23.125-18.492zm61.666 53.959c0 11.246-12.873 16.657-23.125 18.499v-36.999c10.252 1.842 23.125 7.253 23.125 18.5z"
        fill={fill || "#000"}
      />
    </svg>
  )
}

export default Cost
