import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import Base from '../../base';
import { Helmet } from 'react-helmet';
import SquaresSquare from '../../../components/Squares/SquaresSquare/SquaresSquare';
import { useToast } from '@chakra-ui/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, database } from '../../../firebase';
import { useNavigate, useParams } from 'react-router-dom'
import { getUserInitials } from '../../../utils/square';
import SquaresTableSelect from '../../../components/Squares/SquaresTableSelect/SquaresTableSelect';
import DottedLine from '../../../components/DottedLine/DottedLine';
import SquaresGuestChoosePayModal from '../../../components/Squares/SquaresGuestChoose/SquaresGuestChoosePayModal/SquaresGuestChoosePayModal';
import { SQUARES_CREATE } from '../Squares';
import { useObject } from 'react-firebase-hooks/database';
import { get, ref, update } from 'firebase/database';

export const SQUARES_CHOOSE = 'SQUARES_CHOOSE'

const SquaresGuestChoose = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { circleCode, rsvpId } = useParams()
  const [initials, setInitials] = useState('')
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedSquares, setSelectedSquares] = useState([])
  const [opened, setOpened] = useState(false)
  const [consent, setConsent] = useState(false)

  const [squareSnap, squareLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/squares`)
  )
  const [guestSnap, guestLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId}`)
  )

  const squareInfo = squareSnap?.val()
  const guestInfo = guestSnap?.val()

  const handleContinue = async () => {
    if (!initials) {
      toast({
        status: 'error',
        title: 'Check the fields',
        position: 'top'
      })
      setHasError(true)
      return
    }

    await update(ref(database, `circles/${circleCode}/squares`), {
      squares: selectedSquares
    })

    navigate(`/event/${circleCode}/rsvp/squares/summary/${rsvpId}`)
  }

  const handleCancel = () => {
    navigate(-1)
  }

  const onChangeText = (value) => {
    if (value?.length > 19) {
      return
    }

    if (hasError && value?.length > 0) {
      setHasError(false)
    }

    if (value === '' || value?.trim() === 'Your initials:') {
      setInitials('')
      return;
    }

    if (!value?.startsWith('Your initials:')) {
      value = `Your initials: ${value.toUpperCase()}`;
    } else {
      const userInput = value.slice(14).trim();
      value = `Your initials: ${userInput.toUpperCase()}`;
    }

    setInitials(value)
  }

  const onSquareClick = (row, col) => {
    const initialsHasBeenTaken = squareInfo?.squares?.find((square) => square?.initials?.toLowerCase() === getUserInitials(initials).toLowerCase() && square?.uid !== rsvpId)

    if (!initials) {
      toast({
        status: 'error',
        title: 'Check the fields',
        position: 'top'
      })
      setHasError(true)
      return
    }

    if (initialsHasBeenTaken) {
      toast({
        status: 'error',
        title: 'Initials already taken',
        position: 'top'
      })
      return
    }

    const squareSelected = {
      name: guestInfo?.name,
      uid: rsvpId,
      initials: getUserInitials(initials),
      row,
      col
    }

    const exists = selectedSquares.some(
      square => square.row === row && square.col === col
    )

    if (exists) {
      // Remove the square if it exists
      setSelectedSquares(selectedSquares.filter(
        square => square.row !== row || square.col !== col
      ))
      return
    }

    setSelectedSquares([...selectedSquares, squareSelected])
  }

  useEffect(() => {
    if (squareInfo) {
      setSelectedSquares(squareInfo?.squares || [])

      const mySquares = squareInfo?.squares?.find(square => square?.uid === rsvpId)

      if (mySquares?.initials) {
        setInitials(`Your initials: ${mySquares?.initials || ''}`)
      }
    }
  }, [squareLoading])

  return (
    <React.Fragment>
      <SquaresGuestChoosePayModal
        isOpen={opened}
        onClose={() => setOpened(false)}
        consent={consent}
        setConsent={setConsent}
        sessionSettingsData={{}}
        selectedSquares={selectedSquares}
        onConfirm={() => navigate(`/event/${circleCode}/squares/summary/${rsvpId}`)}
        rsvpId={rsvpId}
        squareCost={squareInfo?.costPerSquare}
      />

      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        footerContinueOnClick={handleContinue}
        footerCancelOnClick={handleCancel}
        footerCancelText={'Skip'}
        removeVerticalPadding
        enableFooter
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - See your events history</title>
          <meta
            name="description"
            content="See events you’ve hosted or attended. Click on the tiles to see the event details."
          />
        </Helmet>

        {loading || squareLoading || guestLoading ?
          <div className='flex justify-center items-center flex-1'>
            <ClipLoader />
          </div>
          : (
            <div className="flex relative flex-1 mt-[-20px] mb-[-15px]">
              <div id='content' className='w-full'>
                {/* Header */}
                {/* <div className='mx-5'>
                  <div className='mt-12 flex items-start justify-start w-full sm:flex-row flex-col sm:justify-between sm:items-center'>
                    <div className='flex flex-1 items-center'>
                      <span className='text-[20px] font-semibold'>Cost per square</span>
                      <SquaresSquare value={`$${squareInfo?.costPerSquare}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none ml-2'} inputProps={{ disabled: true }} />
                    </div>
                  </div>

                  <div className='mt-3 flex flex-col w-full sm:mt-2'>
                    <span className='text-[20px] font-semibold'>Payout Amounts</span>

                    <div className='flex flex-1 items-center gap-2'>
                      <SquaresSquare title={'1st'} value={`$${squareInfo?.payouts?.first}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                      <SquaresSquare title={'2st'} value={`$${squareInfo?.payouts?.second}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                      <SquaresSquare title={'3st'} value={`$${squareInfo?.payouts?.third}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                      <SquaresSquare title={'4st'} value={`$${squareInfo?.payouts?.fourth}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                    </div>
                  </div>
                </div>

                <DottedLine color={"#80808033"} className={"my-10 h-[1px]"} horizontal solid /> */}

                <div className='w-full px-5 mt-10'>
                  <h2 className='text-primary text-[20px]'>Choose your squares by clicking on them below.</h2>
                  <p className='text-[14px]'>Teams and corresponding numbers will appear once all squares are filled in.</p>

                  {/* <div className='relative flex flex-col gap-2 mt-2'>
                    <div className="absolute inset-0 w-full h-full flex">
                      <DottedLine color={"#80808033"} />
                    </div>

                    {squareInfo?.venmo && <div className='flex items-center'>
                      <div className='w-[2px] h-3 bg-primary' />
                      <span className='ml-4 text-[14px]'>Venmo (@{squareInfo?.venmo})</span>
                    </div>}
                    {squareInfo?.cashapp && <div className='flex items-center'>
                      <div className='w-[2px] h-3 bg-primary' />
                      <span className='ml-4 text-[14px]'>CashApp (${squareInfo?.cashapp})</span>
                    </div>}
                    {squareInfo?.paypal && <div className='flex items-center'>
                      <div className='w-[2px] h-3 bg-primary' />
                      <span className='ml-4 text-[14px]'>Paypal (@{squareInfo?.paypal})</span>
                    </div>}
                    {squareInfo?.zelle && <div className='flex items-center'>
                      <div className='w-[2px] h-3 bg-primary' />
                      <span className='ml-4 text-[14px]'>Zelle ({squareInfo?.zelle})</span>
                    </div>}
                    {squareInfo?.allowCash && <div className='flex items-center'>
                      <div className='w-[2px] h-3 bg-primary' />
                      <span className='ml-4 text-[14px]'>or Cash upon arrival</span>
                    </div>}
                  </div> */}

                  <div className='mt-3 flex items-center justify-between flex-col sm:flex-row'>
                    <SquaresSquare
                      placeholder={'Enter your initials to begin'}
                      wrapperInputClassName={`w-auto px-2 min-w-[200px]`}
                      inputProps={{ fontSize: 14 }}
                      inputClassName={'placeholder:text-[14px]'}
                      value={initials}
                      onChangeText={onChangeText}
                      removeOnChangeValidation
                      error={hasError}
                    />

                    <div className='flex items-center sm:mt-0 mt-4'>
                      <div className='w-8 h-8 rounded-sm border-[2px] border-primary mr-3' />
                      <span className='pointer-events-none text-[12px]'>You own this square</span>
                    </div>
                  </div>
                </div>

                <DottedLine color={"#80808033"} className={"my-10 h-[1px]"} horizontal solid />

                {/* Squares */}
                <div className='relative'>
                  <SquaresTableSelect onSquareClick={onSquareClick} sessionTableData={{ selectedSquares }} userInitials={getUserInitials(initials)} rsvpId={rsvpId} />
                </div>

                <DottedLine color={"#80808033"} className={"my-10 h-[1px]"} horizontal solid />
              </div>
            </div>
          )
        }
      </Base>
    </React.Fragment >
  )
}

export default SquaresGuestChoose
