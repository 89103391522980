import React, { memo, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import Button from '../../Button/Button'

const MultilineWithButton = ({
  placeholder,
  className,
  button,
  hasError,
  form,
  register
}) => {
  const [textareaHeight, setTextareaHeight] = useState('auto')
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      setTextareaHeight(`${inputRef.current.scrollHeight}px`)
    }
  }, [inputRef.current?.scrollHeight])

  return (
    <div
      className={twMerge(
        `w-96 min-w-24 min-h-32 border-input-border border-1 rounded-input bg-transparent p-2 resize-none flex flex-col`,
        className
      )}
    >
      <div className="flex w-full justify-between items-center">
        <span className="text-placeholder pointer-events-none left-2.5 ease-linear duration-150">
          {placeholder}
        </span>
        <Button
          text={button.text}
          size="extra-small"
          type={button.type}
          onClick={button.action}
          disabled={button.disabled}
        />
      </div>
      <textarea
        required
        style={{ height: textareaHeight }}
        className={`outline-none mt-[5px] bg-transparent resize-none`}
        ref={(e) => {
          register.ref(e)
          inputRef.current = e
        }}
        {...register}
        onInput={() => {
          setTextareaHeight(`${inputRef.current?.scrollHeight}px`)
        }}
      />
    </div>
  )
}
export default memo(MultilineWithButton)
