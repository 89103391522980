import React, { useEffect, useState } from 'react'
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react'
import OldPlaylist from './OldPlaylist'
import { searchSpotify } from '../services/Spotify'
import { searchAppleMusic } from '../services/AppleMusic'
import { getTokens } from '../services/helpers'

const SimilarSongsModal = ({
  track,
  circleCode,
  circleInfo,
  maxLimit,
  connections,
  provider,
  option,
  selected,
  prevSelectedSongs,
  replaceSong,
  setTrackWithError
}) => {
  const originalTrack = track?.original || track
  const [results, setResults] = useState(null)
  const toast = useToast()

  const handleSearch = async () => {
    try {
      // ALWAYS USE SETTINGS TOKEN
      const token = await getTokens('guest', `streaming-${circleCode}`)
      if (!token.appleMusicToken && !token.accessToken) {
        toast({
          status: 'error',
          title: 'Error while refreshing tokens',
          position: 'top'
        })
        return
      }
      console.log('tokens', token)
      console.log('originalTrack', originalTrack, 'track', track, circleInfo)

      if (provider === 'spotify') {
        const spotifyResults = await searchSpotify(
          token.accessToken,
          originalTrack.name,
          0,
          12
        )
        console.log('searchSpotify spotifyResults', spotifyResults)
        const _results = spotifyResults
          .filter(
            (x) =>
              !!x.isrc &&
              x.id !== originalTrack.id &&
              !selected.find((y) => x.isrc === y.isrc)
          )
          .slice(0, 4)
        setResults(_results)
      } else {
        const appleMusicResults = await searchAppleMusic(
          token.appleMusicToken,
          'us',
          originalTrack.name,
          token.accessToken,
          0,
          12
        )
        const _results = appleMusicResults
          .filter((x) => !!x.isrc && x.id !== originalTrack.id && !x.withError)
          .slice(0, 4)
        setResults(_results)
      }
    } catch (e) {
      toast({
        title: e.message || 'Error while searching similar tracks',
        position: 'top',
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (!!originalTrack) {
      setResults(null)
      handleSearch().then(() => null)
    }
  }, [originalTrack])

  return (
    <Modal
      isOpen={!!originalTrack}
      onClose={() => setTrackWithError(null)}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent mx="20px">
        <ModalHeader color="primary">
          Check Similar Songs to "{originalTrack?.name}"
        </ModalHeader>
        <ModalCloseButton mt="5px" mr="0" />
        <ModalBody px="0">
          {!results && (
            <Flex minH="460px" align="center" justify="center">
              <Spinner size="xl" color="primary" />
            </Flex>
          )}
          {!!results && results.length > 0 && (
            <OldPlaylist
              type="selected"
              option="0"
              provider={provider}
              replacedTrack={track}
              playlist={results}
              circleCode={circleCode}
              maxLimit={maxLimit}
              selected={selected}
              prevSelectedSongs={prevSelectedSongs}
              replaceSong={(item) => replaceSong(track, item)}
              connections={connections}
              containerStyle={{
                paddingLeft: '20px',
                paddingRight: '20px',
                overflowY: 'scroll',
                maxHeight: '70vh'
              }}
            />
          )}
          {!!results && results.length === 0 && (
            <Flex minH="460px" align="center" justify="center">
              <Text fontSize="20px" fontWeight="600">
                Could not find similar songs
              </Text>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SimilarSongsModal
