import React, { memo, useRef, useEffect, useCallback } from 'react'
import { useWatch } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

const Multiline = ({ placeholder, className, register, hasError, form }) => {
  const spanRef = useRef(null)
  const inputRef = useRef(null)

  const value = useWatch({
    name: register.name,
    control: form?.control
  })


  const handlePlaceholderAnimation = useCallback((isFocused) => {
    if (!spanRef.current) return

    if (isFocused) {
      spanRef.current.style.top = '-10px'
      spanRef.current.style.fontSize = '12px'
      spanRef.current.style.background = '#fff'
      spanRef.current.style.padding = '0px 4px'
      return
    }

    if (!value && !isFocused) {
      spanRef.current.style.top = '10px'
      spanRef.current.style.fontSize = '16px'
      spanRef.current.style.padding = '0px'
    }
  }, [value])

  useEffect(() => {
    if (value) {
      handlePlaceholderAnimation(true)
    } else {
      handlePlaceholderAnimation(false)
    }
  }, [handlePlaceholderAnimation, value])

  return (
    <div className={twMerge(`relative w-full h-28`, className)}>
      <textarea
        className="border-input-border border-1 rounded-input bg-transparent w-full p-2 outline-none h-full resize-none focus:border-primary focus:border-[2px]"
        autoComplete="off"
        {...register}
        onFocus={() => {
          handlePlaceholderAnimation(true)
          spanRef.current?.classList.add('text-primary')
          spanRef.current?.classList.add('font-bold')
        }}
        onBlur={() => {
          handlePlaceholderAnimation(false)
          spanRef.current?.classList.remove('text-primary')
          spanRef.current?.classList.remove('font-bold')
        }}
      />
      <span
        ref={spanRef}
        className="absolute top-2 text-placeholder pointer-events-none left-2.5 ease-linear duration-150"
      >
        {placeholder}
      </span>
    </div>
  )
}
export default memo(Multiline)
