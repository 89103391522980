import React, { memo } from 'react'
import ActiveEventsCard from '../../../../components/MyEvents/ActiveEventsCard/ActiveEventsCard'

const PastEvents = ({ isToggled, pastEvents, setDeleteEventModal }) => {
  return (
    <div className={`${isToggled ? 'mt[10px]' : 'hidden'} flex flex-1`}>
      {pastEvents.length === 0 ? (
        <div className="flex flex-1 items-center justify-center">
          <span>No past events</span>
        </div>
      ) : (
        <div id="header" className="flex flex-col mt-[10px] sm:flex-row flex-1">
          <div className="w-full">
            {pastEvents.map((event, index) => (
              <div className="mb-4" key={index}>
                <ActiveEventsCard
                  event={event}
                  setDeleteEventModal={setDeleteEventModal}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(PastEvents)
