import React, { useMemo } from 'react'
import Modal from '../../../Modal/Modal'
import { XIcon } from '../../../../assets/icons'
import DottedLine from '../../../DottedLine/DottedLine'
import Checkbox from '../../../Checkbox/Checkbox'
import Button from '../../../Button/Button'

const SquaresGuestChoosePayModal = ({ isOpen, onClose, sessionSettingsData, selectedSquares, consent, setConsent, onConfirm, rsvpId, squareCost }) => {
  const myGuestSquaresSummary = useMemo(() => {
    const mySquares = selectedSquares.filter(square => square?.uid === rsvpId)

    const total = mySquares?.length * Number(squareCost)

    return {
      totalPrice: total,
      amountOfSquares: mySquares?.length
    }
  }, [rsvpId, selectedSquares, squareCost])

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={"max-w-[450px] w-full mx-5"}>
      <div>
        <div className="bg-white rounded-input relative w-full flex flex-col">
          <div className='flex items-center justify-end mt-5 px-5'>
            <div className='flex items-center cursor-pointer z-50' onClick={onClose}>
              <XIcon fill={"black"} width={23} height={23} />
            </div>
          </div>

          <div className="absolute inset-0 w-full h-full left-5 flex">
            <DottedLine color={"#80808033"} />
          </div>

          <div className='flex flex-col px-5 mt-3'>
            <div className='flex items-center'>
              <div className='w-[2px] h-5 bg-primary' />
              <span className='ml-5 text-[18px] text-primary font-semibold'>Pay your host for the squares</span>
            </div>
            <div className='flex items-center justify-between mx-5'>
              <span className='font-semibold'>You owe ${myGuestSquaresSummary?.totalPrice}</span>
              <span className='text-[14px]'>{myGuestSquaresSummary?.amountOfSquares} squares selected</span>
            </div>
          </div>

          <DottedLine color={"#80808033"} className={"my-5 h-[1px]"} horizontal solid />

          {sessionSettingsData?.venmo && <div className='flex flex-row px-5'>
            <div className='w-[2px] h-4 bg-primary' />
            <span className='ml-5 text-[15px]'>Venmo: (@{sessionSettingsData?.venmo})</span>
          </div>}

          {sessionSettingsData?.cashapp && <div className='flex flex-row px-5 mt-2'>
            <div className='w-[2px] h-4 bg-primary' />
            <span className='ml-5 text-[15px]'>Cashapp: (${sessionSettingsData?.cashapp})</span>
          </div>}

          {sessionSettingsData?.paypal && <div className='flex flex-row px-5 mt-2'>
            <div className='w-[2px] h-4 bg-primary' />
            <span className='ml-5 text-[15px]'>Paypal: (@{sessionSettingsData?.paypal})</span>
          </div>}

          {sessionSettingsData?.zelle && <div className='flex flex-row px-5 mt-2'>
            <div className='w-[2px] h-4 bg-primary' />
            <span className='ml-5 text-[15px]'>Zelle: ({sessionSettingsData?.zelle})</span>
          </div>}

          {sessionSettingsData?.allowCash && <div className='flex flex-row px-5 mt-2'>
            <div className='w-[2px] h-4 bg-primary' />
            <span className='ml-5 text-[15px]'>Or pay cash upon arrival.</span>
          </div>}

          <div className='flex flex-col items-center justify-between px-10 mb-5 z-50 mt-5 sm:flex-row'>
            <Checkbox
              checked={consent}
              onClick={() => setConsent(!consent)}
              text="I consent to receive texts from Coteri."
              textClassName={"text-[12px]"}
              className={'mt-[10px]'}
            />

            <Button
              text={"Confirm"}
              size={"medium"}
              className={"sm:mt-0 mt-5"}
              onClick={onConfirm}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SquaresGuestChoosePayModal
