import React, { memo } from 'react'
import { CheckIcon } from '../../assets/icons'
import { twMerge } from 'tailwind-merge'

const Checkbox = ({
  checked,
  onClick,
  text,
  className,
  textClassName,
  width,
  height
}) => {
  return (
    <div className={twMerge('flex items-center', className)}>
      <div
        className={`border-1 ${checked ? 'border-primary bg-primary' : 'border-black bg-transparent'} rounded-md flex items-center justify-center cursor-pointer`}
        style={{
          width: width || 20,
          height: height || 20
        }}
        onClick={onClick}
      >
        <CheckIcon
          width={14}
          height={14}
          fill={checked ? '#FFF' : 'transparent'}
        />
      </div>
      {text && (
        <div
          onClick={onClick}
          className={twMerge(
            'ml-[10px] cursor-pointer font-[14px]',
            textClassName
          )}
        >
          {text}
        </div>
      )}
    </div>
  )
}

export default memo(Checkbox)
