import * as React from 'react'

function Trash({ fill, ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.615 20a1.56 1.56 0 01-1.143-.472A1.557 1.557 0 016 18.385V6H5V5h4v-.77h6V5h4v1h-1v12.385c0 .46-.154.844-.462 1.152a1.565 1.565 0 01-1.153.463h-8.77zM17 6H7v12.385c0 .18.058.327.173.442a.599.599 0 00.442.173h8.77a.59.59 0 00.423-.192.59.59 0 00.192-.423V6zM9.808 17h1V8h-1v9zm3.384 0h1V8h-1v9z"
        fill={fill}
      />
    </svg>
  )
}

export default Trash
