import React, { useEffect, useState } from 'react'
import RSVPSelectSongsStep from './Step2/RSVPSelectSongsStep'
import { useObject } from 'react-firebase-hooks/database'
import { database, ref } from '../../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import PhoneOrEmailVerificationModal from '../../../components/NewGuest/PhoneOrEmailVerificationModal/PhoneOrEmailVerificationModal'
import { eventTimePassed } from '../../../helpers'

const RSVPPlaylist = () => {
  const { circleCode, rsvpId } = useParams()
  const [infoToVerify, setInfoToVerify] = useState(null)
  const [phoneOrEmailModal, setPhoneOrEmailModal] = useState(false)
  const navigate = useNavigate()

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [rsvpSnap, rsvpLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId || '1'}`)
  )

  const eventInfo = eventSnap?.val()
  const rsvpInfo = rsvpSnap?.val()

  useEffect(() => {
    if (!eventLoading && !rsvpLoading) {
      const isTimePassed = eventTimePassed(eventInfo)
      if (
        !eventInfo ||
        isTimePassed ||
        !rsvpInfo ||
        (!rsvpInfo.phone && !rsvpInfo.email) ||
        (!eventInfo.completed && !eventInfo.withCanva)
      ) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      if (!rsvpInfo.verified) {
        setPhoneOrEmailModal(true)
        setInfoToVerify(
          !!rsvpInfo.email
            ? { info: rsvpInfo.email, channel: 'email' }
            : { info: rsvpInfo.phone, channel: 'sms' }
        )
        return
      }
      setInfoToVerify(null)
      if (!rsvpInfo.response) {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}`, {
          replace: true
        })
        return
      }
      if (rsvpInfo.response !== 'yes') {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`, {
          replace: true
        })
        return
      }
    }
  }, [
    eventLoading,
    rsvpLoading,
    eventInfo,
    rsvpInfo,
    circleCode,
    rsvpId,
    navigate
  ])

  if (eventLoading || rsvpLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <ClipLoader color="#5B4ABC" />
      </div>
    )
  }

  return (
    <div className="flex flex-1 flex-col md:items-stretch">
      <RSVPSelectSongsStep
        circleCode={circleCode}
        rsvpId={rsvpId}
        circleInfo={eventInfo}
        option="guest"
        provider="spotify"
      />

      <PhoneOrEmailVerificationModal
        open={phoneOrEmailModal}
        circleCode={circleCode}
        rsvpId={rsvpId}
        infoToVerify={infoToVerify}
        onClose={() => {
          setPhoneOrEmailModal(false)
          setInfoToVerify(null)
        }}
      />
    </div>
  )
}

export default RSVPPlaylist
