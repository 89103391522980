import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Base from './base'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { getPaymentData, setPaymentData } from '../services/database'

const Thankyou = () => {
  const navigate = useNavigate()

  const { circleCode } = getPaymentData() ?? {}

  useEffect(() => {
    if (!!circleCode) {
      setPaymentData({ circleCode, invitesSent: true })
      setTimeout(() => {
        navigate(`/event/${circleCode}/rsvps`, { replace: true })
      }, 2000)
    } else {
      navigate('/', { replace: true })
    }
  }, [circleCode, navigate])

  if (!circleCode) {
    return (
      <Base
        hideMenu={true}
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        blackLogo
        headerBackgroundColor={'transparent'}
        menuColor={'#000'}
      />
    )
  }

  return (
    <Base
      hideMenu={true}
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      blackLogo
      headerBackgroundColor={'transparent'}
      menuColor={'#000'}
    >
      <Flex align="center">
        <Heading
          as="h4"
          flex="1"
          color="primary"
          mt="30px"
          mb="2px"
          textAlign="center"
          fontSize={{ base: '22px', md: '30px' }}
        >
          Thanks for your payment
        </Heading>
      </Flex>
      <Text
        mt="4px"
        color="gray"
        fontSize="20px"
        fontWeight="400"
        textAlign="center"
      >
        You will be redirected to your event automatically...
      </Text>
    </Base>
  )
}

export default Thankyou
