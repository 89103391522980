import React, { memo, useState, useMemo } from 'react'
import Button from '../../Button/Button'
import Modal from '../../Modal/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { remove } from 'firebase/database'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ref, database, auth } from '../../../firebase'
import { useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { useObject } from 'react-firebase-hooks/database'

const RequestListDeleteModal = ({ isOpen, onClose, item, overrideDelete }) => {
  const { circleCode, rsvpId } = useParams()
  const [submiting, setSubmitting] = useState(false)
  const [user] = useAuthState(auth)
  const toast = useToast()

  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )

  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])

  const owner = useMemo(() => {
    return user?.uid === item.claimedBy ? 'You' : guestsInfo[item.claimedBy]?.name
  }, [guestsInfo, item, user])

  const handleDeleteItem = async (data) => {
    try {
      setSubmitting(true)

      await remove(ref(database, `circles/${circleCode}/items/${item.id}`))
      onClose()

      toast({
        status: 'success',
        title: 'Item deleted',
        position: 'top'
      })
    } catch (err) {
      console.log('Error creating request item', err)
    } finally {
      setSubmitting(false)
    }
  }


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-10 bg-white rounded-input mx-3 md:mx-0">
        <h2>Are you sure you want to delete this item?</h2>

        <div className="flex flex-col items-start justify-center mt-[10px]">
          <span>Item: {item.name}</span>
          {item.claimed && <span>Owner: {owner}</span>}
        </div>

        <div className={`flex flex-1 items-center justify-between mt-[20px]`}>
          {submiting ? (
            <ClipLoader color="#5B4ABC" />
          ) : (
            <>
              <Button
                type="text"
                size="medium"
                text={'Cancel'}
                onClick={onClose}
              />
              <Button
                text={'Delete'}
                size="medium"
                disabled={guestsLoading}
                onClick={overrideDelete || handleDeleteItem}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default memo(RequestListDeleteModal)
