import React from 'react'
import { Tooltip } from '@chakra-ui/react'

const NewEventTitle = ({ form }) => {
  return (
    <div>
      <Tooltip label="Type your event title">
        <input
          placeholder="Event title *"
          className="bg-transparent outline-none font-medium text-[24px] mb-5"
          {...form.register('eventTitle')}
          autoComplete="off"
        />
      </Tooltip>
    </div>
  )
}

export default NewEventTitle
