import * as React from 'react'

function Search({ fill, ...props }) {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_118_305)">
        <path
          d="M15.5 14.5h-.79l-.28-.27a6.5 6.5 0 001.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 00-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 005.34-1.48l.27.28V16l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14.5zm-6 0C7.01 14.5 5 12.49 5 10s2.01-4.5 4.5-4.5S14 7.51 14 10s-2.01 4.5-4.5 4.5z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_118_305">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Search
