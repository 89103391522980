import React, { memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Modal from '../../Modal/Modal'
import Input from '../../Input/Input'
import Button from '../../Button/Button'
import ClipLoader from 'react-spinners/ClipLoader'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, updateProfile, updateAuthProfile } from '../../../firebase'

export const schema = yup
  .object({
    name: yup.string().required()
    // email: yup.string().required(),
  })
  .required()

const EditProfileModal = ({ isOpen, onClose, profileDefaultValues }) => {
  const form = useForm({
    resolver: yupResolver(schema)
  })
  const [user] = useAuthState(auth)

  const handleOnClose = () => {
    onClose()
    form.reset()
  }

  const handleFormSubmit = async ({ name, email }) => {
    try {
      await updateProfile({
        name
      })
      await updateAuthProfile(user, { displayName: name })
      handleOnClose()
    } catch (err) {
      console.log('error', err)
    }
  }

  console.log(
    'EditProfileModal: ',
    form.formState.dirtyFields,
    form.formState.isDirty
  )

  useEffect(() => {
    form.setValue('name', profileDefaultValues.name)
    // form.setValue('email', profileDefaultValues.email)
  }, [form, profileDefaultValues, isOpen])

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <div className="p-10 bg-white rounded-input mx-3 md:mx-0">
        <h2>Edit your profile information</h2>

        <Input
          register={form.register('name')}
          form={form}
          type="default"
          placeholder="Name"
          className="mt-[15px] w-full"
          hasError={form.formState.errors.name?.message}
        />
        {/* <Input
          register={form.register('email')}
          form={form}
          type="default"
          placeholder="Email"
          className="mt-[15px] w-full"
          hasError={form.formState.errors.email?.message}
        /> */}
        <div className="mt-[15px] flex flex-row items-center justify-between">
          {form.formState.isSubmitting ? (
            <div className="flex flex-1 items-center justify-center">
              <ClipLoader color="#5B4ABC" />
            </div>
          ) : (
            <React.Fragment>
              <Button type="text" text={'Cancel'} onClick={handleOnClose} />
              <Button
                text={'Confirm'}
                onClick={form.handleSubmit(handleFormSubmit)}
                disabled={!form.formState.isDirty}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default memo(EditProfileModal)
