import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, setUserId } from '../firebase'
import { removeEventData } from '../services/database'
import LoginBox from '../components/LoginBox'
import RegisterBox from '../components/RegisterBox'
import ResetBox from '../components/ResetBox'
import {
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import Button from '../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Home/Header/Header'
import Background from '../components/Home/Background/Background'
import Features from '../components/Home/Features/Features'
import MusicFlow from '../components/Home/MusicFlow/MusicFlow'
import HowItWorks from '../components/Home/HowItWorks/HowItWorks'
import PopularEvents from '../components/Home/PopularEvents/PopularEvents'
import Footer from '../components/Home/Footer/Footer'
import * as amplitude from '@amplitude/analytics-browser'
import Halloween from '../components/Home/Halloween/Halloween'
import SuperBowl from '../components/Home/SuperBowl/SuperBowl'
import Thanksgiving from '../components/Home/Thanksgiving/Thanksgiving'

const Home = () => {
  const [showPopUp, setShowPopUp] = useState(null)
  const [canvaPopUp, setCanvaPopUp] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [scrollReached, setScrollReached] = useState(0)
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const toast = useToast()

  const handleSignUp = () => {
    setShowPopUp('register')
  }

  const handleSignIn = () => {
    setShowPopUp('login')
  }

  const handleSuccess = () => {
    setShowPopUp(null)
    if (!!redirect) {
      if (redirect.startsWith('canva=')) {
        setCanvaPopUp(redirect.substring(6, redirect.length - 1))
        return
      }
      navigate(redirect)
    }
  }

  const handleCreateEvent = (url) => {
    amplitude.track('Create Event CTA clicked', {
      'CTA label': 'primary action'
    })
    // logEvent(analytics, 'new_event', {
    //   source: 'HOME_BANNER'
    // })
    removeEventData()
    if (!user || user.isAnonymous) {
      setRedirect(url)
      setShowPopUp('register')
      return
    }
    navigate(url)
  }

  const handleScroll = () => {
    const perc = (window.screenY / window.height) * 100
    if (perc > 40 && scrollReached < 40) {
      setScrollReached(40)
      // logEvent(analytics, 'scrolled', {
      //   source: 'SCROLL_40%'
      // })
    }
    if (perc > 75 && scrollReached < 75) {
      setScrollReached(75)
      // logEvent(analytics, 'scrolled', {
      //   source: 'SCROLL_75%'
      // })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!loading && !!user) {
      // setUserId(analytics, user.uid)
      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('uid', user.uid)
      amplitude.identify(identifyEvent)
      amplitude.setUserId(user.uid)
    }
  }, [user, loading])

  if (loading) {
    return null
  }

  return (
    <div>
      <title>Coteri</title>
      <Header handleSignUp={handleSignUp} handleSignIn={handleSignIn} />

      <div id="screen1">
        <div
          style={{ height: 'calc(100vh - 7rem)' }}
          className="relative flex justify-center items-center"
        >
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={require('../assets/images/home.png')}
          />

          <div className="absolute inset-0 flex flex-col justify-end items-center gap-10 pb-10 bg-[#00000099]">
            <h2 className="text-[30px] sm:text-[50px] text-white text-center">
              Events for any ocassion
            </h2>
            <div>
              <Button
                text={'Create event'}
                className={'bg-white text-black hover:text-white text-[18px]'}
                onClick={() => handleCreateEvent('/event/new')}
              />
            </div>
            <div className="mx-4 text-center">
              <span className=" text-white text-[16px] sm:text-[24px] text-center">
                It only takes a few seconds to plan your next event.
              </span>
            </div>
          </div>
        </div>
      </div>

      <Thanksgiving handleCreateEvent={handleCreateEvent} />

      <SuperBowl handleCreateEvent={handleCreateEvent} />

      <div className="flex justify-center relative mx-4 pb-[80px]">
        <Background />
        <div className="max-w-[1200px] flex flex-col relative w-full">
          <Features />

          <MusicFlow />

          <HowItWorks />

          <PopularEvents />
        </div>
      </div>

      <Footer />

      <Modal isCentered isOpen={!!showPopUp} onClose={() => setShowPopUp(null)}>
        <ModalOverlay />
        <ModalContent py="12px" mx="20px">
          <ModalCloseButton mt="4px" mr="0" />
          <ModalBody>
            {showPopUp === 'login' && (
              <LoginBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
            {showPopUp === 'register' && (
              <RegisterBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
            {showPopUp === 'reset' && (
              <ResetBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default React.memo(Home)
