import React from 'react'

const EmailDistributionListItem = ({ item }) => {
  return (
    <tr className="border-t-1">
      <td className="py-3">
        <div className="flex-1 flex ml-3 md:ml-4 items-center">
          <span className="max-w-[80px] md:max-w-[230px] sm:max-w-[200px] text-start sm:text-[16px] text-[14px]">
            {item.name}
          </span>
        </div>
      </td>
      <td className="py-3">
        <div className="flex-1 flex items-center">
          <span className="sm:text-[16px] text-[14px]">{item.email}</span>
        </div>
      </td>
    </tr>
  )
}

export default EmailDistributionListItem
