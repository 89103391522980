import React, { memo } from 'react'
import { TrashIcon } from '../../../assets/icons'

const GuestItem = ({ index, guest, onDelete, removeRsvp }) => {
  return (
    <tr className="border-t-1">
      <td className="py-3">
        <div className="flex-1 flex ml-1 md:ml-4 items-center">{index}</div>
      </td>
      <td className="py-3">
        <div className="flex-1 flex flex-col ml-1 md:ml-4 items-start">
          <span className="max-w-[240px] text-start">{guest.name}</span>
          <span className="max-w-[240px] text-start text-[14px] text-placeholder">
            {guest.phone ?? guest.email ?? ''}
          </span>
        </div>
      </td>
      {removeRsvp && (
        <td className="flex flex-1 items-center justify-center md:justify-start py-[28px] md:py-[18px]">
          {!guest.response ? (
            <span>✉️</span>
          ) : guest.response === 'yes' ? (
            <span>🎉</span>
          ) : (
            <span>🙁</span>
          )}
        </td>
      )}
      {!!onDelete && (
        <td className="py-3">
          <div className="flex-1 flex items-center justify-evenly">
            <TrashIcon
              fill="#00000099"
              className="cursor-pointer"
              onClick={() => onDelete(guest)}
            />
          </div>
        </td>
      )}
    </tr>
  )
}

export default memo(GuestItem)
