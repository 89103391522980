import React, { useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import Base from '../../base';
import { Helmet } from 'react-helmet';
import SquaresSquare from '../../../components/Squares/SquaresSquare/SquaresSquare';
import { useToast } from '@chakra-ui/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, database } from '../../../firebase';
import { useNavigate, useParams } from 'react-router-dom'
import { getUserInitials } from '../../../utils/square';
import SquaresTableSelect from '../../../components/Squares/SquaresTableSelect/SquaresTableSelect';
import DottedLine from '../../../components/DottedLine/DottedLine';
import { SQUARES_CREATE } from '../Squares';
import { ref, set, update } from 'firebase/database';
import { v4 } from 'uuid'
import { useObject } from 'react-firebase-hooks/database';

export const SQUARES_CHOOSE = 'SQUARES_CHOOSE'

const SquaresChoose = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { circleCode } = useParams()
  const [user] = useAuthState(auth)
  const [initials, setInitials] = useState('')
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedSquares, setSelectedSquares] = useState([])
  console.log('SquaresChoose: ', user)
  // const [gameSettings, setGameSettings] = useState(undefined)

  const [squareSnap, squareLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/squares`)
  )
  const squareInfo = squareSnap?.val()

  const handleContinue = async () => {
    try {
      setLoading(true)

      const id = squareInfo?.id || v4().replace(/-/g, '')

      const createSquare = {
        // ...gameSettings,
        id,
        squares: selectedSquares
      }

      sessionStorage.setItem(SQUARES_CHOOSE, JSON.stringify({
        initials,
        selectedSquares
      }));

      if (!initials) {
        toast({
          status: 'error',
          title: 'Check the fields',
          position: 'top'
        })
        setHasError(true)
        return
      }

      if (squareInfo) {
        await update(ref(database, `circles/${circleCode}/squares`), createSquare)
      } else {
        await set(ref(database, `circles/${circleCode}/squares`), createSquare)
      }

      navigate(`/event/${circleCode}/squares/summary`)
    } catch (err) {
      console.log('SquaresChoose: ', err)
      toast({
        status: 'error',
        title: 'Something went wrong',
        position: 'top'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    navigate(-1)
  }

  const onChangeText = (value) => {
    if (value?.length > 19) {
      return
    }

    if (hasError && value?.length > 0) {
      setHasError(false)
    }

    if (value === '' || value?.trim() === 'Your initials:') {
      setInitials('')
      return;
    }

    if (!value?.startsWith('Your initials:')) {
      value = `Your initials: ${value.toUpperCase()}`;
    } else {
      const userInput = value.slice(14).trim();
      value = `Your initials: ${userInput.toUpperCase()}`;
    }

    setInitials(value)
  }

  const handleEditGame = () => {
    navigate(`/event/${circleCode}/squares`)
  }

  const onSquareClick = (row, col) => {
    if (!initials) {
      toast({
        status: 'error',
        title: 'Check the fields',
        position: 'top'
      })
      setHasError(true)
      return
    }

    const squareSelected = {
      uid: user?.uid,
      initials: getUserInitials(initials),
      row,
      col
    }

    const exists = selectedSquares.some(
      square => square.row === row && square.col === col
    )

    if (exists) {
      // Remove the square if it exists
      setSelectedSquares(selectedSquares.filter(
        square => square.row !== row || square.col !== col
      ))
      return
    }

    setSelectedSquares([...selectedSquares, squareSelected])
  }

  const setPageData = (data) => {
    setInitials(data?.initials || '')
    setSelectedSquares(data?.selectedSquares || [])
  }

  // const loadSession = () => {
  //   try {
  //     setLoading(true)

  //     const session = sessionStorage.getItem(SQUARES_CHOOSE);

  //     if (!session) {
  //       return
  //     }

  //     const sessionData = JSON.parse(session)

  //     setPageData(sessionData)
  //   } catch (err) {
  //     console.log('Squares: error loading session', err)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // const loadGameSettingsSession = () => {
  //   try {
  //     setLoading(true)

  //     const session = sessionStorage.getItem(SQUARES_CREATE);

  //     if (!session) {
  //       return
  //     }

  //     const sessionData = JSON.parse(session)

  //     setGameSettings(sessionData)
  //   } catch (err) {
  //     console.log('Squares: error loading session', err)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  useEffect(() => {
    if (squareInfo && !squareLoading) {
      if (squareInfo) {
        setPageData({
          initials: `Your initials: ${squareInfo?.squares?.find((s) => s.uid === user?.uid)?.initials || ''}`,
          selectedSquares: squareInfo?.squares || [],
        })
      }
    }
  }, [squareLoading])


  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      footerContinueOnClick={handleContinue}
      footerCancelOnClick={handleCancel}
      footerCancelText={'Skip'}
      footerContinueProps={{
        loading,
      }}
      removeVerticalPadding
      enableFooter
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coteri - See your events history</title>
        <meta
          name="description"
          content="See events you’ve hosted or attended. Click on the tiles to see the event details."
        />
      </Helmet>

      {loading || squareLoading ?
        <div className='flex justify-center items-center flex-1'>
          <ClipLoader />
        </div>
        : (
          <div className="flex relative flex-1 mt-[-20px] mb-[-15px]">
            <div id='content' className='w-full'>
              {/* Header */}
              <div className='mt-12 w-full px-5'>
                <h2 className='text-primary text-[20px]'>Choose your squares by clicking on them below.</h2>
                <p className='text-[14px]'>Teams and corresponding numbers will appear once all squares are filled in.</p>
                {/* <div className='mt-3 flex items-center justify-between flex-row'>
                  <span className='text-[14px]'>(Venmo: @jonathanorler)</span>
                  <span className='ml-3 text-[14px] text-primary font-semibold cursor-pointer underline' onClick={handleEditGame}>Edit game settings</span>
                </div> */}

                <div className='mt-3 flex items-center justify-between flex-col sm:flex-row'>
                  <SquaresSquare
                    placeholder={'Enter your initials to begin'}
                    wrapperInputClassName={`w-auto px-2 min-w-[200px]`}
                    inputProps={{ fontSize: 14 }}
                    inputClassName={'placeholder:text-[14px]'}
                    value={initials}
                    onChangeText={onChangeText}
                    removeOnChangeValidation
                    error={hasError}
                  />

                  <div className='flex items-center sm:mt-0 mt-4'>
                    <div className='w-8 h-8 rounded-sm border-[2px] border-primary mr-3' />
                    <span className='pointer-events-none text-[12px]'>You own this square</span>
                  </div>
                </div>
              </div>

              <DottedLine color={"#80808033"} className={"my-10 h-[1px]"} horizontal solid />

              {/* Squares */}
              <div className='relative'>
                <SquaresTableSelect onSquareClick={onSquareClick} sessionTableData={{ selectedSquares }} userInitials={getUserInitials(initials)} user={user} />
              </div>

              <DottedLine color={"#80808033"} className={"my-10 h-[1px]"} horizontal solid />
            </div>
          </div>
        )
      }
    </Base>
  )
}

export default SquaresChoose
