import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { auth, sendPasswordResetEmail } from '../firebase'
import { Button, Flex, Input, Text } from '@chakra-ui/react'
import querystring from 'query-string'

const ResetBox = ({ onSuccess, onChangePopUp }) => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const params =
    typeof window != null ? querystring.parse(window.location.search) : {}

  const handleReset = async () => {
    await sendPasswordResetEmail(auth, email)
    if (!!onSuccess) {
      onSuccess()
    } else {
      navigate('/login')
    }
  }

  return (
    <Flex w="100%" direction="column" px="20px">
      <Text mb="28px" color="black" fontSize="20px" fontWeight="600">
        Reset
      </Text>
      <Input
        type="text"
        h="50px"
        mb="16px"
        borderRadius="10px"
        borderWidth="3px"
        borderColor="black"
        variant="outline"
        _hover={{ borderColor: 'gray.600' }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <Button
        h="50px"
        mb="20px"
        bg="black"
        color="white"
        colorScheme="pink"
        borderRadius="10px"
        borderColor="black"
        onClick={handleReset}
      >
        Send reset email
      </Button>
      <Flex justify="center" mb="10px">
        <Text color="gray.600">Don't have an account?</Text>
        {onChangePopUp ? (
          <Text
            color="primary"
            cursor="pointer"
            ml="4px"
            onClick={() => onChangePopUp('register')}
          >
            Sign Up
          </Text>
        ) : (
          <Link
            to={`/register?${new URLSearchParams(params).toString()}`}
            color="primary"
          >
            <Text color="primary" ml="4px">
              Sign Up
            </Text>
          </Link>
        )}
      </Flex>
    </Flex>
  )
}

export default ResetBox
