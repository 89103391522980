const Styles = {
  global: {
    body: {
      bg: '#F5F5F7',
      margin: 0
    },
    h1: {
      fontFamily: 'Inter, sans-serif !important;',      
      fontWeight: '900 !important',
      fontStyle: 'normal',
      lineHeight: 1.4
    },
    h2: {
      fontFamily: 'Inter, sans-serif !important;',
      fontWeight: '900 !important'
    },
    h3: {
      fontFamily: 'Inter, sans-serif !important;',
      fontWeight: '900 !important'
    },
    h4: {
      fontFamily: 'Inter, sans-serif !important;',
      fontSize: '24px',
      fontWeight: '900 !important',
      fontStyle: 'normal',
      lineHeight: 1.4
    }
  }
}

export default Styles
