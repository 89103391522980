import React, { memo } from 'react'
import { MusicFlowIcon } from '../../../assets/icons'

const MusicFlow = () => {
  return (
    <div className="flex flex-col items-center justify-center mobile:my-14 my-10 mobile:flex-row">
      <MusicFlowIcon width={122} height={122} />
      <div className="ml-4 flex flex-col items-center justify-center">
        <h2 className="md:text-[28px] text-[20px]  text-secondary">
          Build the perfect party playlist
        </h2>
        <h2 className="md:text-[20px] text-[17px] text-secondary">
          You pick the vibe, we do the rest.
        </h2>
      </div>
    </div>
  )
}

export default memo(MusicFlow)
