import * as React from 'react'

function Calendar({ fill, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      viewBox="0 0 768 767.999994"
      height={24}
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path d="M30.191 27.875h707.25v712.496H30.191zm0 0" />
        </clipPath>
      </defs>
      <path
        fill={fill || '#422d8f'}
        d="M143.008 440.176h56.27c7.804 0 14.132-6.328 14.132-14.133v-56.27c0-7.8-6.328-14.128-14.133-14.128h-56.27c-7.804 0-14.132 6.324-14.132 14.128v56.27c0 7.805 6.328 14.133 14.133 14.133zm0 0M143.008 556.512h56.27c7.804 0 14.132-6.324 14.132-14.13V486.11c0-7.8-6.328-14.129-14.133-14.129h-56.27c-7.804 0-14.132 6.325-14.132 14.13v56.273c0 7.804 6.328 14.129 14.133 14.129zm0 0M143.008 672.848h56.27c7.804 0 14.132-6.325 14.132-14.13v-56.273c0-7.8-6.328-14.129-14.133-14.129h-56.27c-7.804 0-14.132 6.329-14.132 14.13v56.273c0 7.804 6.328 14.129 14.133 14.129zm0 0M284.91 440.176h56.27c7.804 0 14.132-6.328 14.132-14.133v-56.27c0-7.8-6.328-14.128-14.132-14.128h-56.27c-7.805 0-14.133 6.324-14.133 14.128v56.27c.004 7.805 6.328 14.133 14.133 14.133zm0 0M284.91 556.512h56.27c7.804 0 14.132-6.324 14.132-14.13V486.11c0-7.8-6.328-14.129-14.132-14.129h-56.27c-7.805 0-14.133 6.325-14.133 14.13v56.273c.004 7.804 6.328 14.129 14.133 14.129zm0 0M284.91 672.848h56.27c7.804 0 14.132-6.325 14.132-14.13v-56.273c0-7.8-6.328-14.129-14.132-14.129h-56.27c-7.805 0-14.133 6.329-14.133 14.13v56.273c.004 7.804 6.328 14.129 14.133 14.129zm0 0M426.813 440.176h56.273c7.8 0 14.129-6.328 14.129-14.133v-56.27c0-7.8-6.324-14.128-14.13-14.128h-56.272c-7.801 0-14.125 6.324-14.125 14.128v56.27c-.004 7.805 6.32 14.133 14.125 14.133zm0 0M483.086 588.316h-56.274c-7.8 0-14.125 6.329-14.125 14.13v56.273c0 7.8 6.325 14.129 14.125 14.129h56.274c7.8 0 14.129-6.325 14.129-14.13v-56.273c0-7.8-6.328-14.129-14.13-14.129zm0 0M568.715 440.176h56.273c7.801 0 14.13-6.328 14.13-14.133v-56.27c0-7.8-6.325-14.128-14.13-14.128h-56.273c-7.8 0-14.13 6.324-14.13 14.128v56.27c0 7.805 6.325 14.133 14.13 14.133zm0 0M568.715 556.512h56.273c7.801 0 14.13-6.324 14.13-14.13V486.11c0-7.8-6.325-14.129-14.13-14.129h-56.273c-7.8 0-14.13 6.325-14.13 14.13v56.273c0 7.804 6.325 14.129 14.13 14.129zm0 0"
      />
      <g clipPath="url(#a)">
        <path
          fill={fill || '#422d8f'}
          d="M571.824 27.91c-13.254 0-23.996 10.742-23.996 23.996v48.227H221.406V51.906c0-13.254-10.746-23.996-24-23.996S173.41 38.652 173.41 51.906v48.227H97.77c-37.262 0-67.579 30.316-67.579 67.578V672.5c0 37.262 30.317 67.578 67.579 67.578h572.457c37.261 0 67.578-30.316 67.578-67.578V167.71c0-37.26-30.317-67.577-67.578-67.577h-74.403V51.906c0-13.254-10.746-23.996-24-23.996zM173.41 134.56v29.796c0 13.254 10.742 24 23.996 24 13.254 0 24-10.746 24-24V134.56c8.696 7.015 14.258 17.754 14.258 29.796 0 21.133-17.129 38.262-38.258 38.262-21.133 0-38.261-17.129-38.261-38.262 0-12.042 5.566-22.78 14.265-29.796zM704.508 672.5c0 18.902-15.38 34.285-34.281 34.285H97.77c-18.903 0-34.282-15.383-34.282-34.285V321.71h641.02zm-94.422-508.145c0 21.133-17.129 38.262-38.262 38.262s-38.261-17.129-38.261-38.262c0-12.042 5.566-22.78 14.261-29.796v29.796c0 13.254 10.742 23.997 23.996 23.997 13.254 0 24-10.743 24-23.997V134.56c8.7 7.015 14.266 17.754 14.266 29.796zm0 0"
        />
      </g>
      <path
        fill={fill || '#422d8f'}
        d="M450.496 563.969l67.055-67.055c6.504-6.504 6.504-17.043 0-23.543-6.5-6.504-17.04-6.504-23.543 0l-43.512 43.512-17.473-17.473c-6.5-6.5-17.043-6.504-23.543 0-6.503 6.5-6.503 17.04 0 23.543zm0 0"
      />
    </svg>
  )
}

export default Calendar
