import React, { useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import Base from './base'
import { Link } from 'react-router-dom'
import '../styles/privacy.css'
import { Helmet } from 'react-helmet'

const Blog = () => {
  return (
    <Base isPublic>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coteri - Blog</title>
        <meta
          name="description"
          content="Coteri: The Music Connection. Read about the evolution of music. See popular songs from each decade."
        />
      </Helmet>
      <Flex flex="1" direction="column" mb="280px">
        <Text fontSize="20px" fontWeight="600" mb="30px">
          Coteri Blog
        </Text>
        <div className="privacy">
          <p className="c1">
            <span className="c3" style={{ fontWeight: '600' }}>
              From the Jukebox to Streaming: A history of music discovery in the
              US (1950s to present)
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Over the past decades, the methods and mediums for discovering
              music have undergone a remarkable transformation, shaped by
              technological advancements and cultural shifts. Let's embark on a
              brief history, tracing the evolution of music discovery in the
              United States from the 1950s until the present day.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              1. The Jukebox Era (1950s-1960s): In the 1950s, the jukebox
              reigned supreme as a popular method for discovering new music.
              These coin-operated machines, often found in diners and bars,
              allowed people to select songs from a collection of vinyl records.
              The rise of rock 'n' roll, fueled by artists like Elvis Presley
              and Chuck Berry, brought a fresh energy to the American music
              scene. The jukebox served as a gateway for many to explore this
              vibrant genre, leading to a cultural revolution.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              2. Radio Airwaves and Top 40 Charts (1960s-1970s): The 1960s and
              1970s witnessed the dominance of radio as a primary means of music
              discovery. Stations played a variety of genres, and disc jockeys
              became tastemakers, introducing listeners to new artists and
              songs. The emergence of the "Top 40" format, which highlighted the
              most popular tracks based on sales and airplay, greatly influenced
              musical tastes and provided a platform for emerging talents. Radio
              played a pivotal role in breaking barriers and fostering cultural
              integration through shared music experiences.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              3. Vinyl Records and Music Stores (1970s-1980s): Vinyl records
              enjoyed their heyday in the 1970s and 1980s, and music stores
              became havens for enthusiasts. These stores were gathering places
              where music lovers could engage with knowledgeable staff, flip
              through album covers, and listen to new releases on in-store sound
              systems. Exploring aisles filled with records provided a tactile
              and immersive experience, allowing individuals to discover music
              through personal interaction and serendipitous encounters.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              4. MTV and Music Videos (1980s-1990s): The 1980s brought about a
              groundbreaking innovation in music discovery: the birth of MTV and
              the rise of music videos. Suddenly, artists could captivate
              audiences visually, complementing their songs with powerful
              imagery and storytelling. MTV became a cultural phenomenon,
              shaping popular culture and introducing viewers to a broad
              spectrum of genres and artists. Music videos expanded the reach of
              musicians and created iconic moments that still resonate today.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              5. Compact Discs and Internet Piracy (1990s-early 2000s): The
              advent of compact discs (CDs) revolutionized the music industry in
              the 1990s, offering clearer sound quality and convenient
              portability. Large music store chains like Tower Records became
              destinations for music lovers seeking the latest releases.
              However, this era also saw the rise of internet piracy, with
              file-sharing platforms like Napster enabling users to download
              music illegally. While piracy raised concerns about intellectual
              property rights, it also led to a democratization of music
              discovery, allowing listeners to explore an unprecedented array of
              artists and genres.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              6. Digital Streaming and Online Platforms (early-2000s to
              Present): The music landscape experienced a seismic shift in the
              early-2000s as digital streaming platforms emerged. Throughout the
              last two decades, music streaming has revolutionized music
              consumption and exploration. Platforms such as Spotify, Apple
              Music, YouTube Music, Pandora, and SoundCloud have profoundly
              impacted the industry, granting listeners immediate access to vast
              music libraries. Below, we briefly explore the evolution of music
              streaming since 2000.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Pandora, established in 2000, stood as one of the pioneers in
              music streaming. It provides personalized radio stations tailored
              to user preferences and boasts effective music discovery
              algorithms. Pandora's premium subscription service enables users
              to skip songs, enjoy offline listening, and access higher-quality
              audio.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              SoundCloud, launched in 2007, has gained popularity as a platform
              for independent artists to share their music. It grants listeners
              access to an extensive catalog, including user-generated content.
              SoundCloud has served as a springboard for numerous artists and
              fostered a community of musicians and fans.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Spotify, launched in 2008, has emerged as one of the leading music
              streaming services. With its vast music library, personalized
              playlists, and artist recommendations, Spotify has experienced
              exponential growth, amassing over 320 million monthly active users
              by 2021. Its algorithm-driven playlists and tailored suggestions
              have revolutionized music discovery.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Apple Music, introduced in 2015, swiftly climbed the ranks of
              streaming services, boasting over 60 million subscribers as of
              2019. It offers an extensive music catalog, exclusive content, and
              personalized recommendations. Apple Music's integration with the
              Siri voice assistant allows users to effortlessly request songs,
              albums, and playlists through voice commands.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              YouTube Music, launched in 2018, represents a newer contender in
              the streaming industry. It provides access to millions of songs,
              music videos, and live performances. YouTube Music has become a
              go-to platform for music discovery, offering personalized
              playlists and recommendations based on user activity.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Overall, music streaming has exerted a monumental influence on the
              music industry over the past decade. It has revolutionized music
              exploration and consumption, granting listeners immediate access
              to vast libraries of music. The ascendance of Spotify, Apple
              Music, YouTube Music, Pandora, and SoundCloud has propelled the
              careers of numerous artists, while their personalized playlists
              and algorithms have made music discovery more accessible than
              ever.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Start collaborating on playlists with Coteri at{' '}
              <Link className="c13" style={{ color: '#422d8f' }} to="/">
                www.Coteri.us
              </Link>{' '}
            </span>
          </p>
        </div>
      </Flex>
    </Base>
  )
}

export default Blog
