import React, { memo } from 'react'
import { twMerge } from 'tailwind-merge'

const Toggle = ({ value, setValue, textOn, textOff, className }) => {
  const toggleCheckbox = () => {
    setValue(!value)
  }

  return (
    <label
      className={twMerge(
        `flex items-center relative w-max cursor-pointer select-none`,
        className
      )}
    >
      <input
        type="checkbox"
        className="appearance-none transition-colors cursor-pointer w-60px h-30px rounded-full focus:outline-none checked:bg-primary bg-gray"
        checked={value}
        onChange={toggleCheckbox}
      />
      <span
        className={`absolute text-xs right-1.5 text-white font-bold  ${
          value ? 'opacity-0' : 'opacity-100'
        }`}
      >
        {textOff}
      </span>
      <span
        className={`absolute text-xs left-1.5 text-white font-bold ${
          value ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {textOn}
      </span>
      <span
        className="w-6 h-6 left-1 absolute rounded-full transform transition-transform bg-gray-200"
        style={{
          transform: value ? 'translateX(1.75rem)' : 'none',
          backgroundColor: value ? '#FFF' : '#cccccc'
        }}
      />
    </label>
  )
}
export default memo(Toggle)
